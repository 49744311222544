.success-message {
  width: 50%;
  margin-right: auto;
  margin-left: auto;
  background-color: #333;
  border-radius: 3px;
  height: 50px;
  padding-top: 3px;
  position: fixed;
  z-index: 5;
  left: 0;
  right: 0;
  bottom: 15px;
  p {
    color: #fff;
    display: inline-block;
    padding-top: 12px;
    padding-left: 20px;
  }
  .mui-btn.mui-btn--flat {
    float: right;
    padding: 0 10px;
    background-color: transparent;
    color: #ce7e07;
    height: auto;
    font-size: 15px;
    margin-right: 15px;
    a {
      color: #ce7e07;
    }
  }
}

@media only screen and (max-width: 800px) {
  .success-message {
    width: 90%;
    z-index: 20;
  }
}
