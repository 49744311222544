.news-feed {
  margin: 20px auto;
  width: 95%;
}

.feed-image {
  width: 100%;
  height: 225px;
  border-radius: 3px 3px 0px 0px;
  object-fit: cover;
}

.feed-info {
  padding: 2%;
  color: gray;
  font-size: 95%;
}

.news-feed {
  .panel {
    height: 400px;
    max-width: 365px;
    border-radius: 3px;
  }
}

.news-feed .panel-info-value {
  padding: 1.5% !important;
  font-weight: 400;
}

.news-feed .title {
  font-weight: bold;
  font-size: 15px;
  color: #8b8b8b;
  padding-top: 5px;
}

.article-content {
  font-weight: 300 !important;
  color: #8b8b8b;
}

.article-source {
  font-weight: 500;
  margin-right: 5px;
  font-size: 12px;
}

.article-date {
  margin-left: 5px;
  font-weight: 300;
  font-size: 12px;
}

.filter-info {
  width: 87%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  height: 35px;
  background-color: #ce7e07;
  border-radius: 3px;
  color: #fff;
  padding-top: 8px;
  p {
    display: inline-block;
    font-size: 13px;
    font-weight: 300;
    width: 100%;
  }
  i {
    vertical-align: middle;
    font-size: 20px;
  }
  .info-icon {
    margin-right: 10px;
    margin-left: 15px;
  }
  .close-icon {
    float: right;
    margin-right: 15px;
    cursor: pointer;
  }
}

.filters-open-container {
  background-color: #ce7e07;
  color: #fff;
  position: fixed;
  right: 15px;
  top: 60px;
  height: 35px;
  width: 85px;
  padding-top: 6px;
  padding-left: 10px;
  cursor: pointer;
  font-size: 16px;
  i {
    vertical-align: middle;
    margin-right: 5px;
  }
  p {
    display: inline-block;
  }
}

.filters-close-container {
  position: fixed;
  right: 250px;
  top: 85px;
  background-color: #ce7e07;
  height: 32px;
  width: 32px;

  cursor: pointer;
  i {
    vertical-align: middle;
    color: #fff;
    margin-top: 4px;
    margin-left: 4px;
  }
}

.filter-container {
  position: fixed;
  right: 0;
  width: 250px;
  height: 100vh;
  background-color: white;
  padding: 20px;
}

.filters-header {
  color: #999999;
  i {
    vertical-align: middle;
    margin-right: 10px;
    margin-left: 2px;
  }
  p {
    display: inline-block;
  }
}

.filter-divider {
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-bottom: 15px;
}

.filters {
  overflow: auto;
}

/***** MEDIA QUERIES *****/
@media only screen and (min-width: 1200px) and (max-width: 1575px) {
  .news-feed-tile {
    width: 50%;
  }
}
@media only screen and (max-width: 800px) {
  .filter-info {
    width: 78%;
  }
  .article-content {
    font-size: 13px !important;
  }
  .news-feed .title {
    font-size: 13px !important;
  }
}

@media (min-width: 544px) and (max-width: 692px) {
  .news-feed .panel {
    height: 420px;
  }
}

@media only screen and (max-width: 600px) {
  .news-feed-tile {
    width: 100%;
  }
  .filter-container {
    top: 50px;
  }
}

@media only screen and (max-width: 600px) {
  .filter-info {
    width: 94%;
    margin-top: 60px;
    height: auto;
    padding-left: 5px;
    min-height: 35px;
  }
}
