/***** DEAL REVIEW PAGE STYLES *****/
.deal-review-page {
  padding: 40px;
}
@media only screen and (max-width: 1024px) {
  .deal-review-page {
    padding: 0;
  }
}
/*** Subheader Styles ***/
.deal-review-subheader {
  background: $primary-color;
  height: 64px;
  margin: -40px -40px 40px -40px;
  color: #fff;
  padding: 0 40px;
  position: sticky;
  top: 0;
  z-index: 12;
}
.deal-review-subheader a:hover {
  color: #fff;
}
@media only screen and (max-width: 1024px) {
  .deal-review-subheader {
    height: 50px;
    margin: -50px 0 24px;
    padding: 0 16px;
    position: sticky;
    top: 0;
  }
}

/***** DEAL REVIEW FILTER FORM STYLES *****/
.deal-review-filter-form-card.card {
  width: 816px;
  max-width: 98%;
  margin: 24px auto;
}
.deal-review-filter-form .two-column-grid {
  display: grid;
  grid-gap: 24px 80px;
  grid-template-columns: repeat(2, 1fr);
}
@media only screen and (max-width: 1024px) {
  .deal-review-filter-form-card.card {
    max-width: 100%;
    margin: 0;
    padding: 16px 16px 80px 16px;
    min-height: calc(100vh - 50px);
    position: relative;
  }
  .deal-review-filter-form-card .xl-text.bold-text.mb-24 {
    margin-bottom: 16px;
    font-size: 18px;
  }
  .deal-review-filter-form .two-column-grid {
    grid-template-columns: 1fr;
  }
}
/*** Select Styles ***/
.deal-review-filter-form-select > .deal-review-filter-form-select__control {
  border: none;
  border-bottom: 1px solid #e0e0e0;
  border-radius: 0;
  min-height: auto;
  min-height: 32px;
  box-shadow: none;
}
.deal-review-filter-form-select
  > .deal-review-filter-form-select__control:hover {
  border-color: #e0e0e0;
}
.deal-review-filter-form-select__value-container {
  padding: 0 !important;
}
.deal-review-filter-form-select__indicator-separator {
  display: none;
}
.deal-review-filter-form-select__indicators {
  height: 30px;
}
/*** Button Checkbox Styles ***/
.deal-review-filter-form-checkbox-btns {
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  display: flex;
  height: 32px;
}
.deal-review-filter-form-checkbox-btns > div {
  width: 50%;
}
.deal-review-filter-form-checkbox-btns > div:not(:first-child) {
  border-left: 1px solid #e0e0e0;
}
.deal-review-filter-form-checkbox-btns input {
  display: none;
}
.deal-review-filter-form-checkbox-btns label {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 300ms linear;
}
.deal-review-filter-form-checkbox-btns > div:first-child > label {
  border-radius: 3px 0 0 3px;
}
.deal-review-filter-form-checkbox-btns > div:last-child > label {
  border-radius: 0 3px 3px 0;
}
.deal-review-filter-form-checkbox-btns input:checked + label {
  background-color: $accent-color;
  color: #fff;
}
/*** Datepicker Styles ***/
.deal-review-filter-form .react-date-picker {
  width: 100%;
  border-bottom: 1px solid #e0e0e0;
  height: 32px;
}
.deal-review-filter-form .react-date-picker__button {
  width: 100%;
}
.deal-review-filter-form .react-date-picker__button__icon {
  display: flex;
}
.deal-review-filter-form .date-placeholder:not(.react-date-picker--open):after {
  content: "Date";
  background: #fff;
  position: absolute;
  height: 31px;
  min-width: 80px;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.38);
  pointer-events: none;
}
.deal-review-filter-form
  .start-date-placeholder:not(.react-date-picker--open):after {
  content: "Start Date";
}
.deal-review-filter-form
  .end-date-placeholder:not(.react-date-picker--open):after {
  content: "End Date";
}
.deal-review-filter-form .react-date-picker__calendar {
  max-width: calc(100vw - 32px) !important;
}
.deal-review-filter-form .end-date-placeholder .react-date-picker__calendar {
  left: auto;
  right: 0;
}
/*** Input Styles ***/
.deal-review-filter-form-input {
  height: 32px;
  border: none;
  border-bottom: 1px solid #e0e0e0;
}
/*** Footer Styles ***/
@media only screen and (max-width: 1024px) {
  .deal-review-filter-form-footer {
    flex-direction: column;
  }
  .deal-review-filter-form-footer p {
    align-self: flex-start;
  }
  .deal-review-filter-form-footer .btn-accent {
    margin-top: 24px;
    width: calc(100% - 32px);
  }
}

/***** DEAL REVIEW LIST STYLES *****/
.deal-review-results {
  flex: 1;
}
/*** Deal Card Styles ***/
.deal-review-card.card {
  padding: 16px;
  max-width: 1200px;
  margin: 0 auto 24px;
}
@media only screen and (max-width: 1024px) {
  .deal-review-card.card {
    width: calc(100vw - 32px);
  }
}
/* Header Styles */
.deal-review-card-header {
  margin: 0 -16px 16px;
  padding: 0 16px 16px;
  border-bottom: 1px solid #e0e0e0;
}
.deal-review-card-header .deal-review-filter-form-select__control {
  border-bottom: none;
  font-size: 12px;
  min-width: 72px;
}
.deal-review-card-header .deal-review-filter-form-select__dropdown-indicator {
  padding: 0;
}
.deal-review-card-header .deal-review-filter-form-select__value-container {
  display: flex;
  justify-content: flex-end;
  padding-right: 8px !important;
}
/* Grid Styles */
.deal-review-card-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 24px 40px;
}
.deal-review-dollar-input {
  border-bottom: 1px solid #e0e0e0;
  height: 32px;
  display: flex;
  align-items: center;
}
.deal-review-dollar-input:before {
  content: "$";
}
.deal-review-dollar-input input {
  border: none;
  padding-left: 8px;
  width: 100%;
  height: auto;
}
.deal-review-card .react-date-picker {
  width: 100%;
  border-bottom: 1px solid #e0e0e0;
  height: 32px;
}
.deal-review-card .react-date-picker__button {
  width: 100%;
}
.deal-review-card .react-date-picker__calendar {
  max-width: 100% !important;
}
@media only screen and (max-width: 1024px) {
  .deal-review-card-grid {
    grid-template-columns: repeat(2, calc(50% - 8px));
    grid-gap: 16px;
  }
  .deal-review-card-grid > div {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }
}
@media only screen and (max-width: 360px) {
  .deal-review-card-grid {
    grid-template-columns: 1fr;
    grid-gap: 16px;
  }
}
/* Footer Styles */
.deal-review-card-footer {
  margin-top: 24px;
}
@media only screen and (max-width: 1024px) {
  .deal-review-card-footer .btn {
    width: 50%;
    padding: 0;
  }
}

/*** Deal Details Sidebar Styles ***/
.deal-review-sidebar {
  width: 0;
  margin: -40px -40px -40px 40px;
  background: #fff;
  box-shadow: -1px 0 4px 0 rgba(0, 0, 0, 0.12);
  height: calc(100vh - 64px);
  overflow: hidden;
  position: sticky;
  top: 64px;
  transition: all 300ms linear;
}
.deal-review-sidebar.open {
  width: 400px;
  padding: 24px 40px;
  overflow: auto;
}
.deal-review-input {
  height: 32px;
  border: none;
  border-bottom: 1px solid #e0e0e0;
}
.deal-details-sidebar-divider {
  border-color: #e0e0e0;
  margin: 24px -40px;
}
.deal-review-details-bank-quote:not(:last-child):after {
  content: ",";
}
.deal-review-details-broker:not(:last-child):after {
  content: ",";
}
.rewarded-quote-info-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px 24px;
}
@media only screen and (max-width: 1024px) {
  .deal-review-sidebar.open {
    position: fixed;
    z-index: 13;
    width: 100%;
    margin: 0;
    padding: 24px;
    overflow-x: hidden;
    height: 100vh;
    top: 0;
  }
  .deal-review-sidebar-close-icon {
    display: none;
  }
}
/* Subheader Styles */
.deal-review-sidebar-subheader.flex {
  @extend .deal-review-subheader;
  display: none;
  margin: -24px -24px 24px -24px;
  top: -24px;
}
@media only screen and (max-width: 1024px) {
  .deal-review-sidebar-subheader.flex {
    display: flex;
  }
}

/*** Update Toast Styles ***/
.update-toast {
  max-width: 98%;
  width: 480px;
  margin: 0 auto;
  border-radius: 3px;
  height: 48px;
  padding-top: 3px;
  position: fixed;
  z-index: 5;
  left: 0;
  right: 0;
  bottom: 15px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  padding: 0 24px;
  color: #fff;
  display: flex;
  align-items: center;
}
.update-toast.success {
  background: #66b066;
}
.update-toast.error {
  background: #b06666;
}
