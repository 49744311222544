.public-login {
  .mui-panel.rate-box {
    width: calc(20% - 16px);
    min-width: 120px;
    padding: 10px 10px 5px 10px;
    border-radius: 3px;
    margin: 4px;
    display: inline-flex;
    font-size: 11px;
  }
}

.public-login {
  .panel-info-value {
    padding-left: 10px;
    padding-bottom: 12px;
  }
}

.public-login-rates {
  width: calc(100% - 350px);
  float: left;
}

.public-login-first-row {
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.rate-panel-left-side {
  display: inline-block;
  width: 85%;
  text-align: left;
}

.arrow-drop-up {
  color: #4caf50;
  display: inline-block;
  vertical-align: middle;
  margin-left: -5px;
}

.arrow-drop-down {
  @extend .arrow-drop-up;
  color: #f6766f;
}

.arrow-rate {
  display: inline-block;
}

.rate-panel-right-side {
  float: right;
}

.rate-box-gray-text {
  color: #999;
}

.public-login-box {
  width: 600px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 120px;
}

.mui-panel.login-box {
  padding: 30px;
  h4 {
    font-weight: 500;
    padding-left: 2px;
    font-size: 18px;
    color: #444;
  }
  input {
    border-radius: 3px;
    margin-right: 20px;
    border: 1px solid #dcdcdc;
    background-color: #f5f5f5;
    padding-left: 15px;
  }
  .custom-select {
    border: 1px solid #dcdcdc;
    padding: 0 8px;
    height: 38px;
    margin-bottom: 24px;
    select {
      height: 38px;
      font-size: 13px;
    }
  }
  .multiselect-dropdown-box {
    top: 32px;
    width: 240px;
    right: 24px;
  }
}

.username-input {
  width: 215px;
  float: left;
  margin-right: 10px;
  margin-bottom: 16px;
  input {
    font-size: 13px;
    height: 36px;
    &:focus {
      height: 36px;
    }
  }
}

.password-input {
  @extend .username-input;
}

.public-login-btn {
  float: left;
  padding: 0 15px;
  height: 36px;
  font-size: 13px;
  font-weight: normal;
  text-transform: none;
  margin-top: 15px;
  box-shadow: -1px 0 4px rgba(0, 0, 0, 0.24);
  i {
    font-size: 19px;
    margin-right: 5px;
    float: left;
    margin-top: 8px;
  }
}

.public-login-btn.mui-btn--flat {
  &:hover {
    background-color: #ce7e07;
    color: #f5f5f5;
  }
}

.request-login {
  margin-top: 5px;
  display: inline-block;
  padding-right: 20px;
  border-right: 1px solid #dcdcdc;
  color: #8b8b8b;
  font-size: 13px;
  a {
    color: #ce7e07;
  }
}

.forgot-password {
  display: inline-block;
  padding-left: 18px;
  color: #8b8b8b;
  font-size: 13px;
  a {
    color: #ce7e07;
  }
}

.complete-field-error {
  clear: both;
  margin-bottom: 10px;
}

.news-sidebar {
  height: calc(100vh - 64px);
  width: 350px;
  background-color: #fff;
  float: right;
  overflow: auto;
  h4 {
    padding: 25px 20px 10px 20px;
    font-weight: 500;
    font-size: 18px;
  }
}

#user-page .news-sidebar {
  height: 100vh;
}
.news-sidebar-content {
  min-height: calc(100vh - 200px);
}

.public-feed-item {
  width: 320px;
  margin-right: auto;
  margin-left: auto;
  border-radius: 3px;
  padding-bottom: 10px;
  .article-date {
    margin-left: 0;
  }
}

.public-feed-image {
  @extend .feed-image;
  height: 185px;
}

.feed-info {
  margin-top: 10px;
}

.read-more-btn {
  padding: 0 10px;
  font-size: 14px;
  height: auto;
  margin-left: 20px;
  background-color: #00112b;
  margin-top: 0;
  animation-duration: 0.1ms;
  animation-name: mui-node-inserted;
  font-weight: 500;
  line-height: 36px;
  text-transform: uppercase;
  transition: all 0.2s ease-in-out;
  display: inline-block;
  margin-bottom: 6px;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  background-image: none;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  letter-spacing: 0.03em;
  position: relative;
  overflow: hidden;
  color: #fff;
  &:hover {
    background-color: transparent;
    color: #00112b;
  }
}

.first-name-input {
  width: 49%;
  display: inline-block;
  margin-right: 2%;
}

.last-name-input {
  width: 49%;
  display: inline-block;
}

.sign-up-form {
  .mui-textfield {
    margin-bottom: 10px;
    input {
      font-size: 13px;
      height: 38px;
    }
  }
}

.company-input {
  margin-bottom: 25px !important;
}

.back-to-login {
  float: right;
  margin-right: 2px;
  font-size: 13px;
  span {
    margin-left: 3px;
  }
}

.comps-toast-error-btn {
  text-transform: uppercase;
  float: right;
  margin-right: 15px;
  margin-top: 12px;
  font-weight: 500;
  font-size: 16px;
  color: #ce7e07;
  &:hover {
    color: #fff;
  }
}

.back-login {
  clear: both;
  font-size: 13px;
}

.create-account {
  padding-left: 30px;
  font-size: 13px;
  color: #aaa;
}

@media only screen and (min-width: 1500px) {
  .news-container {
    width: 23%;
    float: right;
  }
}

@media (min-width: 1200px) and (max-width: 1300px) {
  .public-login-box {
    width: 500px;
  }
  .mui-panel.login-box {
    padding: 20px;
  }
  .username-input {
    width: 166px !important;
  }
}

@media (min-width: 959px) and (max-width: 1030px) {
  .public-login-first-row {
    margin-left: 40.5px;
  }
}

@media (min-width: 901px) and (max-width: 975px) {
  .public-login-box {
    width: 490px;
  }
  .username-input {
    width: 205px;
  }
}

@media only screen and (max-width: 900px) {
  .news-sidebar {
    display: none;
  }
  .public-login-rates {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 800px) {
  .public-login-first-row {
    width: 100%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (max-width: 767px) {
  .news-container {
    display: none;
  }
}

@media only screen and (max-width: 635px) {
  .public-login-box {
    width: 240px;
  }
}

@media only screen and (max-width: 515px) {
  .public-login-first-row {
    display: none;
  }
  .public-login-box {
    margin-top: 88px;
  }
  .login-box {
    text-align: center;
  }
  .username-input {
    width: 100%;
  }
  .public-login-btn {
    width: 100%;
    margin-bottom: 16px;
    i {
      float: none;
      margin-top: -3px;
      vertical-align: middle;
    }
  }
  .create-account {
    text-align: center;
    padding-left: 0;
    margin-bottom: 32px;
  }
  .back-to-login {
    float: none;
  }
}
