.deal-contacts-page {
  .quote-section {
    font-size: 17px;
    width: 75%;
  }
}

.deal-contacts {
  .panel {
    padding: 20px;

    cursor: pointer;
  }
}

.first-letter {
  font-size: 22px;
  background-color: #8b8b8b;
  width: 35px;
  height: 35px;
  border-radius: 50px;
  color: #fff;
  padding-top: 2px;
  text-align: center;
  float: right;
  margin-right: 20px;
}

.deal-contacts {
  .contact-name {
    display: inline-block;
    height: 35px;
    padding-top: 8px;
    font-weight: 500;
  }
  .contact-position {
    display: inline-block;
    color: #8b8b8b;
    padding-top: 8px;
  }
}

.email-container {
  a:hover,
  a:focus {
    color: #ce7e07;
    text-decoration: none;
  }
}

.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.email-container {
  margin-bottom: 10px;
}

#email-icon {
  height: 30px;
}

#phone-icon {
  height: 30px;
}

.phone-container {
  @extend .email-container;
}

.contact-modal-form {
  .mui-divider {
    margin-left: 100px;
  }
}

@media only screen and (min-width: 1500px) {
  .first-letter {
    margin-right: 40px;
  }
}
