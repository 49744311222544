.sub-header {
  overflow: hidden;
  background-color: #00112b;
  position: fixed;
  width: calc(100% - 224px);
  z-index: 8;
  height: 60px;
  ul {
    margin-bottom: 0;
    padding-left: 0;
    text-align: center;
    padding-top: 7px;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    height: 100% !important;
    margin-left: 40px;
    -webkit-overflow-scrolling: touch;
    // hide scroll bar https://stackoverflow.com/a/49278385
    scrollbar-width: none /* Firefox */;
    -ms-overflow-style: none /* Internet Explorer 10+ */;
    li {
      display: inline-block;
      list-style-type: none;
      a,
      span {
        width: auto;
        font-size: 13px;
        line-height: 53px;
        color: rgba(255, 255, 255, 0.7);
        text-transform: uppercase;
        padding-bottom: 16px;
        padding-left: 20px;
        padding-right: 20px;
        transition: color 300ms linear, border 300ms linear;
      }
      a:hover {
        color: #fff;
        border-bottom: 3px solid #ce7e07;
      }
      span:hover {
        color: #fff;
        border-bottom: 3px solid #ce7e07;
      }
      .header-active-link {
        color: #fff;
        border-bottom: 3px solid #ce7e07;
      }
    }
  }
}
.sub-header ul::-webkit-scrollbar {
  display: none;
}
.mui-row.sub-header {
  margin-top: -3px;
}

.back-arrow img {
  height: 24px;
  margin-top: 20px;
  margin-left: 24px;
  float: left;
  position: absolute;
  z-index: 1;
}
@media only screen and (max-width: 480px) {
  .back-arrow img {
    margin-left: 8px;
  }
}

.sub-header-close {
  color: #fff;
  font-size: 24px;
  margin-top: 20px;
  margin-left: 24px;
  float: left;
}
.sub-header-close:hover {
  cursor: pointer;
  transition: all 300ms linear;
  opacity: 0.7;
  color: #fff;
}

.page-title {
  display: inline-block;
  margin-top: 23px;
  font-size: 16px;
  margin-left: 13px;
}

.sub-header-padding {
  padding-top: 84px;
}

.sub-header-flex {
  display: flex;
  align-items: center;
  padding: 0 24px;
}
.sub-header-flex .back-arrow img {
  margin: 0;
  position: relative;
}
.sub-header-flex .sub-header-title {
  margin: 0 16px;
}

.inner-subheader-container {
  width: 80%;
  margin: 0 auto;
  position: relative;
}
@media only screen and (max-width: 1500px) {
  .inner-subheader-container {
    width: 98%;
  }
}

/*** Nav Search Subheader Styles ***/
.nav-search-sub-header {
  overflow: visible;
}
.nav-search-sub-header > div {
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin: 0 auto;
  height: 100%;
}
.nav-search-sub-header > div > div:nth-child(2) {
  flex: 1;
  max-width: 540px;
}
.nav-search-sub-header ul {
  text-align: left;
}
// .nav-search-sub-header ul li a {padding-left: 8px; padding-right: 8px;}
.nav-search-sub-header .search-container {
  margin: 0;
  width: 100%;
  transform: none;
  white-space: nowrap;
}
.mobile-header-searchbar {
  height: 120px !important;
}
.search-container-mobile {
  margin-left: 15px !important;
  margin-right: 5px !important;
  width: 100% !important;
}
.mobile-dropdown-container {
  display: none;
  text-align: right;
}
.mobile-dropdown {
  position: relative;
}
.mobile-dropdown i {
  vertical-align: middle;
  color: #fff;
}
.mobile-dropdown-content {
  background: #fff;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 24px;
  top: 0;
  padding: 8px 16px;
  text-align: left;
  width: 184px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  opacity: 1;
  visibility: visible;
  transition: opacity 300ms linear;
}
.mobile-dropdown-content-hidden {
  opacity: 0;
  visibility: hidden;
}
.mobile-dropdown-content a {
  display: block;
  line-height: 2;
  font-size: 13px;
  transition: color 300ms linear;
}
.mobile-dropdown-content a:hover {
  color: #ce7e07;
}
@media only screen and (max-width: 1200px) {
  .nav-search-sub-header > div {
    width: 90%;
  }
  .nav-search-sub-header > div > div:last-child {
    margin-left: 24px;
  }
}
@media only screen and (max-width: 992px) {
  .nav-search-sub-header > div {
    width: 96%;
  }
  .nav-search-sub-header > div > div:last-child {
    margin-left: 16px;
  }
}
@media only screen and (max-width: 872px) {
  .nav-search-sub-header-menu {
    display: none;
  }
  .nav-search-sub-header > div > div:nth-child(2) {
    margin-left: 0;
  }
  .mobile-dropdown-container {
    display: block;
    margin: 0 5px 0 10px;
  }
}

/*** Nav Search Dropdown Subheader Styles ***/
.search-container.search-dropdown-container {
  display: flex;
}
.search-dropdown-container .mui-select {
  border-right: 1px solid #fff;
  margin-right: 16px;
  padding-top: 0;
  margin-bottom: 0;
  padding-right: 16px;
  min-width: 152px;
}
.search-dropdown-container .mui-select > select {
  border-bottom: none;
  font-size: 14px;
  font-weight: 300;
  color: #fff;
  padding-left: 8px;
  background-image: url("/images/icons/dropdown-arrow.png");
  background-size: 8px;
}
.search-dropdown-container form {
  width: 100%;
}
.search-dropdown-container .input {
  width: calc(100% - 36px);
}

@media only screen and (min-width: 872px) and (max-width: 1056px) {
  .contacts-page {
    .sub-header ul li a {
      font-size: 11px;
    }
    .search-dropdown-container .mui-select {
      min-width: 130px;
    }
    .search-dropdown-container .mui-select > select {
      font-size: 11px;
    }
    .search-dropdown-container .input {
      font-size: 11px;
    }
  }
}

@media only screen and (max-width: 959px) {
  .sub-header {
    width: 100% !important;
  }
}
