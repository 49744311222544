.panel {
  width: 80%;
  margin: 20px auto;
  padding-bottom: 8px;
  border-radius: 3px;
  background-color: #fff;
  cursor: pointer;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 2px 0 rgba(0, 0, 0, 0.12);
  .panel-heading {
    background-color: #fcfcfc;
    border-bottom: 1px solid #dcdcdc;
    border-radius: 3px 3px 0 0;
    height: 55px;
    .property-title {
      padding-top: 20px;
      padding-bottom: 15px;
      font-size: 13px;
      text-transform: uppercase;
      font-weight: bold;
    }
  }
  .small-title {
    margin-top: 20px;
    padding-left: 40px;
    color: #999999;
    font-size: 13px;
  }
  .small-title.no-padding-left {
    padding-left: 0;
  }
  .panel-info-value {
    padding-left: 40px;
    margin-bottom: 0;
    font-size: 14px;
    .tag {
      height: 20px;
    }
  }
}

.panel:hover {
  transition: all 300ms linear;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.16), 0 2px 2px rgba(0, 0, 0, 0.23);
}

.material-ui-panel {
  margin-bottom: 20px;
}

@media only screen and (max-width: 959px) {
  .small-title {
    padding-left: 20px;
  }
  .panel-info-value {
    padding-left: 20px;
  }
}

@media only screen and (max-width: 600px) {
  .panel {
    width: 94%;
  }
}
