.deals {
  padding-top: 75px;
}

.deal-name {
  display: inline-block;
}
.deal-star {
  display: inline-block;
  float: right;
}

/* chani's code */
.ip-deal {
  .panel {
    padding-bottom: 10px;
  }
  .small-title {
    font-size: 13px;
    margin-top: 0;
  }
  .panel-info-value {
    font-size: 14px;
  }
  .last-update {
    color: rgba(0, 0, 0, 0.54);
  }
  .panel-heading {
    background-color: #fcfcfc;
    border-bottom: 1px solid #dcdcdc;
    border-radius: 3px 3px 0 0;
    height: 55px;
    margin-bottom: 16px;
    padding-left: 16px;
    .property-title {
      padding-top: 20px;
      padding-bottom: 15px;
      font-size: 13.5px;
      text-transform: uppercase;
      font-weight: bold;
    }
  }
}

.client-name {
  &:hover {
    color: #ce7e07;

    cursor: pointer;
    cursor: hand;
  }
}

.spinner {
  position: absolute;
  width: 88px;
}
.no-contact {
  margin-left: 3%;
  margin-top: 20px;
}

.deal-rating-modal-form {
  padding: 10px;
  .modal-exit {
    color: #8b8b8b;
    font-size: 20px;
    margin-right: 10px;
  }
}

.change-rating-header {
  font-weight: 500;
  padding-left: 10px;
}

.deal-ratings-container {
  margin-top: 25px;
}

.deal-rating-modal-done {
  padding: 0 10px;
  height: auto;
  font-size: 16px;
  position: absolute;
  bottom: 10px;
  right: 20px;
}

.deal-rating-modal-done.mui-btn--flat {
  background-color: transparent;
  color: #ce7e07;
  font-size: 14px;
}

.ip-quote-star {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-top: 14px;
  margin-right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ip-quote-star .star-icon-orange {
  font-size: 20px;
  position: relative;
  z-index: 1;
  margin-top: 0;
  color: #fff;
  background-color: #ce7e07;
  padding-top: 1px;
  padding-left: 1px;
  border-radius: 2px;
  height: 100%;
  width: 100%;
}

.ip-quote-star .star-icon-gray {
  @extend .star-icon-orange;
  background-color: #8b8b8b !important;
  color: #fff !important;
  font-size: 20px !important;
}

.quotes-btn {
  margin-left: 40px;
  text-align: center;
  background-color: #ce7e07;
  width: 100px;
  height: 36px;
  padding: 0;
  margin-top: 20px;
  margin-bottom: 15px;
  color: #fff;
  font-size: 13px;
  border-radius: 3px;
  display: inline-block;
  box-shadow: -1px 0 4px rgba(0, 0, 0, 0.18);
  text-transform: uppercase;
  letter-spacing: 0.5px;

  cursor: pointer;
}

.notes-title {
  margin-top: 20px !important;
}

.padding-left-note {
  padding-left: 40px;
}

.fixed-width-li a {
  padding-left: 50px !important;
  padding-right: 50px !important;
}

@media only screen and (max-width: 600px) {
  .fixed-width-li a {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
}
