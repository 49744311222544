.sub-header.comps-sub-header {
  overflow: visible;
  height: 64px;
  z-index: 12;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sub-header.comps-sub-header .back-arrow img {
  height: 24px;
  margin: 0 -8px 0 16px;
  float: none;
  position: unset;
}
.comps-page {
  height: calc(100vh - 40px);
  overflow: hidden;
  position: relative;
}
#user-page .comps-page {
  height: 100vh;
}

/***** COMPS SEARCH STYLES *****/
.comps-search-box-container {
  background-color: #eaeaed;
  border-radius: 3px;
  width: calc(100% - 720px);
  max-width: 480px;
  margin: 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 12;
  position: relative;
}
.comps-search-dropdown-input {
  width: 84%;
  height: 32px;
  font-size: 13px;
  padding-left: 10px;
  border-radius: 3px;
  border: none;
  color: #00112b;
  background: transparent;
  &:focus {
    outline: none;
    border: none;
  }
}
.comps-search-dropdown-input::-webkit-input-placeholder {
  color: #00112b;
}
.comps-search-dropdown-input::-moz-placeholder {
  color: #00112b;
}
.comps-search-dropdown-input:-ms-input-placeholder {
  color: #00112b;
}
.search-icon-navy {
  font-size: 16px;
  margin-left: 8px;
  color: #00112b;
}
.search-arrow {
  margin-right: 8px;
  cursor: pointer;
  color: #00112b;
  opacity: 1;
  transition: opacity 300ms linear;
}
.hidden-opacity .search-arrow {
  opacity: 0;
  visibility: hidden;
}
@media only screen and (max-width: 1048px) {
  .comps-search-box-container {
    width: 100%;
    max-width: none;
  }
}
@media only screen and (max-width: 600px) {
  .comps-search-box-container {
    margin: 16px 12px;
  }
}
/* Comps Filter Styles */
.comps-filters {
  display: flex;
}
.comps-filter {
  position: relative;
  height: 32px;
  padding: 0 12px;
  background: rgba(255, 255, 255, 0.12);
  border-radius: 3px;
  color: #fff;
  display: flex;
  align-items: center;
  font-size: 12px;
  letter-spacing: 0.5px;
  margin-right: 8px;
  transition: background 300ms linear;
}
.comps-filter:hover {
  background: #ce7e07;
  cursor: pointer;
}
.comps-filter.selected {
  background: #ce7e07;
}
.comps-filter-dropdown {
  top: 40px;
  left: 0;
  position: absolute;
  background: #fff;
  padding: 16px;
  width: 224px;
}
.comps-filter-dropdown-scroll {
  max-height: 280px;
  overflow: auto;
  margin-bottom: 32px;
  margin-right: -16px;
  padding-right: 16px;
}
.comps-filter-dropdown .rkmd-checkbox {
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.87);
  margin-left: -6px;
}
.comps-filter-dropdown .rkmd-checkbox .input-checkbox {
  width: 32px;
}
.comps-filter-dropdown .rkmd-checkbox .label {
  margin-top: 2px;
}
.comps-filter-dropdown-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
  position: absolute;
  bottom: 16px;
  width: calc(100% - 32px);
}
.comps-filter-dropdown-footer p:hover {
  opacity: 0.6;
  transition: opacity 300ms linear;
  cursor: pointer;
}
.comps-prop-cat-dropdown {
  width: 320px;
}
.comps-prop-cat-row {
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #eeeeee;
  border-radius: 3px;
  padding: 4px 8px;
  margin-bottom: 8px;
  .circle {
    height: 8px;
    width: 8px;
    border-radius: 100%;
    margin-right: 8px;
  }
  .green {
    background: #34a853;
  }
  .blue {
    background: #4285f4;
  }
  .yellow {
    background: #fbbc05;
  }
  .purple {
    background: #713bbe;
  }
  .pink {
    background: #e86af0;
  }
  .gray {
    background: #c3c3c3;
  }
  i {
    margin-right: 12px;
    font-size: 20px;
  }
  .lbl {
    width: 32px;
    height: 14px;
  }
  .lbl:after {
    height: 16px;
    width: 16px;
    top: -1px;
    left: -1px;
  }
  .filter-bar {
    margin: 8px 0;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
  .filter-bar input[type="radio"] + label {
    font-size: 10px;
    text-transform: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 0;
  }
  .filter-bar div:not(:last-child) input[type="radio"] + label {
    border-right: 1px solid #ccc;
  }
}
.comps-min-max .mui-textfield > input {
  font-size: 12px;
}
/* Comps Filter Styles - Mobile */
.comps-filter-icon {
  display: none;
  color: #fff;
  padding-left: 8px;
  vertical-align: middle;
}
.comps-filter-icon i {
  font-size: 16px;
}
.comps-filters-mobile {
  position: absolute;
  width: 100%;
  padding: 16px 16px 0 16px;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  left: 0;
  top: 40px;
}
.comps-filters-mobile > div:first-child {
  max-height: 400px;
  overflow: auto;
  margin-right: -16px;
  padding-right: 16px;
  margin-bottom: 40px;
}
.comps-filters-mobile .bordered-section {
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid #e0e0e0;
}
.comps-filters-mobile .bordered-section > p {
  margin-bottom: 8px;
}
.comps-filters-mobile .bordered-section > div {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.comps-filters-mobile .comps-filter-dropdown-footer {
  bottom: 0;
  height: 40px;
  background: #fff;
  z-index: 20;
}
.comps-filters-mobile .rkmd-checkbox {
  display: flex;
  align-items: center;
  font-weight: normal;
  color: #333;
  margin-left: -4px;
}
@media only screen and (max-width: 1048px) {
  .comps-filters {
    display: none;
  }
  .comps-filter-icon {
    display: flex;
  }
}
@media only screen and (max-width: 720px) {
  .comps-filters-mobile .bordered-section > div {
    grid-template-columns: 1fr;
  }
}
/* Comps Search Dropdown (Filters) Styles */
.comps-search-dropdown-box {
  position: absolute;
  height: auto;
  background-color: #fff;
  border-radius: 3px;
  margin-top: -38px;
  padding-top: 5px;
  padding-bottom: 8px;
  z-index: 12;
  width: 100%;
  top: 36px;
  .mui-select select {
    font-size: 14px;
  }
}
.reonomy-link-comps {
  margin-right: 8px;
}
.comps-search-close {
  color: #999 !important;
  font-size: 18px;
  margin-right: 10px;
  margin-top: 3px;
  cursor: pointer;
}
.property-container {
  padding: 0 15px;
}
.property-type-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.26);
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  height: 47px;
  padding-left: 0;
  cursor: pointer;
  padding-top: 25px;
  margin-bottom: 12px;
}
.property-arrow {
  color: #c2c2c2;
  margin-top: -40px;
  float: right;
  cursor: pointer;
}
.comps-dropdown-box {
  @extend .qts-dropdown-box;
  top: 80px !important;
  right: auto !important;
  margin-left: 0;
  position: fixed !important;
  .label {
    display: inline-block;
    width: 120px;
    vertical-align: middle;
  }
}
.comps-search-disabled {
  cursor: not-allowed !important;
  color: #8b8b8b;
  background-color: transparent !important;
  &:hover {
    background-color: transparent !important;
  }
}
.radius-container {
  padding: 0 15px;
  .mui-select .mui-select__menu {
    top: 50px !important;
    height: 250px !important;
    overflow: auto;
  }
}
.closed-container {
  @extend .radius-container;
  .mui-select .mui-select__menu {
    right: 0 !important;
    height: auto !important;
  }
}
.comps-search-input {
  padding: 0 15px;
}
.comps-search-input.dollar-input {
  background-position: 16px 8px;
}
.comps-search-input input {
  font-size: 14px;
}
.floating-row {
  float: right;
  padding: 0 15px;
}
.reset-filters-container {
  @extend .radius-container2;
  float: right;
}
.reset-filters {
  color: #ce7e07 !important;
  text-align: center;
  background-color: #f5f5f5 !important;
  font-size: 13px;
  padding: 0 10px;
  height: auto;
  margin: 0 10px;
  &:hover {
    background-color: #f5f5f5 !important;
    color: #ce7e07 !important;
  }
  &:focus {
    box-shadow: none !important;
  }
}
.search-drpdwn-close-btn {
  padding: 0 15px !important;
  margin-left: 15px;
  height: auto;
  font-size: 12px;
}

/*** Search Success Styles ***/
.success-message-2 {
  width: 80%;
  margin-right: auto;
  margin-left: auto;
  background-color: rgba(0, 0, 0, 0.54);
  border-radius: 3px;
  position: absolute;
  left: 0;
  right: 0;
  top: 80px;
  max-width: 500px;
  transform: translateX(-32px);
  height: 24px;
  line-height: 24px;
  padding: 0;
  font-size: 12px;
  font-weight: 300;
  text-align: center;
  p {
    color: #fff;
    display: inline-block;
  }
}

/***** MAP STYLES *****/
#map {
  height: 100%;
  width: calc(100% - 302px);
  display: inline-block;
}
.map-full-width {
  width: 100% !important;
}
.gm-bundled-control-on-bottom {
  bottom: 128px !important;
  right: 48px !important;
}
.comps-sidebar-open .gm-bundled-control-on-bottom {
  right: 348px !important;
  bottom: 88px !important;
}

/*** Map Pop Up Styles ***/
.comp-popup {
  position: absolute;
  bottom: 32px;
  background-color: #fff;
  width: 400px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 6px 6px 0 0;
  box-shadow: 0px 30px 56.4px 3.6px rgba(0, 0, 0, 0.3);
  opacity: 1;
  transition: all 300ms linear;
}
.comp-popup-top {
  bottom: 184px;
}
.comp-popup-header {
  border-bottom: 2px solid rgba(0, 0, 0, 0.12);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
}
.comp-popup-header p {
  flex: 1;
}
.comp-popup-header i {
  color: #999;
  font-size: 17px;
  cursor: pointer;
}
.comp-popup-body {
  padding: 16px;
  position: relative;
  overflow: auto;
  border-bottom: 2px solid rgba(0, 0, 0, 0.12);
}
.comp-popup-body .small-text {
  margin-bottom: 16px;
}
.comp-popup-footer {
  padding: 16px;
}

@media only screen and (max-width: 1200px) {
  .comp-popup-top {
    bottom: 248px;
  }
}

@media only screen and (max-width: 800px) {
  .comp-popup-top {
    bottom: 384px;
  }
}

@media only screen and (max-width: 600px) {
  .comp-popup,
  .comp-popup-top {
    bottom: 64px;
  }
}

@media only screen and (max-width: 480px) {
  .comp-popup {
    width: 240px;
  }
  .comp-popup-body {
    max-height: 200px;
  }
}

/* request address update */
.request-address-update {
  cursor: pointer;
  color: #ce7e07;
  float: right;
  margin-right: 8px;
  margin-top: -16px;
  font-size: 11px;
}
.request-address-update-small {
  cursor: pointer;
  color: #ce7e07;
  float: right;
  margin-right: 8px;
  margin-top: -12px;
  font-size: 10px;
}
.request-address-update-property {
  @extend .request-address-update;
  margin-top: -32px;
}
.request-address-update-listing {
  @extend .request-address-update;
  margin-top: -22px;
}

/***** TOGGLE SIDE BAR STYLES *****/
.mui-btn.toggle-side-bar {
  display: inline-block;
  position: absolute;
  padding: 0 0px 0 10px;
  font-size: 13px;
  right: 330px;
  top: 68px;
  height: auto;
}
.mui-btn.show-listing {
  padding: 0 15px 0 10px;
  right: 10px;
}

/***** COMPS MODALS *****/
.comps-error-modal {
  width: 350px;
  height: 350px;
  border-radius: 3px;
  position: absolute;
  margin: auto;
  overflow: hidden;
}
.comps-loading-modal {
  @extend .comps-error-modal;
  background-color: transparent !important;
}
.error-circle {
  margin-left: auto;
  margin-right: auto;
  width: 30%;
  display: block;
  margin-top: 20px;
  background-color: #ff5252;
  padding: 15px;
  border-radius: 100px;
}
.modal-error-title {
  text-align: center;
  font-weight: 500;
}
.error-message {
  text-align: center;
}
.mui-btn.mui-btn--flat.modal-error-btn {
  padding: 0 10px;
  color: #ff5252;
  background-color: transparent;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  font-size: 16px;
  &:hover {
    color: #000;
    background-color: transparent;
  }
}

/***** MAP KEY STYLES *****/
.map-key {
  position: absolute;
  bottom: 40px;
  left: 8px;
  background: rgba(255, 255, 255, 0.9);
  min-width: 144px;
  padding: 12px;
}
.map-key-header {
  color: #616161;
}
.map-key-header i {
  font-size: 18px;
}
.map-key .keys i {
  vertical-align: middle;
  font-size: 10px;
  margin-top: -2px;
  margin-right: 8px;
}
.map-key i.red {
  color: #ea4335;
}
.map-key i.yellow {
  color: #fbbc05;
}
.map-key i.blue {
  color: #4285f4;
}
.map-key i.green {
  color: #34a853;
}
.map-key i.gray {
  color: #c3c3c3;
}
.map-key i.purple {
  color: #713bbe;
}
.map-key p.xs-text {
  margin-top: 12px;
}

/*** Map Key Toggle Styles ***/
.map-key .keys {
  transition: height 300ms linear;
  height: 144px;
  overflow: hidden;
}
.map-key-small .keys {
  height: 88px;
}
.map-key.closed .keys {
  height: 0;
  overflow: hidden;
}

@media only screen and (max-width: 900px) {
  .map-key {
    bottom: auto;
    top: 80px;
  }
}

/***** SIDEBAR STYLES *****/
.comps-side-bar {
  background-color: #f5f5f5;
  height: calc(100vh - 136px);
  width: 360px;
  overflow: auto;
  position: fixed;
  z-index: 10;
  top: 104px;
  right: 0;
  font-size: 12px;
}
#user-page .comps-side-bar {
  height: calc(100vh - 96px);
  top: 64px;
}
.comps-tabs {
  padding-left: 0;
  display: flex;
  background: #fff;
}
.comps-tab {
  width: 50%;
  text-align: center;
  padding: 15px;
  padding-bottom: 18px;
  margin-bottom: 0;
  color: rgba(0, 0, 0, 0.54);
  font-weight: 500;
}
.comps-tab.tab-active {
  background: #f5f5f5;
  border-bottom: none;
  color: rgba(0, 0, 0, 0.54);
}

/*** Sidebar - Salesbrokers Styles ***/
.salesbroker-section-header {
  background: #666;
  color: #fff;
  font-size: 12px;
  margin: 12px 8px 0 8px;
  padding: 0 8px;
  height: 24px;
  line-height: 24px;
  font-weight: 300;
}
.sales-firm {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 16px;
}
.sales-firm-footer {
  color: rgba(0, 0, 0, 0.54);
}
.sales-broker {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 16px;
}
.salesbroker-info .mui-btn.mui-btn--flat.button.btn-primary {
  height: 32px;
  line-height: 32px;
  margin: 16px;
  width: calc(100% - 32px);
  font-size: 12px;
  font-weight: 300;
}

/*** Sidebar - Comps Styles ***/
.comp {
  padding: 12px 16px 8px 36px;
  overflow: auto;
  width: calc(100% - 32px);
}
.comp.panel:hover {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 2px 0 rgba(0, 0, 0, 0.12);
  cursor: default;
}
.comp-header {
  display: flex;
  justify-content: space-between;
}
.comp .mui-divider {
  margin: 8px -16px 8px -36px;
}
.comps-icon {
  margin-left: -24px;
  padding-right: 10px;
  font-size: 12px;
  margin-top: 2px;
  color: #34a853;
}
.details-label {
  font-size: 10px;
  color: rgba(0, 0, 0, 0.54);
}
.comp p {
  font-size: 11px;
}
.comp p.xxs-text {
  margin-bottom: 4px;
  font-size: 10px;
}
.comp p.xxs-text + p.accent-text {
  font-size: 10px;
}
.comps-padding-bottom {
  padding-bottom: 6px;
  display: flex;
  justify-content: space-between;
}

/*** Sidebar - Listings Styles ***/
.listing {
  @extend .comp;
}
.listing-header {
  @extend .comp-header;
}
.listings-icon {
  @extend .comps-icon;
  color: #4285f4;
}

/*** Sidebar - Open/Close Styles ***/
.mui-btn.toggle-side-bar {
  position: absolute;
  padding: 0;
  font-size: 13px;
  right: 360px;
  top: 88px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #00112b;
}
.mui-btn.show-listing {
  padding: 0;
  right: 0;
}
.open-comps-side-bar {
  font-size: 12px;
  padding-right: 8px;
  line-height: 32px;
}
.open-comps-side-bar i {
  vertical-align: middle;
  margin-top: -3px;
}

@media only screen and (max-width: 600px) {
  .open-comps-show {
    display: none;
  }
  .mui-btn.toggle-side-bar {
    height: auto;
    padding: 4px 0;
  }
  .list-view-block {
    margin-left: 32px;
    display: block;
  }
  .open-comps-side-bar {
    line-height: 16px;
  }
}

/*** Sidebar - Empty State Styles ***/
.comps-side-bar-empty {
  height: calc(100vh - 198px);
  padding: 16px;
}
.comps-empty-state-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100% - 48px);
}
.comps-empty-state-text i {
  font-size: 104px;
  margin-bottom: 32px;
  color: #9e9e9e;
}
.comps-empty-state-text p {
  font-weight: 500;
  color: #9e9e9e;
  font-size: 14px;
  text-align: center;
}

@media only screen and (max-width: 448px) {
  .comps-side-bar {
    width: 224px;
  }
  .mui-btn.toggle-side-bar {
    right: 224px;
  }
  .mui-btn.toggle-side-bar.show-listing {
    right: 0;
  }
  .comps-tab {
    padding: 15px 8px;
  }
  .comp {
    padding: 12px 8px 4px 32px;
  }
}

/***** BROKERS BOTTOM BAR STYLES *****/
.brokers-bottom-bar {
  background: #f5f5f5;
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: 10;
  height: 187px;
  transition: height 300ms linear;
}
.brokers-bottom-bar-mobile {
  display: none;
}
.brokers-bottom-bar.collapsed {
  height: 32px;
}
.brokers-bottom-bar.show-top-ten {
  height: 436px;
}

/* Brokers Bar Styles */
.brokers-bar {
  background: #333;
  color: #fff;
  height: 32px;
  padding: 0 24px;
  color: #fff;
  cursor: pointer;
}
.brokers-bar-left {
  align-items: center;
}
.brokers-bar-left p {
  margin-right: 24px;
}
.brokers-bar-left .mui-btn.mui-btn--flat.button.btn-action {
  height: 20px;
  line-height: 20px;
  font-size: 10px;
  text-transform: none;
  padding: 0 24px;
  font-weight: 300;
}

/* Brokers Top Section Styles */
.brokers-top-section {
  padding: 8px 24px 32px 24px;
}
.brokers-top-section-titles {
  margin-bottom: 8px;
}

/* Brokers Bottom Section Styles */
.brokers-bottom-section {
  padding: 0 24px 32px 24px;
}

/* Broker Grid Styles */
.broker-grid {
  display: grid;
  grid-template-columns: calc(20% - 12px) calc(20% - 12px) calc(20% - 12px) calc(
      20% - 12px
    ) calc(20% - 16px);
  grid-gap: 16px;
}
.grid-column-three {
  grid-column-start: 1;
  grid-column-end: 4;
}
.grid-column-five {
  grid-column-start: 1;
  grid-column-end: 6;
}

/* Broker Card Styles */
.broker-card {
  background: #fff;
  padding: 8px 16px;
  display: flex;
}
.broker-image {
  margin-right: 16px;
  height: 72px;
  width: 72px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.broker-image img {
  object-fit: cover;
  height: 72px;
}
.broker-info {
  height: 72px;
}
.broker-contact-info {
  height: 28px;
}
.broker-info .mui-btn.mui-btn--flat.button.btn-accent {
  height: 20px;
  line-height: 20px;
  font-size: 10px;
  text-transform: none;
  padding: 0 24px;
  font-weight: 300;
  border: 1px solid #ce7e07;
  margin-bottom: 0;
}

/*** Broker Empty State Styles ***/
.brokers-empty-state {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #9e9e9e;
}
.brokers-empty-state i {
  font-size: 36px;
  margin-bottom: 8px;
}
.brokers-empty-state p {
  color: #9e9e9e;
  font-size: 14px;
  text-align: center;
}

/*** QTS Rep Card Styles ***/
.qts-rep-title-mobile {
  display: none;
}
.qts-rep-card {
  @extend .broker-card;
  background: #e0e0e0;
}
.qts-rep-image {
  @extend .broker-image;
}

/*** Brokers Top Section Btns ***/
.brokers-top-section-btns .mui-btn.mui-btn--flat.button.btn-accent {
  margin: 0 0 10px 0;
}
.brokers-top-section-btns .mui-btn.mui-btn--flat.button.btn-accent2 {
  margin: 0;
}

@media only screen and (max-width: 1200px) {
  .brokers-bottom-bar {
    height: 248px;
  }
  .brokers-bottom-bar.show-top-ten > div:nth-child(2) {
    height: 404px;
    overflow: auto;
  }
  .broker-card {
    flex-direction: column;
    align-items: center;
  }
  .qts-rep-card {
    height: 100%;
  }
  .broker-image {
    margin-right: 0;
    margin-bottom: 8px;
  }
  .broker-info {
    text-align: center;
  }
}

@media only screen and (max-width: 968px) {
  .brokers-bottom-section .broker-grid {
    grid-template-columns: calc(25% - 12px) calc(25% - 12px) calc(25% - 12px) calc(
        25% - 12px
      );
  }
}

@media only screen and (max-width: 800px) {
  .brokers-bottom-bar {
    height: 384px;
    overflow: auto;
  }
  .brokers-top-section .broker-grid {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .brokers-bottom-section .broker-grid {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .qts-rep-title {
    display: none;
  }
  .qts-rep-title-mobile {
    display: block;
    margin-bottom: 8px;
  }
  .brokers-top-section-btns {
    margin-top: 24px;
  }
}

@media only screen and (max-width: 600px) {
  .brokers-bottom-bar {
    height: auto;
    overflow: hidden;
    bottom: 32px;
  }
  .brokers-bottom-bar > div:nth-child(2) {
    height: 404px;
    overflow: auto;
  }
  .brokers-top-section .broker-grid {
    grid-template-columns: 100%;
  }
  .brokers-bottom-section .broker-grid {
    grid-template-columns: 100%;
  }
  .grid-column-three {
    grid-column-end: 1;
  }
  .brokers-top-section-titles p,
  .qts-rep-title-mobile {
    margin-bottom: 8px;
    font-size: 10px;
  }
  .brokers-bottom-bar-mobile {
    display: block;
    bottom: 0;
    height: 436px;
  }
  .brokers-bar-left p {
    font-size: 12px;
  }
  .brokers-bar-left .mui-btn.mui-btn--flat.button.btn-action {
    display: none;
  }
  .broker-card,
  .qts-rep-card {
    flex-direction: row;
  }
  .broker-image,
  .qts-rep-image {
    margin-right: 32px;
    height: 32px;
    width: 32px;
    min-width: 32px;
  }
  .broker-image img,
  .qts-rep-image img {
    height: 32px;
  }
  .qts-rep-info .xs-text {
    font-size: 10px;
  }
  .brokers-empty-state p {
    font-size: 12px;
  }
  .broker-info {
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: auto;
  }
  .brokers-bottom-bar-mobile .brokers-bar {
    margin-bottom: 16px;
  }
  .brokers-bottom-bar-mobile.collapsed {
    border-top: 1px solid #fff;
  }
}

@media only screen and (max-width: 440px) {
  .broker-info .mui-btn.mui-btn--flat.button.btn-accent {
    min-width: initial;
    padding: 0 8px;
  }
  .broker-image,
  .qts-rep-image,
  .qts-rep-image {
    margin-right: 16px;
  }
}

@media only screen and (max-width: 392px) {
  .broker-info {
    display: block;
  }
}

/***** RATES CARD STYLES *****/
.rates-card {
  position: absolute;
  z-index: 2;
  top: 120px;
  left: 32px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
  background: #fff;
  width: 328px;
  padding: 16px;
}
.rates-card::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  left: 0;
  top: 344px;
  box-sizing: border-box;
  border: 6px solid black;
  border-color: transparent transparent #fff #fff;
  transform-origin: 0 0;
  transform: rotate(45deg);
  box-shadow: -1px 2px 0px 0 rgba(0, 0, 0, 0.2);
}

/*** Rates Card Header ***/
.rates-card-header {
  margin-bottom: 16px;
}
.rates-card-header i {
  font-size: 18px;
}
.rates-card-header i:hover {
  opacity: 0.5;
  transition: opacity 300ms linear;
  cursor: pointer;
}

/*** Rates Grid ***/
.rate-boxes {
  grid-template: 1fr 1fr 1fr 1fr / calc(50% - 4px) calc(50% - 4px);
  grid-gap: 8px;
}
.rate-box {
  background: #eee;
  display: flex;
  justify-content: space-between;
  padding: 12px;
  font-size: 12px;
  text-align: left;
}
.rates {
  margin-left: 12px;
  text-align: right;
}
.rates span {
  display: block;
}
.rates-card-buttons {
  margin-top: 24px;
}
.rates-card-buttons .mui-btn.mui-btn--flat.button {
  height: 32px;
  width: 144px;
  line-height: 32px;
  font-size: 12px;
}
.rates-card-buttons .mui-btn.mui-btn--flat.button.btn-accent2 {
  border: 1px solid #999;
}
.rates-card-buttons .mui-btn.mui-btn--flat.button.btn-accent {
  border: 1px solid #ce7e07;
}

@media only screen and (max-width: 1000px) {
  .rates-card {
    width: 280px;
  }
  .rates-card-header p {
    font-size: 12px;
  }
  .rates-card-header i {
    font-size: 16px;
  }
  .rate-box {
    padding: 8px;
    font-size: 10px;
  }
}

@media only screen and (max-width: 400px) {
  .rates-card {
    width: 232px;
    left: 16px;
  }
  .rates-card-header {
    margin-bottom: 8px;
  }
  .rate-box {
    font-size: 9px;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */
  .rate-box {
    width: calc(50% - 8px);
    margin: 4px;
  }
}

/***** STREET VIEW STYLES *****/
/*** Open Street View Styles ***/
.openStreetView {
  position: fixed;
  z-index: 10;
  top: 120px;
  right: 312px;
  background-color: #ff5252;
  cursor: pointer;
  height: 54px;
  width: 54px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    position: fixed;
    top: 136px;
    right: 376px;
    background-color: #000;
    opacity: 0.6;
    color: #fff;
    padding: 5px 15px;
    border-radius: 3px;
  }
}

.openStreetViewNoList {
  @extend .openStreetView;
  right: 4px;
  p {
    right: 64px;
  }
}

@media only screen and (max-width: 700px) {
  .openStreetView p {
    display: none;
  }
  .openStreetViewNoList {
    height: 40px;
    width: 40px;
    top: 72px;
    right: 40px;
  }
}

/*** Street View Styles ***/
.comps-street-sub-header {
  @extend .sub-header;
  width: 100%;
  z-index: 2;
}
.comps-back-arrow img {
  height: 24px;
  margin-left: 20px;
  float: left;
  margin-top: 20px;
  cursor: pointer;
}
.address-title {
  color: #fff;
  margin-top: 22px;
  display: inline-block;
  font-size: 16px;
  margin-left: 13px;
}
.streetview-map {
  width: 100% !important;
  height: 93.5% !important;
  z-index: -1;
  margin-top: 60px;
  position: static !important;
}

/***** MEDIA QUERIES *****/
@media (max-width: 550px) {
  .comps-error-modal {
    width: 300px;
    height: 325px;
  }
}
