.rate-sheet {
  margin-top: 1%;
}

.rate-sheets {
  margin-left: 3%;
}

.rates-panel {
  padding: 10px 30px !important;
  margin-right: 5%;
  border-radius: 3px;
  font-size: 13px;
  margin-left: 6%;
  .mui-divider {
    margin-left: 0;
    margin-right: 0;
  }
}

.rate-sheets .mui-row {
  margin-left: 0;
  margin-right: 0;
}

.rate-title {
  color: rgba(0, 0, 0, 0.6);
  font-weight: 500;
  padding-left: 0;
  font-size: 19px;
}

.main-title {
  @extend .rate-title;
}

#circle {
  width: 10%;
  margin-top: -5px;
}

.last-updated {
  color: #ce7e07;
  margin-top: 22px;
  font-size: 13px;
  text-align: right;
}

.rates-small-title {
  color: #999;
}

.bank-rates-title {
  border-left: 15px solid #ce7e07;
  padding-left: 15px;
  color: #ce7e07;
  font-weight: 500;
  line-height: 15px;
  margin-bottom: 15px;
  margin-left: -30px;
  margin-top: 2%;
}
.padding-left {
  padding-left: 20px;
}
.padding-right {
  padding-right: 20px;
}

@media only screen and (max-width: 1325px) {
  .rate-sheet {
    .panel-info-value {
      word-wrap: break-word;
    }
    .small-title {
      padding-left: 20px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .rate-title {
    margin-left: 2%;
  }
  .main-title {
    margin-left: 2%;
  }
}
