.mq-sub-header {
  @extend .sub-header;
  height: 59px;
  ul {
    padding-top: 7px;
    li a {
      padding-bottom: 15px;
    }
  }
}

.mq-panel {
  .small-title {
    font-size: 13px;
  }
}

.missing-quotes .panel-info-title {
  margin-bottom: 0;
}

.quote-section {
  padding-left: 3%;
  padding-bottom: 10px;
}

.missing-quotes {
  .mui-divider {
    margin-top: 0;
  }
}

.tabs {
  margin-top: 20px;
  margin-bottom: 0;
  padding-left: 38px;
}

.tab {
  padding: 10px;
  display: inline-block;
  cursor: pointer;
  font-size: 13px;
}

.tab-active {
  border-bottom: 2px solid #ce7e07;
  color: #ce7e07;
}

.tab-content {
  max-height: 200px;
  padding: 10px 40px;
  overflow-y: hidden;
  text-align: left;
  &:hover {
    overflow-y: auto;
  }
}

.tab-content a {
  padding-left: 15px;
  color: #000000;
  &:hover {
    color: #ce7e07;
  }
  i {
    vertical-align: middle;
  }
}

.documents-header {
  padding: 10px 0;
  border-bottom: 1px solid #dcdcdc;
  margin-bottom: 10px;
  p {
    font-weight: 500;
  }
}
.documents {
  @extend .documents-header;
  p {
    font-weight: normal;
  }
  .document-name {
    i {
      vertical-align: middle;
      margin-right: 10px;
    }
    p {
      display: inline-block;
    }
  }
  .document-share {
    p {
      color: rgba(0, 0, 0, 0.54);
    }
  }
  .document-download {
    i {
      color: rgba(0, 0, 0, 0.54);
    }
  }
}

.missing-quotes-ul {
  margin-bottom: 0;
  padding-left: 0;
  text-align: center;
  padding-top: 6px;
}

.mq-list-item {
  display: inline-block;
  a {
    width: 105px;
    height: 11px;
    font-size: 13px;
    line-height: 53px;
    color: rgba(255, 255, 255, 0.4);
    text-transform: uppercase;
    padding-bottom: 16px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.mq-note {
  padding-left: 40px;
  padding-right: 40px;
  margin-top: 5px;
}

.mq-note-author {
  margin-top: 5px;
}

.missing-quotes {
  .quotes-btn {
    margin-left: 40px;
    text-align: center;
    background-color: #ce7e07;
    width: 100px;
    height: 36px;
    padding: 10px 0;
    margin-top: 20px;
    margin-bottom: 15px;
    color: #fff;
    font-size: 13px;
    border-radius: 3px;
    display: inline-block;
    box-shadow: -1px 0 4px rgba(0, 0, 0, 0.18);
    span {
      text-transform: uppercase;
      letter-spacing: 0.5px;
    }
  }
}

.pass-btn {
  @extend .quotes-btn;
  background-color: #8b8b8b !important;
  margin-left: 15px !important;
}

.quote {
  padding-top: 100px;
}

.quote-panel {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  padding: 16px !important;
  display: flex;
  .mui-row {
    margin-bottom: 10px;
  }
  .mui-textfield {
    input {
      font-size: 12px;
    }
  }
  .mui-select {
    select {
      font-size: 12px;
    }
  }
  label {
    font-size: 11px;
  }
  .mui-select__menu {
    top: 42px !important;
    height: auto !important;
    max-height: 185px;
    div {
      font-size: 14px;
      height: 30px;
      line-height: 30px;
    }
  }
}

.quote-bank-name {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.5px;
  margin: 0 0 16px 0;
}

.quote-deal-name {
  margin-bottom: 30px;
}

.quote-col-left {
  margin-right: 3%;
}

.quote-col-right {
  margin-left: 15px;
}

.no-border {
  input {
    border-bottom: none !important;
    color: #cccccc;
    font-weight: 300;
  }
}

.dollar-input {
  background-image: url(/images/icons/dollar.png);
  background-repeat: no-repeat;
  background-position: left 24px;
  background-size: 9px;
  input {
    padding-left: 15px;
  }
  label {
    padding-left: 15px;
  }
}

.spread-percentage {
  background-image: url(/images/icons/percent.png);
  background-repeat: no-repeat;
  background-position: right 25px;
  background-size: 18px;
}

.index-select {
  select {
    font-size: 13px !important;
  }
  .mui-select__menu {
    width: 220px !important;
  }
  .mui-select__menu > div {
    font-size: 12px !important;
  }
}

.quote-orange-header {
  font-size: 13px;
  color: #ce7e07;
  text-transform: uppercase;
  margin-top: 25px;
}

.quote-col-one {
  margin-right: 20px;
}

.delete-note {
  background-image: url(/images/icons/close-bg.png);
  background-repeat: no-repeat;
  background-position: right 6px;
  background-size: 16px;
  width: 35px;
  height: 20px;
  margin-top: -45px;
  margin-bottom: 25px;
  position: relative;
  z-index: 1;
  float: right;
  cursor: pointer;
}

.save-quote-btn {
  font-size: 12px;
  font-weight: normal;
  width: auto;
  height: 32px;
  padding: 0 16px;
}

.add-quote-btn {
  height: 32px;
  padding: 0 8px;
  color: #ce7e07 !important;
  background: transparent !important;
  font-size: 12px;
  font-weight: normal;
}

.add-quote-btn:hover {
  color: #fff !important;
  background: #ce7e07 !important;
}

.quote-notes-container {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  margin: -16px;
  padding: 16px;
  min-width: 304px;
  .note {
    padding-left: 0;
    font-size: 13px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 12px;
    margin-bottom: 12px;
  }
}
.quote-notes-header {
  font-size: 16px;
  margin-bottom: 16px;
  font-weight: 500;
}
.quote-add-note {
  font-size: 12px;
}
.quote-add-note p:hover {
  color: #8b8b8b;
  transition: all 300ms linear;
  cursor: pointer;
}
.quote-add-note i {
  font-size: 17px;
  vertical-align: middle;
  margin-top: -2px;
}
.quote-note-row {
  width: calc(100% - 32px);
}

@media only screen and (max-width: 800px) {
  .quote-panel {
    width: 90%;
  }

  .quote-notes-container {
    border-left: unset;
    margin: 0;
    padding-left: 0;
    padding-top: 24px;
  }
}

@media only screen and (max-width: 767px) {
  .quote-col-left {
    margin-right: 0;
    padding-right: 20px;
  }
  .quote-col-small {
    padding-right: 20px;
  }
  .quote-col-right {
    margin-left: 0;
  }
  .quote-col-one {
    margin-right: 0;
    padding-right: 20px;
  }
}

@media only screen and (max-width: 543px) {
  .quote-col-left {
    padding-right: 0;
  }
  .quote-col-small {
    padding-right: 0;
  }
}

@media only screen and (max-width: 400px) {
  .quote-panel {
    .mui-textfield {
      input {
        font-size: 14px;
      }
    }
    .mui-select {
      select {
        font-size: 14px;
      }
    }
  }
}
