.pipeline .mui-panel {
  padding-bottom: 10px;
}

.pipeline .mui-row {
  margin-left: 0;
  margin-right: 0;
}

.pipeline {
  .quote-section {
    padding-left: 40px;
    margin-bottom: 0;
    margin-top: 30px;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 18px;
    font-weight: bold;
  }
}

.property-title {
  padding-top: 30px;
  font-size: 1.4em;
}

.address {
  font-size: 1.1em;
}

.pipeline .deal-info-title {
  margin-bottom: 5px;
}

@media only screen and (max-width: 900px) {
  .pipeline .quote-section {
    margin-left: 1%;
  }
}

@media only screen and (max-width: 770px) {
  .pipeline .quote-section {
    padding-top: 8px;
    padding-left: 36px;
  }
}
