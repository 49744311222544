.account-activation {
  position: relative;
  overflow-x: hidden;
}

.bg-img {
  width: 100%;
  height: calc(100% - 100px);
  background: url("/images/gs-background.png") center center no-repeat;
  background-size: cover;
  position: relative;
}

.aa-logo {
  position: absolute;
  top: 20px;
  left: 50px;
}

.gs-app-icons {
  position: absolute;
  top: 30px;
  right: 50px;
  img {
    margin-left: 10px;
    opacity: 0.3;
    &:hover {
      transition: all 300ms linear;
      opacity: 1;
    }
  }
}

.gs-banner {
  width: 100%;
  bottom: 0;
  padding-left: 30px;
  .gs-banner-text {
    margin-top: 72px;
    p {
      font-weight: 100;
      font-size: 60px;
      color: #fff;
      float: right;
      position: relative;
      span {
        font-weight: 500;
        margin-right: 16px;
      }
      .mui-btn--flat {
        width: 180px;
        height: 45px;
        position: absolute;
        left: 0;
        bottom: -96px;
        padding: 0 20px;
        font-size: 14px;
      }
    }
  }
  .gs-banner-img {
    float: right;
    width: 50%;
    padding-left: 40px;
    img {
      float: left;
    }
  }
}

.step1 {
  overflow: hidden;
  .create-psswd-box {
    background-color: #fff;
    box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.14);
    margin: 0 auto 96px auto;
    position: relative;
    border-radius: 3px;
  }
  .create-psswd-left {
    text-align: center;
    img {
      margin-bottom: 40px;
    }
  }
  .create-psswd-right {
    width: 360px;
    float: right;
    padding: 40px;
    position: relative;
    height: 360px;
    .mui-textfield {
      margin-top: 32px;
    }
  }
  .create-psswd {
    font-weight: 300;
    font-size: 20px;
    color: #555;
    margin-bottom: 24px;
  }
  .username {
    font-size: 13px;
    color: #999;
    margin-bottom: 16px;
    span {
      color: #ce7e07;
      display: block;
      margin-top: 8px;
    }
  }
  .activate-account-btn {
    width: 280px;
    height: 45px;
    padding: 0;
    font-size: 13px;
    position: absolute;
    bottom: 40px;
    margin: 0;
  }
}

.aa-success-circle-container {
  text-align: center;
  margin-top: 24px;
}

.aa-success-circle {
  color: #4caf50;
  font-size: 75px;
}

.aa-error-circle {
  color: #d50000;
  font-size: 85px;
}

.aa-congratulations {
  font-size: 15px;
  text-align: center;
}

.aa-reset-successful {
  font-size: 15px;
  text-align: center;
}

.aa-portal-link {
  text-align: center;
  a {
    color: #ce7e07;
    text-decoration: underline;
  }
}

.white-background {
  background-color: #fff;
  padding-bottom: 16px;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}

.gs-container {
  max-width: 950px;
  width: 40%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 32px;
  .mui-divider {
    margin: 65px 0 !important;
  }
}

.getting-started {
  margin-top: 64px;
  margin-bottom: 75px;
  text-align: center;
  .gs-title {
    font-size: 40px;
    margin-bottom: 24px;
  }
  .gs-subtitle {
    font-size: 15px;
    font-weight: 300;
    color: #888;
  }
}

.gs-step {
  height: 30px;
  width: 30px;
  border-radius: 100%;
  background-color: #4caf50;
  color: #fff;
  padding: 5px 11px;
  float: left;
}

.gs-step-title {
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 8px;
}

.gs-step-subtitle {
  font-size: 15px;
  color: #888;
  font-weight: 300;
}

.gs-step-app-icons {
  margin-top: 32px;
  img {
    margin-right: 10px;
    height: 40px !important;
  }
}

.gs-subfooter {
  font-size: 15px;
  color: #888;
  font-weight: 300;
  a {
    color: #ce7e07;
  }
  span {
    color: #ce7e07;
  }
}

.view-on-portal {
  margin-bottom: 20px;
}

.step2 {
  overflow: auto;
  .step2-left {
    width: 50%;
    float: left;
  }
  .step2-right {
    width: 50%;
    float: right;
    padding-left: 112px;
    img {
      height: 40px !important;
      margin-top: 32px;
    }
  }
}

.divider-or {
  background-color: rgba(0, 0, 0, 0.1);
  display: inline-block;
  .or-circle {
    height: 32px;
    width: 32px;
    position: absolute;
    border-radius: 50%;
    background-color: #f1f1f1;
    padding-top: 6.5px;
    font-size: 11px;
    font-weight: 500;
  }
}

.gs-footer {
  text-align: center;
  margin-top: 96px;
  margin-bottom: 32px;
  color: #cfcfcf;
  font-size: 13px;
  font-weight: 100;
  i {
    vertical-align: middle;
    font-size: 15px;
    margin-top: -4px;
    padding-right: 3px;
  }
}

@media only screen and (max-width: 1680px) {
  .gs-container {
    width: 55%;
  }
}

@media only screen and (max-width: 1200px) {
  .gs-container {
    width: 70%;
  }
  .gs-banner-text {
    width: 58% !important;
  }
  .gs-banner-img {
    width: 42% !important;
    img {
      width: 450px;
    }
  }
}

@media only screen and (min-width: 992px) {
  .gs-banner {
    position: absolute;
  }
  .gs-banner-text {
    float: left;
    width: 50%;
    padding-right: 40px;
  }
  .create-psswd-box {
    height: 360px;
    width: 700px;
  }
  .create-psswd-left {
    position: absolute;
    width: 350px;
    height: 360px;
    box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.1);
    img {
      margin-top: 80px;
    }
  }
  .divider-or {
    width: 1px;
    height: 120px;
    position: absolute;
    margin-top: 8px;
    .or-circle {
      top: 40px;
      left: -14px;
      padding-left: 8.5px;
    }
  }
  .message-container {
    width: 360px;
    float: right;
    padding: 40px;
    position: relative;
    height: 360px;
  }
}

@media only screen and (max-width: 992px) {
  .bg-img {
    text-align: center;
    height: auto;
  }
  .gs-banner {
    position: relative;
  }
  .aa-logo {
    position: static;
    padding-top: 24px;
  }
  .gs-app-icons {
    display: none;
  }
  .gs-banner-text {
    float: none;
    width: 100% !important;
    padding-right: 0;
    p {
      float: none !important;
      .mui-btn--flat {
        margin: 80px auto;
        display: block;
        position: static !important;
      }
    }
  }
  .gs-banner-img {
    float: none !important;
    width: 100% !important;
    img {
      float: none !important;
      width: 400px;
      margin-left: 80px;
    }
  }
  .create-psswd-box {
    width: 360px;
    height: 624px;
  }
  .create-psswd-left {
    position: static;
    box-shadow: none;
    height: auto;
    width: auto;
    img {
      margin-top: 40px;
    }
  }
  .create-psswd {
    text-align: center;
  }
  .step2 {
    text-align: center;
    .step2-left {
      float: none !important;
      width: 100% !important;
    }
    .gs-step-app-icons img {
      display: block;
      margin: 16px auto;
    }
    .divider-or {
      width: 120px;
      height: 1px;
      position: relative;
      margin-top: 40px;
      margin-bottom: 40px;
      .or-circle {
        top: -14px;
        left: 40px;
        padding-left: 0;
      }
    }
    .step2-right {
      float: none !important;
      width: 100% !important;
      padding-left: 0 !important;
    }
  }
  .message-container {
    margin-top: 88px;
  }
}

@media only screen and (max-width: 544px) {
  .gs-banner {
    padding-right: 30px;
  }
  .gs-banner .gs-banner-text p {
    font-size: 40px;
  }
  .gs-banner .gs-banner-img img {
    width: 304px;
    margin-left: 40px;
  }
  .aa-container {
    padding: 0 24px;
  }
  .gs-container {
    padding: 0 24px;
  }
}

@media only screen and (max-width: 400px) {
  .gs-banner .gs-banner-img {
    padding-left: 16px !important;
    img {
      margin-left: 8px !important;
    }
  }
  .create-psswd-box {
    width: 280px !important;
  }
  .create-psswd-right {
    width: 280px !important;
    float: none !important;
  }
  .activate-account-btn {
    width: 200px !important;
  }
}

//height media queries

@media only screen and (max-height: 850px) {
  .gs-banner-img img {
    width: 450px;
  }
}

@media only screen and (max-height: 725px) {
  .gs-banner-img img {
    width: 350px;
  }
}
