.lc-header {
  background-image: url("/images/lc-header.png");
  margin-top: -3px;
  margin-bottom: 40px;
  position: relative;
  height: 147px;
  .mui-container {
    position: relative;
  }
  h2 {
    color: #fff;
    font-size: 30px;
    line-height: 147px;
    margin: 0;
    min-height: 50px;
  }
  .add-circle {
    position: absolute;
    border-radius: 50%;
    background-color: #ce7e07;
    box-shadow: 0px 3px 4.7px 0.3px rgba(0, 0, 0, 0.18);
    width: 56px;
    height: 56px;
    z-index: 8;
    bottom: -24px;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      color: #fff;
    }
  }
  .close-circle {
    @extend .add-circle;
    background-color: #808080;
  }
}

@media only screen and (max-width: 480px) {
  .lc-header {
    height: auto;
    padding: 24px 0;
    margin-bottom: 24px;
    h2 {
      font-size: 22px;
      line-height: inherit;
    }
    .add-circle {
      height: 40px;
      width: 40px;
      bottom: -40px;
    }
  }
}

.lc-panel {
  border-radius: 3px;
}

.lc-panel .rkmd-checkbox {
  margin-bottom: 16px;
}

.lc-panel .ic-dropdown-content .rkmd-checkbox {
  margin-bottom: 0;
}

.lc-panel .rkmd-checkbox .input-checkbox {
  width: 24px;
}

.lc-panel
  .rkmd-checkbox
  .input-checkbox
  input[type="checkbox"]
  + .checkbox:before {
  left: -2px;
}

.lc-panel-header-row {
  min-height: 56px;
  cursor: pointer;
}

.lc-banker {
  font-size: 13px;
  display: block;
  font-weight: 300;
  color: #999;
}

.lc-header-row-left p {
  line-height: 20px;
  font-size: 15px;
  font-weight: 500;
  color: #666;
  padding-left: 24px;
  padding-bottom: 12px;
}

.lc-header-row-left p:first-child {
  line-height: 56px;
  padding-bottom: 0;
  margin-bottom: -12px;
}

.toggle-arrow {
  cursor: pointer;
}

.lc-header-row-right i {
  float: right;
  color: #999;
  padding-top: 18px;
  padding-right: 24px;
}

.lc-header-row-right span {
  margin-top: 20px;
  margin-right: 8px;
  float: right;
}

.lc-header-row-edit i {
  float: right;
  padding-top: 18px;
  padding-right: 24px;
}

.lc-panel-row {
  padding: 16px 24px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.lc-internal-row {
  margin-top: 8px;
}

.lc-section-title {
  margin-bottom: 8px;
}

.lc-panel-row {
  padding: 16px 24px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.lc-internal-row {
  margin-top: 8px;
}
.lc-section-title {
  margin-bottom: 8px;
}
.lc-icon {
  font-size: 17px;
  width: 32px;
  color: #666;
  margin-top: 2px;
  float: left;
}
.lc-img {
  width: 32px;
  display: inline-block;
  img {
    height: 17px;
  }
}
.lc-row-title {
  font-size: 13px;
  font-weight: bold;
  display: inline-block;
  padding-right: 3px;
}
.lc-row-content {
  font-size: 13px;
  color: #666;
}
.lc-panel .mui-textfield > textarea {
  resize: none;
  height: 50px;
}
.lc-panel .delete-note {
  margin-right: 26px;
}

/*** Lending Criteria Add Styles ***/
.lc-add-panel {
  @extend .quote-panel;
  display: block;
  width: 100%;
  .mui-select > select {
    background-position: right 5px center;
  }
}

.lc-edit-panel {
  @extend .lc-add-panel;
}

.lc-add-panel-header {
  font-size: 20px;
  letter-spacing: 0.5px;
  margin-bottom: 30px;
  margin-top: 10px;
}

.lc-edit-panel-header {
  @extend .lc-add-panel-header;
  display: inline-block;
}

.lc-edit-panel-subheader {
  display: inline-block;
  color: #666666;
  font-size: 15px;
  span {
    margin: 0 8px;
  }
}

.lc-fake-select {
  position: relative;

  cursor: pointer;
  .mui-textfield > input:disabled {
    color: #000 !important;

    cursor: pointer;
  }
  i {
    position: absolute;
    right: 0;
    top: 20px;
    color: #c2c2c2;
  }
}

.lc-dropdown-box {
  @extend .qts-dropdown-box;
  box-shadow: 0px 18px 54.32px 1.68px rgba(0, 0, 0, 0.11),
    0px 9px 15.52px 0.48px rgba(0, 0, 0, 0.19);
  z-index: 2;
  .rkmd-checkbox {
    margin-bottom: 0;
  }
  .rkmd-checkbox label,
  .rkmd-checkbox .label {
    display: inline-block;
  }
}

.states-dropdown {
  width: 288px;
}

.nationwide {
  margin-top: 16px;
  .input-checkbox {
    input[type="checkbox"] {
      & + .checkbox:before {
        z-index: unset !important;
      }
    }
  }
  .label {
    display: inline-block;
  }
}

.left-column {
  padding-right: 15px;
}

.right-column {
  padding-left: 15px;
}

.small-right-column {
  padding-left: 15px;
}

@media only screen and (max-width: 1449px) {
  .left-column {
    padding-right: unset;
  }
  .right-column {
    padding-left: unset;
  }
}

.lc-label {
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
  line-height: 15px;
  margin: 0;
}

.lc-datepicker {
  margin-top: 1px;
  position: relative;
  .react-date-field--theme-default {
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.26);
  }
  .react-date-picker__calendar--theme-default
    .react-date-picker__month-view--theme-default {
    position: absolute !important;
    top: -30px !important;
    right: 0 !important;
    margin-bottom: 20px;
  }
}

.save-lc-btn {
  width: 95%;
  padding: 0 5px;
  margin: 2px 5px;
  font-size: 13px;
  height: auto;
}

.cancel-lc-btn {
  @extend .save-lc-btn;
  background-color: #8b8b8b !important;
  &:hover {
    background-color: #f2f2f2 !important;
    color: #8b8b8b !important;
  }
}

.icon-dollar {
  background-image: url(/images/icons/dollar.png);
  background-repeat: no-repeat;
  background-position: left 14px;
  background-size: 9px;
  input {
    padding-left: 15px;
  }
  label {
    padding-left: 15px;
  }
}

.icon-percent {
  background-image: url(/images/icons/percent.png);
  background-repeat: no-repeat;
  background-position: right 14px;
  background-size: 15px;
}

.lc-fake-select .mui-textfield.lc-disabled > input:disabled {
  color: rgba(0, 0, 0, 0.45) !important;
}

.lc-disabled-option {
  cursor: not-allowed !important;
  background-color: transparent !important;
  &:hover {
    background-color: transparent !important;
  }
}

.lp-select > label:after {
  content: "*";
  padding-left: 8px;
  color: #e50000;
}

.lc-excluded-areas {
  border-left: 2px solid #d3d3d3;
  padding-left: 16px;
  .search-input-container {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.26);
    background: transparent;
    border-radius: 0;
    .search-icon {
      padding: 10px 4px;
      font-size: 20px;
    }
  }
  .chip {
    font-size: 11px;
    padding: 0 12px;
  }
}

.lc-excluded {
  margin-bottom: 8px;
}

.lc-panel .ic-dropdown-select {
  font-size: 13px;
  margin-top: 4px;
}

@media (min-width: 1450px) and (max-width: 1550px) {
  .lc-header .add-circle {
    right: -8px;
  }
}

@media only screen and (max-width: 1449px) {
  .lc-header .add-circle {
    right: 8px;
  }
}

@media (min-width: 1199px) and (max-width: 1425px) {
  .lc-container {
    padding-left: 30px;
  }
  .lc-excluded-first-column {
    border-left: 3px solid #d3d3d3;
    height: 56px;
    padding-top: 8px;
    margin-top: -8px;
    padding-left: 16px;
  }

  @media only screen and (max-width: 768px) {
    .left-column {
      padding-right: 0;
    }
    .right-column {
      padding-left: 0;
    }
  }
}

.mui-select{
  padding-top: 5px;
}

.paper {
  padding: 12px;
  margin: 12px;
}

.duplicate {
  vertical-align: middle;
  font-size: 20px;
}

.lc-input {
  border: 1px solid rgba(0, 0, 0, 0.26);
  border-radius: 3px;
  height: 38px;
  padding-top: 5px;
  width: 91%;
}

.lc-textarea {
  @extend .lc-input;
  height: 56px;
  width: 98%;
}


