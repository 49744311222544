$border-color: rgba(#000, 0.1);
.screen {
  width: 100%;
  height: 125px;
  position: relative;
  background-color: #00112b;
  border-radius: 2px;
  textarea {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    padding: 1rem;
    padding-top: 0;
    text-align: right;
    font-size: 3.5em;
    background-color: #00112b;
    color: #ffffff;
    word-wrap: break-word;
    resize: none;
    line-height: 62px;
    overflow: hidden;
  }
}

/* We will define our buttons here */
// We'll just set each button to be a square.
.calc-container button {
  width: 80px;
  height: 80px;
  border: 1px solid $border-color;
  font-size: 2em;
  font-weight: 100;
  background: #ffffff;
  border-radius: 0;
  &:active {
    background: #f5f5f5;
  }
  &:focus {
    outline: none;
  }
  &.func {
    background: #ce7e07;
    color: #ffffff;
    &:active {
      background: #e59400;
    }
  }
  &.special {
    background: #ffffff;
    color: #000000;
    &:active {
      background: #f5f5f5;
    }
  }
}
//Each section will be laid out in a specific way
.buttons-wrapper {
  float: left;
  &.numbers {
    width: 240px;
  }
  &.functions {
    float: right;
    width: 80px;
  }
  &.specials {
  }
}
#root {
  text-align: center;
}
.calc-container {
  width: 320px;
  height: 525px;
  margin: 50px auto;
  background: black;
  display: block;
}
