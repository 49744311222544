.docs-outer-container {
  padding: 80px 40px;
}
.docs-list {
  @extend .reports-list;
}
.docs-container {
  @extend .reports-container;
  max-width: 800px;
}

.docs-list .docs-row:nth-child(odd) {
  background: #f7f7f7;
}

.docs-list .docs-row:nth-child(even) {
  background: #fff;
}

.docs-row {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  padding-top: 10px;
}

.docs-row-icon {
  padding-left: 16px;
  padding-right: 16px;
}

.docs-row-title {
  flex: 1;
}
.docs-row-download {
  color: #8b8b8b;
  cursor: pointer;
  padding-right: 16px;
  padding-left: 16px;
}
.doc-download-icon {
  font-size: 28px;
}

@media only screen and (max-width: 540px) {
  .docs-outer-container {
    padding: 80px 24px;
  }
}
