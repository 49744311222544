/***** Loans Subheader Styles *****/
.loan-links {
  position: absolute;
  left: 0;
}
.loan-search-filter {
  padding-left: 350px;
  padding-right: 160px;
  margin: 0 auto;
  max-width: 100%;
}
.loans-search-container {
  margin-top: 13px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 2px;
  padding: 2px;
  position: relative;
  float: left;
  width: 80%;
  margin-right: 8px;
  .search-icon {
    padding: 4px 8px 0 8px;
    float: left;
  }
}
.loans-search-container input {
  width: calc(100% - 72px);
}
.loans-search-container input::-webkit-input-placeholder {
  color: white;
  font-weight: 300;
  font-size: 13px;
}
.loan-filter {
  margin-top: 13px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 2px;
  float: left;
  width: 28%;
  color: #fff;
  height: 36px;
  line-height: 36px;
  padding: 0 8px;
  p {
    float: left;
  }
  i {
    float: right;
    padding-top: 6px;
  }
}

/*** My Loans Select Styles ***/
.mui-row.my-loans-selects {
  width: 80%;
  margin: 15px auto;
  .custom-select {
    width: 240px;
    background: #fff;
    border: 1px solid #e8e8e8;
    border-radius: 3px;
    margin-right: 8px;
    padding-right: 8px;
    display: inline-block;
    .mui-select {
      padding-top: 0;
      margin-bottom: 0;
      select {
        border-bottom: none;
      }
    }
  }
}

/*** Other Loans Checkbox Styles ***/
.other-loans-check {
  width: 80%;
  margin: 0 auto;
  p {
    display: inline-block;
    font-weight: 400;
  }
}

/*** Loan Panel Styles ***/
.add-banker-section {
  display: inline-flex;
  color: #ce7e07;
  margin-top: 5px;
}

.add-banker-circle {
  margin-left: 10px;
  font-size: 19px;
}

.banker-name {
  display: inline-block;
}

.add-banker-circle-remove {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background: red;
  height: 16px;
  width: 16px;
  margin-left: 10px;
  vertical-align: middle;
  font-size: 12px;
  color: #fff;
}

.remove-me {
  color: red;
  text-transform: uppercase;
  font-size: 12px;
  margin-left: 26px;
  margin-top: 16px;
  font-weight: 500;
  margin-bottom: 8px;
  i {
    margin-top: -2px;
    margin-right: 8px;
  }
}

.loan {
  @extend .pipeline;
  .panel {
    position: relative;
  }
  .street-view-square {
    float: right;
    padding: 12px 16px 0 0;
  }
}

.street-view-square-loans {
  float: right;
  padding: 12px 16px 0 0;
}

.load-more {
  padding: 0 16px;
  margin-bottom: 16px;
  font-size: 12px;
  height: 40px;
}

.loan-inline-btns {
  display: inline-block;
  margin-left: 8px;
}

.loan-quote-button {
  text-align: center;
  border-radius: 3px;
  box-shadow: none;
  padding: 0 8px;
  cursor: pointer;
  height: 24px;
  font-size: 12px;
  background-color: #ce7e07;
  margin: 0;
  width: auto;
  line-height: 24px;
  font-weight: 400;
  text-transform: none;
}

.loan-pass-button {
  text-align: center;
  border-radius: 3px;
  box-shadow: none;
  padding: 0 8px;
  cursor: pointer;
  height: 24px;
  font-size: 12px;
  background-color: #ce7e07;
  margin: 0;
  width: auto;
  line-height: 24px;
  font-weight: 400;
  text-transform: none;
  background-color: #808080;
}

.close-search {
  cursor: pointer;
  font-size: 20px;
  vertical-align: text-bottom;
}

.loan-pass-button:hover {
  background-color: #808080;
}
.loan-pass-button:focus {
  background-color: #808080;
}

.panel-info-value-loans {
  @extend .panel-info-value;
  margin-top: 20px;
}

.panel-heading-loans {
  @extend .panel-heading;
  height: 75px !important;
}

.loan-brokers {
  border: 1px solid #ce7e07;
  background: #fdfaf6;
  border-radius: 3px;
  display: inline-block;
  margin-left: 40px;
  margin-right: 15px;
  padding: 4px;
  p {
    display: inline-block;
    margin-right: 16px;
    color: #ce7e07;
  }
  p:last-of-type {
    margin-right: 0;
  }
}

.remove-from-loan {
  float: right;
  background: #ff6263;
  color: #fff;
  padding: 4px;
  border-radius: 3px;
  margin: 20px 16px 16px 16px;
  font-size: 13px;
}

.loan-search-button {
  display: none;
}

.loan-search-popup {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.loan-search-header {
  display: flex;
  justify-content: space-between;
}

.loan-search-inputs {
  display: flex;
  justify-content: space-between;
  input {
    flex: 1;
    border: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.26);
  }
}

.loan-search-footer {
  button {
    width: 100%;
    padding: 0;
    height: 40px;
  }
}

@media only screen and (max-width: 959px) {
  .loan-search-button {
    display: block;
    position: absolute;
    top: 24px;
    right: 8px;
    i {
      color: #fff;
      cursor: pointer;
    }
  }
  .loan-search-filter {
    display: none;
  }
}
