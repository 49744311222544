.quote-title {
  display: inline-block;
  padding: 20px 40px;
  font-weight: 500;
  font-size: 16px;
}

#badge-icon {
  width: 36px;
}

.last-update {
  color: #999999;
  padding-left: 40px;
  font-size: 13px;
  margin-top: 10px;
}

.bank-quote {
  .deal-options {
    padding: 24px 40px 0 40px;
  }
  .note {
    padding-left: 40px;
  }
}

.quote .success-message {
  width: 670px;
}

@media only screen and (max-width: 680px) {
  .quote .success-message {
    width: 320px;
    height: auto;
  }
}
