/*** Text Alignment Styles ***/
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}

/*** Text Color Styles ***/
.accent-text-light {
  color: rgba(0, 0, 0, 0.54);
}
.accent-text {
  color: #999;
}
.accent-text-dark {
  color: #666;
}
.white {
  color: white;
}
.orange-text {
  color: #ce7e07!important;
}
.red-text {
  color: #ff5252!important;
}
.primary-text {
  color: $primary-color;
}
.accent-color-text {
  color: $accent-color;
}
.orange-hover {
  cursor: pointer;
  transition: color 300ms linear;
  &:hover {
    color: #ce7e07;
  }
}
.orange-text-hover:hover {
  transition: all 300ms linear;
  color: #bbb;
}
.primary-color-text {
  color: #00112b;
}

/*** Font Weight Styles ***/
.bold-text {
  font-weight: 500;
}
.normal-text {
  font-weight: 400;
}

/*** Text Transfrom Styles ***/
.uppercase-text {
  text-transform: uppercase;
}

/*** Font Size Styles ***/
.xxs-text {
  font-size: 10px;
}
.xs-text {
  font-size: 12px;
}
.small-text {
  font-size: 13px;
}
.medium-text {
  font-size: 16px;
}
.large-text {
  font-size: 18px;
}
.xl-text {
  font-size: 22px;
}
.xxl-text {
  font-size: 24px;
}

/*** Text Header Styles ***/
.title {
  font-weight: bold;
  padding-left: 40px;
}

/*** Icon Styles ***/
.icon-right {
  margin-left: 8px;
}
.icon-left {
  margin-right: 8px;
}
.middle {
  vertical-align: middle;
}
.page-header {
  font-size: 28px;
  font-weight: 500;
}

/*** Text Transform Styles ***/
.uppercase-text {
  text-transform: uppercase;
}

/*** Link Styles ***/
.link-text:hover {
  opacity: 0.4;
  transition: all 300ms linear;
  cursor: pointer;
}
