.profile-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 24px;
  .panel {
    width: 100%;
    margin: 20px auto;
    padding-bottom: 0;
    &:hover {
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 2px 0 rgba(0, 0, 0, 0.12);
      cursor: default;
    }
  }
  .mui-divider {
    margin-right: 0px;
    margin-left: 0px;
    margin-top: 0;
  }
  .margin-left {
    margin-left: 50px;
  }
}

@media only screen and (max-width: 544px) {
  .profile-page {
    padding: 0 20px;
  }
  .profile-page .panel {
    width: 100% !important;
  }
  .add-circle-icon {
    right: 16px !important;
  }
  .add-info-dropdown-content {
    right: 26px !important;
  }
}

.profile-page .profile-header-card {
  padding: 24px;
  div {
    float: left;
  }
  div:last-of-type .page-header {
    margin-top: -2px;
  }
  .small-title {
    padding-left: 0;
    margin-top: 4px;
  }
}

.profile-initials {
  float: left;
  margin-right: 24px;
  p {
    font-size: 27px;
    height: 64px;
    width: 64px;
    color: #fff;
    background: #262a3f;
    font-weight: 300;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
  }
}

@media only screen and (min-width: 992px) {
  .profile-page-columns > div:first-of-type {
    padding-right: 16px;
  }
  .profile-page-columns > div:last-of-type {
    padding-left: 16px;
  }
}

.personal-info-panel {
  .mui-row {
    padding: 12px 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
  }
  .mui-row:last-of-type {
    border-bottom: none;
  }
}

.profile-info-header i {
  vertical-align: middle;
  font-size: 24px;
  margin-right: 16px;
  color: #666;
}

.profile-settings-panel {
  padding: 24px;
  .profile-info-header {
    color: rgba(0, 0, 0, 0.87);
    float: left;
  }
}

.settings-footer {
  color: #999;
  font-size: 12px;
  margin: 16px 0 48px 0;
}

.profile-section-title-icon {
  font-size: 18px;
  float: left;
  vertical-align: middle;
  margin-left: 20px;
  margin-top: 22px;
  color: #666;
}

.profile-section-title {
  padding-top: 24px;
  font-weight: 500;
  font-size: 15px;
  color: #666;
}

.profile-info-header {
  font-weight: 500;
  font-size: 13px;
  color: #999;
}

.profile-info {
  padding: 20px;
  float: left;
  font-size: 13px;
}

.no-padding-bottom {
  padding-bottom: 0;
}

.no-padding-top {
  padding-top: 0;
}

.profile-page-dropdown {
  position: relative;
  float: right;
  display: flex;
  &:hover {
    .profile-dropdown-content {
      display: block;
    }
  }
  i {
    color: #999;
  }
}

.profile-dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 175px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  right: 10px;
  z-index: 1;
  div {
    padding: 15px 10px;
    text-decoration: none;
    display: block;

    cursor: pointer;
    &:hover {
      background-color: #f5f5f5;
    }
    img {
      height: 20px;
      margin-right: 10px;
    }
  }
  .profile-dropdown-delete {
    color: #f23a30;
    font-size: 20px;
    vertical-align: middle;
  }
  i {
    margin-right: 10px;
  }
}

.main {
  color: #4caf50;
  font-size: 20px;
  vertical-align: middle;
  margin-top: -2px;
  margin-left: 15px;
}

.main-dropdown {
  color: #4caf50;
  font-size: 20px;
  vertical-align: middle;
  margin-top: -5px;
}

.not-shown {
  color: #8b8b8b;
  font-weight: normal;
  display: inline-block;
}

.show {
  display: inline-block;
  color: #ce7e07;
  font-weight: 500;
  padding-left: 15px;
  padding-bottom: 5px;
  cursor: pointer;
}

.disabled-main-link {
  color: rgba(0, 0, 0, 0.5);
  cursor: not-allowed !important;
  &:hover {
    color: rgba(0, 0, 0, 0.5);
    background-color: transparent !important;
  }
  i {
    color: rgba(76, 175, 80, 0.5);
  }
}

.add-circle-icon {
  position: fixed;
  right: 88px;
  bottom: 16px;
  height: 56px;
  -webkit-filter: drop-shadow(2px 2px 2px #a5a5a5);
  cursor: pointer;
}

.close-circle-icon {
  @extend .add-circle-icon;
}

.add-info-dropdown {
  position: relative;
  &:hover {
    .add-info-dropdown-content {
      display: block;
    }
  }
}

.add-info-dropdown-content {
  //display: none;
  position: fixed;
  min-width: 50px;
  right: 98px;
  bottom: 80px;
  div {
    padding: 9px 7px;
    display: block;
    height: 40px;
    width: 40px;
    background-color: #00112b;
    border-radius: 50px;
    margin-bottom: 10px;
    margin-left: 10px;

    cursor: pointer;
    -webkit-filter: drop-shadow(2px 2px 2px #a5a5a5);
  }
}

.add-info-dropdown-content {
  .fax-icon {
    height: 18px;
    margin-left: 1px;
  }
  .email-icon {
    color: #fff;
    margin-top: 2px;
    margin-left: 3px;
    font-size: 20px;
  }
  .phone-icon {
    color: #fff;
    margin-top: 2px;
    margin-left: 3px;
    font-size: 20px;
  }
  .web-icon {
    @extend .fax-icon;
    margin-left: 4px;
  }
}

.add-contact-modal-header {
  padding: 20px 0 10px 20px;
  i {
    vertical-align: middle;
    font-size: 20px;
    margin-right: 5px;
    margin-top: -5px;
  }
  p {
    display: inline-block;
    font-weight: 500;
    font-size: 15px;
    margin-left: 14px;
  }
  .modal-exit {
    color: #000;
    margin-right: 20px;
  }
}

.add-contact-modal-form {
  .mui-divider {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
  }
  .mui-select {
    margin: 20px auto;
  }
  .mui-textfield {
    margin: 20px auto;
  }
}

.add-contact-form-info {
  padding: 0 35px 10px 35px;
  .mui-select__menu {
    top: 45px !important;
    height: 135px !important;
    overflow: hidden;
    &:hover {
      overflow: auto;
    }
  }
}

.add-contact-modal-buttons {
  margin-top: 30px;
  .mui-btn {
    padding: 0 20px;
    font-size: 14px;
    height: auto;
    width: 46%;
    max-width: 112px;
  }
  .save-btn {
    background-color: #00112b;
    color: #fff;
    &:hover {
      color: #00112b;
    }
  }
  .cancel-btn {
    background-color: #bbb;
    color: #fff;
    &:hover {
      color: #00112b;
    }
  }
}
