label {
  overflow: visible;
}
.mui-textfield label {
  overflow: visible;
}

/***** FLOAT STYLES *****/
.float-left {
  float: left;
}
.float-right {
  float: right;
}

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.clear {
  clear: both;
}

/***** FLEX STYLES *****/
.flex {
  display: flex;
}
.flex-justify-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex-center {
  display: flex;
  align-items: center;
}
.flex-center-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-space-justify {
  display: flex;
  justify-content: space-between;
}
.flex-double {
  flex: 2;
}
.flex-half {
  flex: 0.5;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-column {
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 560px) {
  .mobile-560-flex-space {
    display: flex;
    justify-content: space-between;
  }
  .mobile-560-flex-col-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

/***** WIDTH STYLES *****/
.full-width {
  width: 100%;
}

@media only screen and (min-width: 561px) {
  .max-225-width {
    max-width: 225px;
  }
}

/***** CURSOR STYLES *****/
.no-cursor {
  cursor: default !important;
}
.pointer {
  cursor: pointer;
}

/***** COMPS ROW STYLES *****/
@media only screen and (min-width: 624px) {
  .comps-row {
    display: flex;
  }
}
.comps-row-violations {
  display: flex;
}
.violation-value {
  margin-left: 16px;
  text-align: center;
  background-color: #00112b;
  margin-top: 20px;
  margin-bottom: 15px;
  color: #fff;
  font-size: 13px;
  border-radius: 3px;
  cursor: default;
  cursor: pointer;
  padding: 4px 8px;
  flex: 1;
  span {
    margin-left: 4px;
  }
}
.border-right {
  border-right: 1px solid #dcdcdc;
  width: 204px;
}
.comps {
  background-color: #8b8b8b;
  width: 168px;
  margin-left: 20px;
}

/***** EMPTY STATE STYLES *****/
.grey-circle {
  height: 125px;
  width: 125px;
  background-color: #cccccc;
  border-radius: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 250px;
  i {
    color: #fff;
    font-size: 65px;
  }
}
.no-results {
  text-align: center;
  margin-top: 15px;
  font-size: 18px;
  color: #8b8b8b;
  padding-bottom: 10px;
}

/***** LINK STYLES *****/
a:hover {
  text-decoration: none;
}
a:visited {
  text-decoration: none;
}
a:active {
  text-decoration: none;
}
a:link {
  text-decoration: none;
}
a:focus {
  color: #ce7e07;
}

/***** DISPLAY STYLES *****/
.inline-block {
  display: inline-block;
}
.grid {
  display: grid;
}
.block {
  display: block;
}
.hidden {
  display: none !important;
}
.hidden-opacity {
  opacity: 0;
}

@media only screen and (max-width: 900px) {
  .mobile-900-hidden {
    display: none;
  }
}
@media only screen and (min-width: 901px) {
  .desktop-900-hidden {
    display: none;
  }
}

@media only screen and (max-width: 560px) {
  .mobile-560-hidden {
    display: none;
  }
}
@media only screen and (min-width: 561px) {
  .desktop-560-hidden {
    display: none;
  }
}

@media only screen and (max-width: 560px) {
  .mobile-560-block {
    display: block;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */
  .grid {
    display: flex;
    flex-wrap: wrap;
  }
}

/***** GRID STYLES *****/
.column-left {
  margin-right: 8px;
}
.column-right {
  margin-left: 8px;
}
.column-half {
  width: 50%;
}
.column-quarter {
  width: 25%;
}
/*** Margin Styles ***/
.mr-56 {
  margin-right: 56px;
}
.mt-48 {
  margin-top: 48px;
}
.mr-24 {
  margin-right: 24px;
}
.mb-24 {
  margin-bottom: 24px;
}
.mr-16 {
  margin-right: 16px;
}
.mb-16 {
  margin-bottom: 16px;
}
.ml-16 {
  margin-left: 16px;
}
.mt-8 {
  margin-top: 8px;
}
.mr-8 {
  margin-right: 8px;
}
.mb-8 {
  margin-bottom: 8px;
}
.ml-8 {
  margin-left: 8px;
}
.mr-4 {
  margin-right: 4px;
}
.mb-4 {
  margin-bottom: 4px;
}

@media only screen and (max-width: 560px) {
  .mobile-560-mr-10 {
    margin-right: 10px;
  }
}

/***** SPINNER STYLES *****/
.centered-spinner {
  width: 105px;
  margin-left: auto;
  margin-right: auto;
  img {
    width: 100px;
  }
}
.loading-spinner {
  position: absolute;
  height: 100px;
  width: 100px;
  top: 50%;
  left: 50%;
  margin-left: -50px;
  margin-top: -50px;
  z-index: 100;
}
.site-spinner {
  width: 100%;
  min-height: 100vh;
  display: flex;
  img {
    margin: auto;
  }
}
/***** BACKGROUND STYLES *****/
.primary-background {
  background: $primary-color;
}

/***** ERROR STYLES *****/
.error-block {
  font-size: 85%;
  font-weight: 600;
  color: #d50000;
}
.help-block {
  font-size: 85%;
  font-weight: 600;
  text-align: left;
  display: block;
  margin-top: -2%;
  color: #d50000;
}

/***** DATEPICKER STYLES *****/
.react-date-picker__button {
  border: none !important;
  display: flex;
  align-items: center;
}
