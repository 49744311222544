.mq-broker-subheader {
  width: 80%;
  margin: 0 auto;
  height: 100%;
  p {
    color: #fff;
    margin-top: 20px;
    display: inline-block;
    font-size: 16px;
  }
}

.mq-broker-subheader-broker {
  @extend .mq-broker-subheader;
  width: 100%;
  display: flex;
  .mq-broker-toggle-broker {
    display: flex;
    align-items: center;
    margin-left: 24px;
  }
}
.mq-broker-name {
  margin-left: 5%;
}

.mq-broker-toggle {
  margin-top: 4px;
  p {
    display: inline-block;
    color: #898989;
    text-transform: uppercase;
    vertical-align: top;
    margin-top: 2px;
  }
  p.active {
    color: #fff;
  }
  .toggle {
    display: inline-block;
    margin: 4px 16px;
  }
  .cbx:checked ~ label {
    background: #898989;
  }
  .cbx:checked ~ label:after {
    background: #dcdcdc;
  }
}

.mq-broker-toggle-broker {
  @extend .mq-broker-toggle;
  margin-right: 5%;
}

.mq-broker-container {
  @extend .contacts-container;
}

.mq-broker-page {
  .panel {
    padding: 16px;
    position: relative;
    z-index: 2;
    &:hover {
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 2px 0 rgba(0, 0, 0, 0.12);
    }
  }
  .panel [class*="col"] {
    padding: 0 8px 8px 8px;
  }
  .border-left-panel {
    border-left: 6px solid #ce7e07;
    padding-left: 10px;
  }
  .padding-left {
    padding-left: 8px;
  }
}

.mq-nav {
  margin-left: 0 !important;
  margin-right: 0;
  li {
    width: 50%;
    span {
      cursor: pointer;
      display: inline-block;
      line-height: 50px !important;
      padding: 0 !important;
      width: 100% !important;
    }
  }
}

.mq-search-bar {
  overflow: hidden;
  background-color: #00112b;
  position: fixed;
  width: 100%;
  z-index: 8;
  height: 60px;
}

.mq-tab-bar {
  top: 100px;
}

.search-container {
  width: 95vw;
}

.after-panel {
  background-color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 2px 0 rgba(0, 0, 0, 0.12);
  width: calc(80% - 16px);
  height: 16px;
  margin: -28px auto 0 auto;
  border-radius: 5px;
  z-index: 1;
}

.mq-broker-bank-row {
  width: calc(100% - 200px);
  float: left;
}

.header-text {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
  word-wrap: break-word;
  display: inline-block;
  margin-right: 8px;
}

.subtitle-text {
  font-size: 12px;
  color: #bbb;
  font-weight: 300;
}
.subtitle-text-dark {
  color: #333;
}
.subtitle-text.inline {
  display: inline-block;
}

.mq-deal-column-left {
  float: left;
  .rkmd-checkbox {
    margin-top: -8px;
    margin-left: -6px;
    float: left;
    label {
      color: #333;
    }
  }
}

.mq-deal-column-right {
  float: right;
  i {
    font-size: 24px;
    margin-left: 8px;
    color: #bbb;
  }
}

.mq-bank-column-right {
  @extend .mq-deal-column-right;
}

.mq-deal-amount {
  height: 24px;
  width: 24px;
  background-color: #bbb;
  color: #fff;
  line-height: 24px;
  border-radius: 100%;
  text-align: center;
  display: inline-block;
  vertical-align: top;
}

.mq-deal-amount-square {
  @extend .mq-deal-amount;
  border-radius: 3px;
}

.deal-options {
  clear: both;
  padding: 16px 8px 0 8px;
}

.deal-options-left {
  float: left;
  color: #999;
  p {
    text-transform: uppercase;
    font-size: 14px;
    display: inline-block;
    font-weight: bold;
    margin-right: 32px;
  }
  p:last-of-type {
    margin-right: 8px;
  }
}

.deal-options-right {
  float: right;
  color: #999;
  p {
    text-transform: uppercase;
    font-size: 14px;
    display: inline-block;
    font-weight: bold;
    margin-left: 32px;
  }
  p:first-of-type {
    margin-left: 8px;
  }
}

.mq-deal-loading {
  width: 80%;
  margin: 0 auto;
  text-align: center;
  .loading-spinner {
    height: 24px;
  }
}

.no-mq-deals {
  width: 80%;
  margin: 0 auto;
  text-align: center;
  font-size: 13px;
  color: #999;
}

.close-quote {
  float: right;
  color: #999;
  margin-top: 16px;
  font-size: 24px;

  cursor: pointer;
}

.deal-snooze-icon {
  height: 18px;
  width: 18px;
  margin-right: 6px;
}

/***** WRITE UP STYLES *****/
.write-up {
  .panel {
    max-width: 1000px;
  }
  .panel-heading {
    padding: 16px 24px;
    background-color: #fff;
  }
  .grey-circle {
    margin-top: 24px;
    margin-bottom: 24px;
  }
}

.write-up-content {
  padding: 16px 24px;
}

.write-up-date {
  display: inline-block;
  font-weight: 500;
  margin-right: 32px;
  p {
    display: inline-block;
  }
}

.write-up-time {
  @extend .write-up-date;
}

.non-qts {
  margin-top: -16px;
  background: #bbb;
  display: block;
  width: 66px;
  text-align: center;
  border-radius: 0 0 3px 3px;
  margin-left: 8px;
  margin-bottom: 12px;
  color: #fff;
  font-size: 13px;
  padding: 2px;
  float: left;
}

.non-qts + .deal-tag {
  background: rgb(242, 188, 43);
  float: right;
  margin: -16px 8px 0 0;
  border-radius: 0 0 3px 3px;
}

.bank-quote-star {
  margin-right: 16px;
}

.bank-star {
  position: absolute;
  right: 12px;
  top: 32px;
}

.mq-deal-star {
  display: inline-block;
}

.empty-row {
  height: 16px;
}

.mq-broker-icon-container {
  display: inline-block;
  position: relative;
}
.mq-broker-icon-hover {
  display: none;
}
.mq-broker-icon-container:hover .mq-broker-icon-hover {
  display: inline-block;
}
.mq-broker-icon-container:hover .mq-broker-icon {
  display: none;
}
.mq-broker-icon-hover {
  height: 24px;
}
.mq-broker-tooltip {
  opacity: 0;
  position: absolute;
  bottom: 100%;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  left: 50%;
  font-size: 10px;
  width: 120px;
  text-align: center;
  border-radius: 3px;
  margin-left: -60px;
  padding: 4px;
  transition: all 300ms linear;
}
.mq-broker-icon-container:hover .mq-broker-tooltip {
  opacity: 1;
}

/***** MEDIA QUERIES *****/

@media only screen and (max-width: 991px) {
  .mq-broker-container {
    padding-top: 120px;
  }
}

@media only screen and (max-width: 768px) {
  .mq-broker-deal-col {
    min-height: 75px;
  }
  .mq-broker-bank-col {
    min-height: 75px;
  }
  .mq-bank-column-right {
    float: none;
  }
  .mq-deal-amount-square {
    margin-left: -8px;
  }
}

@media only screen and (max-width: 660px) {
  .mq-broker-page .contact-input {
    width: 60%;
  }
}

@media only screen and (max-width: 544px) {
  .mq-broker-page .panel {
    width: 95%;
  }
  .after-panel {
    width: calc(95% - 16px);
  }
  .mq-broker-subheader {
    width: 95%;
  }
}
