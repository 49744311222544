/***** MY PROPERTIES SUBHEADER STYLES *****/
.my-properties-subheader-container {
  display: flex;
  justify-content: space-between;
}
.my-properties-subheader-container .search-container {
  width: 400px;
}
.my-properties-subheader-container .search-form {
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 12px;
}
.my-properties-subheader-container .search-icon {
  font-size: 20px;
  margin-right: 8px;
}

/***** MY PROPERTIES PAGE STYLES *****/

.mobile-header-searchbar + .my-properties-container {
  padding-top: 120px !important;
}

.my-properties-container {
  padding-top: 64px;
}

/*** My Properties Filters ***/
.filter-bar-container {
  width: 80%;
  margin: 0 auto;
  margin-top: 16px;
}
.filter-bar {
  display: inline-flex;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
}
.filter-bar input[type="radio"] {
  display: none;
}
.filter-bar input[type="radio"] + label {
  padding: 4px 24px;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
}
.filter-bar input[type="radio"] + label:nth-child(4) {
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
}
.filter-bar input[type="radio"]:checked + label {
  background-color: #ce7e07;
  font-weight: normal;
  color: white;
  transition: background-color, color 0.2s;
}

.property-title {
  padding-top: 30px;
}

.active-deals-pw {
  .panel {
    padding-bottom: 5px;
  }
  .quote-section {
    padding-left: 40px;
    margin-bottom: 0;
    margin-top: 25px;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 14px;
  }
}

.mui-divider {
  margin-right: 20px;
  margin-left: 20px;
  margin-top: 15px;
}

.street-view {
  float: right;
  padding-top: 20px;
  padding-right: 20px;
}
.street-view-square {
  @extend .street-view;
  padding: 0;
  margin: 11px 16px 0 0;
  cursor: pointer;
  &:hover {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.2);
  }
}

.deal-tag {
  text-transform: initial;
  margin-left: 24px;
  display: inline;
  font-size: 12px;
  color: white;
  padding: 2px 10px;
  border-radius: 3px;
}

.deal-tag-green {
  @extend .deal-tag;
  background-color: green;
}

.deal-tag-red {
  @extend .deal-tag;
  background-color: #cc0000;
}

.deal-tag + .deal-tag {
  margin-left: 12px;
}

.active-deals-ip {
  @extend .active-deals-pw;
}
.active-deals-fpw {
  @extend .active-deals-pw;
}
.active-deals-ip .note {
  padding-left: 40px;
}
.active-deals-ip .panel .panel-heading {
  min-height: 55px;
  height: auto;
}

.match-confidence-tag {
  display: inline-block;
  font-size: 12px;
  color: #fff;
  background-color: #00112b;
  padding: 2px 10px;
  border-radius: 3px;
  margin-top: 20px;

  cursor: pointer;
  margin-left: 2%;
}

.properties-quote-subheader {
  width: 85%;
  margin: 56px auto 48px auto;
  position: relative;
  hr {
    background-color: #ce7e07;
  }
  p {
    position: absolute;
    background: #f5f5f5;
    width: 140px;
    padding: 8px 0;
    left: 50%;
    margin-left: -70px;
    top: -20px;
    text-transform: uppercase;
    font-size: 16px;
    color: #666;
    text-align: center;
  }
}

.deal-delete {
  float: right;
  margin: 11px 16px 16px 0px;
  color: #999;
  transition: all 300ms linear;
  border: 1px solid #999;
  height: 32px;
  width: 32px;
  border-radius: 2px;
  text-align: center;
  background: rgba(153, 153, 153, 0.1);
  font-size: 21px;
  padding-top: 5px;

  cursor: pointer;
  &:hover {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.2);
  }
}
