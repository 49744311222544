.directory {
  background-image: url("/images/directory-background-image.png");
  background-size: cover;
  height: 100vh;
  padding-top: 72px;
  .sub-header {
    overflow: visible !important;
  }
}

@media only screen and (max-width: 840px) {
  .directory {
    height: calc(100vh - 50px);
  }
}

@media only screen and (max-width: 840px) {
  .directory {
    height: calc(100vh - 50px);
  }
}

/***** DIRECTORY LANDING PAGE STYLES *****/
.directory-flex-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
#directions {
  color: #ffffff;
  font-size: 20px;
}
.dead-search-container {
  width: 576px;
  margin: 32px auto 0 auto;
  background-color: #ffffff;
  border-radius: 2px;
  height: 40px;
  line-height: 40px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1);
  padding: 0 8px;
}
#dead-search-icon-small {
  height: 22px;
  padding-right: 8px;
}
#email {
  text-decoration: none;
  display: inline-block;
}

#feedback {
  padding: 0 16px;
  margin-bottom: 16px;
  height: 44px;
  font-size: 14px;
}
#feedback:hover {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  background: #ce7e07;
  color: #fff;
}
#feedback-text {
  text-align: center;
  color: #ffffff;
  font-size: 13px;
  margin: 0 auto 48px auto;
  background: rgba(0, 0, 0, 0.3);
  line-height: 1.5;
  max-width: 320px;
}

/***** DIRECTORY SEARCH PAGE STYLES *****/
.search-page {
  background-image: none;
  padding-top: 0;
  input {
    font-weight: 300;
    letter-spacing: 0.3px;
    font-size: 13px;
  }
}

/*** Search Container Styles ***/
.search-container {
  width: 40%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 13px;
  background-color: rgba(255, 255, 255, 0.16);
  border-radius: 2px;
  padding: 2px;
  position: relative;
}
.search-page .search-container {
  width: 500px;
  max-width: 96%;
}
.search-form {
  margin-bottom: 0;
  position: relative;
}
.directory-search-icon {
  vertical-align: middle;
  color: #fff;
  margin-left: 8px;
  font-size: 18px;
}
#search-icon-small {
  height: 22px;
  width: auto;
  padding-left: 10px;
  padding-right: 10px;
}
.contacts-results {
  padding-top: 75px;
}
.directory .footer p {
  color: white;
  font-size: 13px;
}
.input {
  width: 88%;
  height: 32px;
  border: none;
  padding-left: 0;
  margin-left: 0;
  color: #ffffff;
  font-size: 1em;
  background-color: transparent;
}
.input:focus {
  outline: none;
}
::-webkit-input-placeholder {
  color: #ffffff;
}

/*** Suggestions Box Styles ***/
#suggestions-box {
  display: block !important;
  position: absolute;
  max-height: 214px;
  width: 100%;
  margin-top: 2px;
  background-color: #fff;
  border-radius: 0 0 3px 3px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  left: -4px;
  &:hover {
    overflow: auto;
  }
}

.suggestion {
  padding: 0 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: 8px 0;
  &:hover {
    background: #f5f5f5;
  }
}
.suggestion-icon {
  font-size: 18px;
}
.sug-p {
  margin: 5px;
  font-size: 13px;
  max-height: 21px;
  overflow: hidden;
  display: inline-block;
}
.sug-info {
  overflow: hidden;
  text-align: left;
  margin-top: 10px;
  margin-left: 12.5%;
  padding-top: 10px;
  padding-bottom: 10px;
  word-wrap: break-word;
  a {
    color: #000000;
    &:hover {
      color: #ce7e07;
    }
  }
}

/*** No Results Styles ***/
#no-results {
  text-align: center;
  margin-top: 150px;
}

/*** Contacts Container Styles ***/
.contacts-box {
  width: 80%;
  margin: 16px auto;
  .mui-divider {
    margin-left: 0;
    margin-right: 0;
    margin-top: 10px;
  }
}

/***** DIRECTORY CONTACT STYLES *****/
.contact-heading {
  background-color: #fff;
  padding: 20px 16px;
  border-radius: 3px 3px 0 0;
  p {
    color: rgba(0, 0, 0, 0.87);
  }
}
.contact {
  background-color: #ffffff;
  position: relative;
  font-size: 12px;
  padding: 16px 0;
}
.contact-padding {
  padding: 0 16px;
}
.contact:hover {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.contact:last-child {
  border-radius: 0 0 3px 3px;
}
.contact .contact-name {
  font-weight: bold;
  padding-top: 13px;
  padding-left: 40px;
  padding-right: 20px;
  font-size: 1.5em;
  text-align: left;
  color: #00112b;
}
.directory-toggle-arrow {
  float: right;
  color: #8b8b8b;
  cursor: pointer;
}

@media only screen and (max-width: 840px) {
  .contact-heading {
    display: none;
  }
  .contact:first-child {
    border-radius: 3px 3px 0 0;
  }
  .contact-padding > div > *:before {
    content: attr(data-label);
    float: left;
    font-weight: 400;
    color: #333;
    text-align: left;
  }
  .contact .mui-row > div {
    width: 100%;
    text-align: right;
  }
  .directory-toggle-arrow {
    margin-right: -8px;
  }
  .open-contact-info-container {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding-top: 12px;
    margin-top: 12px;
  }
}

/*** Directory Contact Bio Styles ***/
.directory-contact-bio {
  border-top: 2px solid #ccc;
  padding: 16px 16px 0 16px;
  margin-top: 16px;
}

/*** Directory Contact Listings Styles ***/
.directory-contact-listings {
  border-top: 2px solid #ccc;
  margin-top: 16px;
}
.directory-contact-listings > p {
  color: rgba(0, 0, 0, 0.87);
  padding: 16px 16px 0 16px;
}
.directory-contact-listings .open-contact-listings-titles p {
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
}
.listings-table {
  width: 100%;
}
.listings-table td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.listings-table tbody tr:last-of-type td {
  border-bottom: none;
}
.listings-table .street-view-square {
  float: none;
  margin: 0;
}
.directory-contact-listings .listings-table tr {
  border: none;
  margin: 0;
  padding: 0;
}
.directory-contact-listings .listings-table td {
  font-size: 12px;
}
.directory-contact-listings .soft-quote-square {
  margin-left: 16px;
}

hr {
  margin: 0;
  margin-top: -6px;
  margin-bottom: 10px;
}

.dir-icons {
  width: 5%;
  margin-right: 5px;
}

.limited-results {
  margin-top: 20px;
  p {
    color: #8b8b8b;
    padding-bottom: 20px;
  }
}

.directory-results {
  padding-top: 250px;
  .grey-circle {
    margin-top: 0 !important;
  }
}

::-webkit-input-placeholder {
  color: darkgray;
  font-family: "Roboto", sans-serif;
}
.search-page ::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.8);
  font-family: "Roboto", sans-serif;
}
.search-page :-moz-placeholder {
  /* Firefox 18- */
  color: rgba(255, 255, 255, 0.8);
  font-family: "Roboto", sans-serif;
}

.search-page ::-moz-placeholder {
  /* Firefox 19+ */
  color: rgba(255, 255, 255, 0.8);
  font-family: "Roboto", sans-serif;
}

.search-page :-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.8);
  font-family: "Roboto", sans-serif;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: gray;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: gray;
}

:-ms-input-placeholder {
  color: gray;
}

@media only screen and (max-width: 1400px) {
  .search-container {
    width: 55%;
  }
}

@media only screen and (max-width: 1200px) {
  .contacts-box {
    width: 85%;
  }
}

@media only screen and (max-width: 1097px) {
  #dead-search-icon-small {
    height: 20px;
    padding-left: 10px;
    padding-right: 2px;
  }
}

@media only screen and (max-width: 959px) {
  .search-container {
    width: 60%;
  }
  #feedback {
    margin-top: 150px;
  }
}

@media only screen and (max-width: 800px) {
  .search-container {
    width: 70%;
  }
}

@media only screen and (max-width: 615px) {
  .search-container {
    width: 60%;
  }
  .search-container .input {
    width: 80%;
  }
}

@media only screen and (max-width: 575px) {
  #directions {
    font-size: 16px;
    margin-bottom: 9%;
  }
}

@media only screen and (max-width: 550px) {
  #feedback-text {
    width: 80%;
  }
}

@media only screen and (max-width: 544px) {
  .search-container {
    width: 56%;
  }
  .search-container .input {
    width: 70%;
  }
  .directory-search-icon {
    font-size: 18px;
    margin-right: 8px;
  }
}

@media only screen and (max-width: 400px) {
  #search-icon-small {
    height: 24px;
  }
}

/***** MEDIA QUERIES *****/
@media only screen and (max-width: 600px) {
  .dead-search-container {
    width: 400px;
  }
}

@media only screen and (max-width: 424px) {
  .dead-search-container {
    width: 240px;
  }
  .dead-search-container .input {
    width: 80%;
    font-size: 0.9em;
  }
}
