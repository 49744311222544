.custom-select {
  border: 2px solid #e8e8e8;
  background: #f5f5f5;
  border-radius: 3px;
  height: 40px;
  line-height: 40px;
  position: relative;
  select {
    -webkit-appearance: none;
    height: 40px;
    border: none;
    padding-left: 8px;
    width: 100%;
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iNiIgd2lkdGg9IjEwIj48cG9seWdvbiBwb2ludHM9IjAsMCAxMCwwIDUsNiIgc3R5bGU9ImZpbGw6cmdiYSgwLDAsMCwuMjQpOyIvPjwvc3ZnPg==);
    background-repeat: no-repeat;
    background-position: right center;
    font-size: 13px;
    &:focus {
      height: 40px;
    }
  }
  i {
    position: absolute;
    right: 8px;
    top: 8px;
    pointer-events: none;
    color: #666;
  }
}
