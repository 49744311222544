@import "vendor-overrides/react-modal";
@import "vendor-overrides/ym-calendar";
@import "vendor-overrides/mui-css";
@import "vendor-overrides/rdt";

@import "base/colors.scss";
@import "base/typography.scss";

@import "layout/header";
@import "layout/sidebar";
@import "layout/sub-header";

@import "components/buttons";
@import "components/card";
@import "components/custom-select";
@import "components/md-checkbox";
@import "components/modal";
@import "components/multiselect";
@import "components/panel";
@import "components/standard-calculator";
@import "components/radio-buttons";
@import "components/table";
@import "components/tags";
@import "components/toast";
@import "components/toggle";

@import "pages/account-activation";
@import "pages/eastern-way";
@import "pages/loans";
@import "pages/news-feed";
@import "pages/rate-sheet";
@import "pages/reports";
@import "pages/authentication";
@import "pages/directory";
@import "pages/in-process-pipe";
@import "pages/missing-quotes";
@import "pages/calculators";
@import "pages/home";
@import "pages/deal-details";
@import "pages/deal-review";
@import "pages/active-deals";
@import "pages/remaining-portfolio";
@import "pages/status-summary";
@import "pages/bank-quotes";
@import "pages/deal-contacts";
@import "pages/property-docs";
@import "pages/ip-deal";
@import "pages/public-login";
@import "pages/comps";
@import "pages/profile";
@import "pages/contacts";
@import "pages/not-found";
@import "pages/qts";
@import "pages/lending-criteria";
@import "pages/reonomy";
@import "pages/mq-broker";
@import "pages/my-deals";
@import "pages/investing-criteria";
@import "pages/unsubscribe";
@import "pages/salesbroker-deals";
@import "pages/investing-criteria";
@import "pages/docs";
@import "pages/accounting";

@import "general-classes";

* {
  &:focus {
    outline: none !important;
  }
}

/*** React CSS Transition Group ***/
.example-enter {
  left: -400px;
}

.example-enter.example-enter-active {
  left: 0;
  transition: all 300ms ease-in;
}

.example-leave {
  left: 0;
}

.example-leave.example-leave-active {
  left: -800px;
  transition: all 300ms ease-in;
}

* {
  margin: 0;
}

html,
body {
  height: 100%;
}

html {
  font-size: inherit;
}

body {
  font-family: "Roboto", sans-serif;
  background-color: #f5f5f5;
}

.mui-scroll-lock {
  overflow: auto !important;
  padding-right: 0 !important;
}

#app {
  height: 100%;
}
#app > div:first-of-type {
  min-height: 100%;
  height: 100%;
}
.full-height {
  height: 100vh;
}
.wrapper {
  //min-height: 100%;
  height: 115%;
  margin: 0 auto -50px;
}
.content {
  height: 100%;
}
#outer-page {
  height: 100%;
}
#page {
  height: 100%;
  position: relative;
  width: calc(100% - 224px);
  float: right;
  padding-top: 40px;
}
#user-page {
  height: calc(100% - 64px);
  position: relative;
  width: calc(100% - 224px);
  float: right;
}
.no-side-bar-page {
  width: 100% !important;
}
#right {
  float: right;
}
@media only screen and (max-width: 959px) {
  #page {
    width: 100%;
    padding-top: 50px;
  }
  #user-page {
    width: 100%;
    padding-top: 50px;
  }
}

@media only screen and (min-width: 1700px) {
  #user-page {
    width: calc(100% - 320px);
  }
}

@media only screen and (max-width: 546px) {
  // Styles for Google places address autocomplete dropdown results.
  // See https://developers.google.com/maps/documentation/javascript/places-autocomplete#style_autocomplete
  .pac-item {
    line-height: 64px !important;
    font-size: 13px !important;
    min-height: 64px !important;
  }
  .pac-item-query {
    font-size: 15px !important;
  }
}
