/***** REPORTS CONTAINER STYLES *****/
.report-outer-container {
  padding: 40px;
}
.reports-container {
  max-width: 1200px;
  width: 90%;
  margin: 16px auto;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 1px 1px rgba(0, 0, 0, 0.24);
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  overflow: auto;
  background: #fff;
}

@media only screen and (max-width: 540px) {
  .reports-outer-container {
    padding: 40px 24px;
  }
  .reports-container {
    width: 100%;
  }
}

/***** REPORTS LIST STYLES *****/
.reports-list .mui-row {
  height: 48px;
  padding: 14px 16px;
  font-size: 13px;
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.report-list-title {
  text-transform: uppercase;
  font-weight: 500;
}
.reports-list .mui-row:nth-child(odd) {
  background: #fdfdfd;
}
.reports-list .mui-row:nth-child(even) {
  background: #fff;
}
.reports-list .mui-row:first-of-type {
  border-radius: 3px 3px 0 0;
}
.reports-list .mui-row:last-of-type {
  border-radius: 0 0 3px 3px;
  border-bottom: none;
}
.other-brokers-opened {
  display: flex;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.reports-row-icon {
  height: 16px;
  margin-right: 20px;
}
.select-brokers-container > div {
  width: 100%;
  margin-top: 24px;
  margin-bottom: 16px;
  min-height: 160px;
  position: relative;
}
.select-brokers-container > div .add-note-buttons {
  position: absolute;
  bottom: 0;
  right: 0;
}
.select-brokers-container .css-vj8t7z {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0;
}
.select-brokers-container .css-11unzgr {
  max-height: 120px;
  overflow: auto;
}

/***** REPORT TABLE STYLES *****/
.reports-table-container {
  padding: 70px 40px;
}
.sub-header-title {
  font-size: 18px;
  color: #fff;
  display: inline-block;
  margin-left: 80px;
  margin-top: 18px;
}
.reports-table {
  width: 100%;
}
.reports-table thead {
  background: #fff;
  padding: 16px 0;
  font-size: 12px;
  color: #bbb;
  text-transform: uppercase;
}
.reports-table th {
  font-weight: 300;
  vertical-align: middle;
  padding: 16px;
}
.reports-table td {
  vertical-align: top;
  padding: 8px 16px;
  font-size: 13px;
  border: 1px solid #e8e8e8;
}
.reports-table td a:hover {
  color: #ce7e07;
}
.reports-table tbody tr:nth-child(odd) {
  background: #fff;
}
.reports-table tbody tr:nth-child(even) {
  background: #fdfdfd;
}
.reports-table tr td:first-of-type {
  border-left: none;
}
.reports-table tr td:last-of-type {
  border-right: none;
}
.reports-table tr:last-of-type td {
  border-bottom: none;
}
