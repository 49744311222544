.rkmd-checkbox {
  color: #818181;
  font-size: 14px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.5px;

  .input-checkbox {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 35px;
    text-align: center;
    vertical-align: -10px;

    input[type="checkbox"] {
      visibility: hidden;
      position: absolute;
      left: 7px;
      bottom: 7px;
      margin: 0;
      padding: 0;
      outline: none;
      cursor: pointer;
      opacity: 0;

      & + .checkbox:before {
        position: absolute;
        left: 4px;
        bottom: 8px;
        width: 18px;
        height: 18px;
        font-family: "Material Icons";
        font-weight: normal;
        font-style: normal;
        font-size: 24px;
        line-height: 1;
        text-transform: none;
        letter-spacing: normal;
        word-wrap: normal;
        white-space: nowrap;
        direction: ltr;
        vertical-align: -6px;

        /* Support for all WebKit browsers. */
        -webkit-font-smoothing: antialiased;
        /* Support for Safari and Chrome. */
        text-rendering: optimizeLegibility;

        /* Support for Firefox. */
        -moz-osx-font-smoothing: grayscale;

        /* Support for IE. */
        font-feature-settings: "liga";

        transition: all 0.2s ease;
        z-index: 1;
      }

      & + .checkbox:before {
        content: "\e835";
        color: #717171;
        font-size: 20px;
      }

      &:checked + .checkbox:before {
        content: "\e834";
        color: #ce7e07;
      }

      &:active:not(:disabled) + .checkbox:before {
        transform: scale3d(0.88, 0.88, 1);
      }

      &:disabled + .checkbox:before {
        color: rgba(0, 0, 0, 0.157) !important;
      }
    }
  }

  &.checkbox-light {
    label,
    .label {
      color: #fff;
    }
    input[type="checkbox"] + .checkbox:before {
      color: #fff;
    }
    input[type="checkbox"]:disabled + .checkbox:before {
      color: #5d5d5d !important;
    }

    &.checkbox-rotate {
      input[type="checkbox"] + .checkbox:before {
        border-color: #fff;
      }
      input[type="checkbox"]:disabled + .checkbox:before {
        border-color: #5d5d5d !important;
      }
    }
  }

  label,
  .label {
    cursor: pointer;
  }

  .ripple {
    width: 32px;
    height: 32px;
  }
}
