.ReactModal__Overlay {
  background-color: rgba(42, 50, 73, 0.5);
  z-index: 20;
}

.ReactModal__Overlay--after-open {
  background-color: rgba(42, 50, 73, 0.7) !important;
  transition: opacity 150ms ease-out;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 224px;
}

.ReactModal__Content {
  background: #fff;
  -webkit-overflow-scrolling: touch;
  border-radius: 0;
  overflow: hidden !important;
  outline: none;
  padding: 20px;
  -webkit-transform: scale(0.5) rotateX(-30deg);
  transform: scale(0.5) rotateX(-30deg);
}

.ReactModal__Content--after-open {
  -webkit-transform: scale(1) rotateX(0deg);
  transform: scale(1) rotateX(0deg);
  transition: all 150ms ease-in;
  width: 40%;
  height: 175px;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Content--before-close {
  -webkit-transform: scale(0.5) rotateX(30deg);
  transform: scale(0.5) rotateX(30deg);
  transition: all 150ms ease-in;
}

@media only screen and (max-width: 959px) {
  .ReactModal__Content--after-open {
    width: 80% !important;
  }
  .ReactModal__Overlay--after-open {
    margin-left: 0;
  }
}

@media only screen and (min-width: 1700px) {
  .ReactModal__Overlay--after-open {
    margin-left: 320px;
  }
}

// @media only screen and (max-width: 700px){
//  .ReactModal__Content--after-open {
//       width: 80% !important;
//   }
// }
