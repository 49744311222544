.deal-docs-page {
  .quote-section {
    font-size: 17px;
    width: 75%;
  }
}
.property-docs {
  .panel {
    width: 90%;
    margin: 20px auto;
  }
  .sm-panel {
    @extend .panel;
    width: 65%;
  }
  .panel-heading {
    background-color: #fcfcfc;
    border-bottom: 1px solid #dcdcdc;
    border-radius: 3px 3px 0 0;
    height: 55px;
    .bank-quote-title {
      padding-top: 20px;
      padding-bottom: 15px;
      padding-left: 40px;
      font-size: 13.5px;
      text-transform: uppercase;
      font-weight: bold;
    }
    .mui-divider {
      margin-top: 0;
    }
  }
}

.document-folder-title {
  padding: 20px 30px;
  font-weight: 500;
  text-transform: uppercase;
}

.document-container {
  .file-icon {
    margin: 17px 20px 15px 20px;
    vertical-align: top;
    height: 20px;
    width: auto;
  }
}

.document-titles {
  display: inline-block;
  padding-top: 10px;
}
.shared-w-client {
  font-size: 13px;
  color: #8b8b8b;
  padding: 17px 20px;
}
.doc-download {
  float: right;
  color: #8b8b8b;
  margin-right: 30px;
  font-size: 20px;
  cursor: pointer;
}
.document-title {
  font-size: 13px;
  font-weight: 500;
  padding-top: 8px;
}

.document-subtitle {
  font-size: 13px;
  color: #8b8b8b;
}

.download-icon {
  float: right;
  margin: 17px 40px 0 0;
  width: 35px;
}

.no-documents {
  padding-left: 3%;
  width: 30%;
  margin-top: 35px;
}

.property-docs-header {
  font-size: 13px;
  line-height: 53px;
  font-weight: 500;
  padding: 0 20px;
}

.property-docs-divider {
  margin-top: 0;
}

.download-tooltip {
  position: relative;
  display: inline-block;
  float: right;
  &:hover {
    .download-tooltiptext {
      visibility: visible;
    }
  }
  .download-tooltiptext {
    visibility: hidden;
    background-color: rgba(0, 0, 0, 0.75);
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 3px;
    position: absolute;
    z-index: 1;
    width: 85px;
    bottom: 85%;
    left: 55%;
    margin-left: -60px;
    &:after {
      content: " ";
      position: absolute;
      top: 100%; /* At the bottom of the tooltip */
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: black transparent transparent transparent;
    }
  }
}
