/***** INVESTING CRITERIA PANEL STYLES *****/
.ic-panel {
  max-width: 1136px;
  width: 96%;
}

/*** Panel Heading Styles ***/
.ic-panel .panel-heading {
  background: #fff;
  border-bottom: 2px solid #bdbdbd;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
}
.ic-panel .panel-heading i {
  font-size: 20px;
  transition: opacity 300ms linear;
  cursor: pointer;
}
.ic-panel .panel-heading i:hover {
  opacity: 0.5;
}

/*** Panel Body Styles ***/
.ic-panel .panel-body {
  padding: 0 24px 8px 24px;
}
.ic-panel .small-title {
  font-size: 12px;
  padding-left: 0;
}
.ic-panel .panel-info-value {
  padding-left: 0;
}
.ic-panel .mui-divider {
  margin: 16px 0;
}

/* Investing Criteria Panel Details Styles */
.ic-panel-details > div {
  margin-bottom: 16px;
}
.ic-panel-details > div:last-child {
  margin-bottom: 0;
  align-items: center;
}
.ic-panel-details .small-title {
  min-width: 200px;
  margin-top: 0;
}
.ic-panel-details .rkmd-checkbox {
  margin-left: -6px;
}
.ic-panel-details .rkmd-checkbox .input-checkbox {
  width: 28px;
  height: 0;
}
.ic-panel-details .label {
  font-weight: normal;
  display: inline-block;
  margin-right: 24px;
  color: rgba(0, 0, 0, 0.87);
}

/***** INVESTING CRITERIA ADD/EDIT PANEL STYLES *****/
.ic-add-panel {
  font-size: 12px;
  padding-bottom: 16px;
}
.ic-edit-panel {
  @extend .ic-add-panel;
}
/*** Panel Heading Styles ***/
.ic-add-panel .panel-heading {
  border-bottom: 2px solid #eeeeee;
  margin: 0 24px;
  padding: 0;
}
/*** Panel Body Styles ***/
.ic-add-panel .panel-body > div {
  margin-bottom: 32px;
}
.ic-add-panel .panel-body > div:last-child {
  margin-bottom: 0;
}
.ic-add-panel .panel-body .small-title.bold-text {
  margin-top: 0;
  margin-bottom: 8px;
}
/*** Add Panel Grid Styles ***/
.ic-panel-grid {
  display: grid;
  grid-template-columns: repeat(8, calc(12.5% - 16px));
  grid-gap: 16px;
}
.ic-panel-grid input {
  border: none;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 4px;
  max-width: 100%;
}
/*** Investing Criteria Nationwide Row Styles ***/
.flex-end-row {
  display: flex;
  align-items: flex-end;
}
/*** Investing Criteria Cash Flow Styles ***/
.ic-cash-flow .rkmd-checkbox {
  display: inline-block;
  margin-right: 24px;
}
.ic-cash-flow .rkmd-checkbox .input-checkbox {
  width: 24px;
  height: 32px;
}
.ic-cash-flow
  .rkmd-checkbox
  .input-checkbox
  input[type="checkbox"]
  + .checkbox:before {
  left: -2px;
}
.ic-cash-flow .label {
  font-size: 12px;
  display: inline-block;
  color: rgba(0, 0, 0, 0.87);
  font-weight: normal;
}
/*** Investing Criteria Notes Styles ***/
.ic-panel textarea {
  width: 100%;
  resize: none;
  border: none;
  border-bottom: 2px solid #eeeeee;
  height: 24px;
}
/*** Investing Criteria Buttons Styles ***/
.ic-btns {
  margin-bottom: 0;
  text-align: right;
}

/***** INVESTING CRITERIA DROPDOWN STYLES *****/
.ic-dropdown-container {
  position: relative;
  width: calc(100% - 24px);
}
.ic-dropdown-container .ic-dropdown {
  opacity: 1;
  visibility: visible;
}
.ic-dropdown-container .small-title {
  font-size: 12px;
  padding-bottom: 4px;
}
.ic-dropdown-select {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 4px;
}
.ic-dropdown-select i {
  font-size: 16px;
  color: $primary-color;
}
.ic-dropdown {
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border-radius: 3px;
  padding: 0;
  position: absolute;
  width: 100%;
  z-index: 2;
  overflow: hidden;
  opacity: 0;
  transition: opacity 300ms linear;
  visibility: hidden;
  padding-top: 8px;
}
.ic-dropdown-content {
  padding: 0 16px;
}
.ic-dropdown .rkmd-checkbox .input-checkbox {
  width: 32px;
  height: 32px;
}
.ic-dropdown
  .rkmd-checkbox
  .input-checkbox
  input[type="checkbox"]
  + .checkbox:before {
  left: -2px;
}
.ic-dropdown .label {
  display: inline-block;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.87);
  font-weight: normal;
}
.ic-dropdown-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  z-index: 2;
  background: #fff;
  padding: 8px 16px;
  bottom: 0;
  width: 100%;
}
.ic-dropdown-right .ic-dropdown {
  width: auto;
  min-width: 200px;
  right: 0;
  bottom: -300%;
  overflow: auto;
}
.ic-dropdown-right .ic-dropdown-content {
  max-height: 400px;
  margin-bottom: 32px;
  overflow: auto;
  padding: 0 16px;
}
.ic-dropdown-right .ic-dropdown-content > p {
  margin: 8px 0;
}

/***** RESEPONSIVE STYLES *****/
@media only screen and (max-width: 959px) {
  .ic-panel-grid {
    grid-template-columns: repeat(4, calc(25% - 16px));
  }
}

@media only screen and (max-width: 680px) {
  .ic-panel-details > .flex {
    display: block;
  }
}

@media only screen and (max-width: 480px) {
  .ic-panel .panel-heading {
    padding: 16px;
  }
  .ic-panel .panel-body {
    padding: 0 16px 8px 16px;
  }
  .ic-panel-details .flex {
    display: block;
  }
  .ic-add-panel .panel-heading {
    margin: 0;
  }
  .ic-dropdown-container {
    width: 100%;
  }
  .ic-panel-grid {
    grid-template-columns: repeat(2, calc(50% - 16px));
  }
  .ic-btns {
    display: flex;
  }
  .ic-btns .mui-btn {
    width: calc(50% - 8px);
  }
  .ic-btns .mui-btn:first-child {
    margin-left: 0;
    margin-right: 8px;
  }
}
