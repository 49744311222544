#nav-bar {
  position: fixed;
  width: 64px;
  background: #ffffff;
  padding: 16px 8px;
  height: 100vh;
  float: left;
  z-index: 12;
  overflow: visible;
  text-align: center;
  box-sizing: border-box;
}
#nav-bar > a {
  display: block;
  height: 64px;
  padding-top: 6px;
}
.nav-logo {
  margin-bottom: 16px;
}

/*** Nav Icons Styles ***/
#nav-bar ul {
  padding-left: 0;
  list-style-type: none;
  max-height: calc(100vh - 185px);
  overflow: hidden;
}
#nav-bar ul:hover {
  overflow: auto;
  margin-right: -6px;
  padding-right: 6px;
}
#nav-bar ul::-webkit-scrollbar {
  width: 6px;
}
#nav-bar ul::-webkit-scrollbar-track {
  border-radius: 0;
  background: #fff;
}
#nav-bar ul::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 50px rgba(0, 0, 0, 0.2);
}
.nav-item {
  display: block;
  padding: 8px 12px;
  margin-bottom: 8px;
  height: 40px;
  width: 48px;
}
.nav-item:hover {
  background-color: #f2f2f2;
  transition: background-color 300ms linear;
}
.nav-item > img {
  max-height: 24px;
}
.active-link {
  background-color: #f2f2f2;
  transition: background-color 300ms linear;
}
.nav-page-title {
  position: absolute;
  background: #303030;
  padding: 2px 8px;
  color: #fff;
  border-radius: 2px;
  font-size: 12px;
  white-space: nowrap;
  left: 100%;
  opacity: 0;
  visibility: hidden;
  transition: opacity 300ms linear;
}
#nav-bar li:hover .nav-page-title {
  opacity: 1;
  visibility: visible;
}

/*** Navbar Footer Styles ***/
.nav-bar-footer {
  position: absolute;
  bottom: 16px;
  left: 0;
  width: 100%;
  text-align: center;
}
.nav-bar-footer > * {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  color: #808080;
  margin-top: 8px;
  font-size: 16px;
  height: 24px;
  width: 48px;
  vertical-align: middle;
}
.nav-bar-footer .hover-styles:hover {
  background-color: #f2f2f2;
  transition: background-color 300ms linear;
  cursor: pointer;
}
.nav-bar-footer a {
  display: flex;
  justify-content: center;
  align-items: center;
}
.nav-bar-footer div a i {
  font-size: 16px;
  color: #808080;
}
.nav-bar-footer .hover-styles:hover .nav-page-title {
  opacity: 1;
  visibility: visible;
}
.nav-bar-footer .app-icons {
  display: flex;
  flex-direction: column;
  height: auto;
}
.nav-bar-footer .app-icons div {
  height: 24px;
  width: 48px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.nav-bar-footer .app-icons div:last-child {
  margin-top: 8px;
  margin-bottom: 24px;
}
.device-ios #android {
  display: none;
}
.device-android #ios {
  display: none;
}

/* Account Dropdown Styles */
.account-dropdown {
  height: 24px;
}
.account-dropdown-content {
  position: absolute;
  left: 100%;
  background: #fff;
  border-radius: 3px;
  padding: 8px 0;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  font-size: 12px;
  text-align: left;
  line-height: 24px;
  bottom: -8px;
  width: 104px;
  opacity: 0;
  transition: opacity 300ms linear;
}
.account-dropdown:hover .account-dropdown-content {
  opacity: 1;
}
.account-dropdown-content a {
  display: block;
  line-height: 24px;
  padding: 0 16px;
  color: #333;
}
.account-dropdown-content a:hover {
  background: #f2f2f2;
  cursor: pointer;
  transition: background 300ms linear;
}
.account-dropdown-content a:focus {
  color: #333;
}

@media only screen and (max-width: 959px) {
  .mobile-menu-toggle {
    color: #ffffff;
    cursor: pointer;
  }

  .mobile-menu {
    position: fixed;
    overflow: auto;
    display: flex;
    flex-direction: column;
    top: 50px;
    left: 0;
    z-index: 20;
    background: #ffffff;
    height: calc(100vh - 50px);
    width: 300px;
    transition: width 400ms ease-out;
  }

  .mobile-menu.closed {
    width: 0;
    overflow: hidden;
    padding: 0;
  }

  .mobile-menu-close {
    color: gray;
    padding: 20px;
    cursor: pointer;
  }
  #nav-bar {
    position: unset;
    width: 100%;
    padding: 0;
    text-align: start;
    height: unset;
  }

  #nav-bar > a {
    display: unset;
    height: 100%;
    padding-top: 0;
  }

  #nav-bar ul {
    margin: 0 0 10px 0;
    padding-bottom: 0;
    height: unset;
    max-height: unset;
    overflow: unset;
  }

  #nav-bar > ul li {
    display: flex;
    align-items: center;
    height: 50px;
    padding-top: 0;
  }

  .nav-logo {
    margin: 16px;
    display: flex;
    align-items: flex-start;
  }

  .nav-item {
    display: flex;
    align-items: center;
    padding: 0;
    margin-bottom: 0;
    height: 100%;
    width: 100%;
    padding-left: 25px;
  }

  .nav-page-title {
    position: unset;
    background: unset;
    padding-left: 10px;
    color: gray;
    // font-size: 12px;
    opacity: unset;
    visibility: visible;
  }

  .mobile-menu-icon {
    color: gray;
  }

  .mobile-menu-divider {
    border: 0.5px solid lightgray;
    display: flex;
  }

  .mobile-menu-eu-links {
    margin-top: 20px;
    padding-left: 55px;
    list-style: none;
    color: gray;
  }

  .mobile-menu-eu-links li {
    height: 45px;
    width: 100%;
  }

  .mobile-menu-eu-links a {
    color: gray;
    width: 100%;
  }
}
