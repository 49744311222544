.eastern-way {
  display: flex;
  height: 100%;
  padding-left: 2%;
  .mui-btn.mui-btn--flat {
    padding: 4px 10px;
    width: 175px;
    font-size: 14px;
    text-align: center;
    margin-top: 20px;
    height: auto;
    margin-left: 40px;
  }
}

.eastern-way-img-container {
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    max-width: 100%;
  }
}

.eastern-way-info-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
  h1 {
    padding-left: 40px;
  }
}

.eastern-way-info {
  font-size: 15px;
  line-height: 25px;
  width: 80%;
  padding-left: 40px;
  span {
    color: #ce7e07;
    cursor: pointer;
  }
}

@media only screen and (max-width: 992px) {
  .eastern-way {
    display: block;
    margin-top: 50px;
    .mui-btn.mui-btn--flat {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .eastern-way-info-container {
    margin-left: 0;
    text-align: center;
    h1 {
      padding-left: 0;
      margin-top: 10px;
    }
  }

  .eastern-way-info {
    padding-left: 0;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}
