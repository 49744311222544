/***** PROPERTY DETAILS STYLES *****/
.property-details .panel {
  max-width: 1000px;
}
.property-details .panel .small-title,
.property-details .panel .panel-info-value {
  padding-left: 24px;
  font-size: 12px;
}

/***** BANK QUOTE STYLES *****/
.bank-quote {
  .quotes-btn {
    margin-left: 40px;
    text-align: center;
    background-color: #ce7e07;
    width: 100px;
    height: 36px;
    padding: 10px 0;
    margin-top: 20px;
    margin-bottom: 15px;
    color: #fff;
    font-size: 13px;
    border-radius: 3px;
    display: inline-block;
    box-shadow: -1px 0 4px rgba(0, 0, 0, 0.18);

    cursor: pointer;
    span {
      text-transform: uppercase;
      letter-spacing: 0.5px;
    }
  }
}

.dd-ss-bank-message {
  color: #00112b;
}

.deals-subheader {
  background-color: #00112b;
  margin-bottom: 35px;
  margin-top: -3px;
  height: 59px;
  position: fixed;
  width: 81%;
  z-index: 1;
}

.no-padding {
  .deals-subheader {
    width: 100%;
  }
}

.deal-details-nav {
  margin-bottom: 0;
  text-align: center;
  padding-left: 0;
  padding-top: 6px;
}

.deal-details-nav-item {
  display: inline-block;
  a {
    height: 50px;
    font-size: 13px;
    line-height: 53px;
    color: rgba(255, 255, 255, 0.4);
    text-transform: uppercase;
    padding-bottom: 16px;
    padding-left: 20px;
    padding-right: 20px;
  }
  &:hover {
    a {
      color: #ffffff;
      border-bottom: 3px solid #ce7e07;
    }
  }
}

.active-deal-nav-item {
  color: #fff !important;
  border-bottom: 3px solid #ce7e07;
}

.deal-padding-top {
  padding-top: 75px;
}

.violations-page {
  .quote-section {
    font-size: 17px;
    width: 75%;
  }
}

.violation {
  .panel {
    padding-bottom: 0;
    cursor: default;
  }
  .mui-divider {
    margin: 0;
  }
}

.violation-circles {
  padding-bottom: 20px;
  padding-top: 25px;
}

.violation-circle {
  height: 40px;
  width: 40px;
  margin: 20px auto;
  border-radius: 100%;
  background-color: #00112b;
  color: white;
  font-size: 20px;
  text-align: center;
  padding-top: 6px;
}

.violation-property {
  margin: 10px 0 10px 50px;
  font-weight: bold;
  font-size: 15px;
  display: inline-block;
}

.violation-city {
  //margin: 20px;
  margin: 20px 0 15px 15px;
  display: inline-block;
}

.violation-name {
  text-align: center;
  margin: 20px;
}

.complaint-total {
  margin: 20px 0;
  padding-left: 4.5%;
  font-weight: 500;
}

.violation-total {
  margin: 20px 0;
  padding-left: 5%;
  color: #ce7e07;
  font-weight: 500;
}

.violation-footer {
  padding: 10px;
  margin-bottom: 80px;
  text-align: center;
  a {
    color: #ce7e07;
  }
}

.bank-quote {
  .panel {
    padding-bottom: 30px;
  }
  .panel-heading {
    background-color: #fcfcfc;
    border-bottom: 1px solid #dcdcdc;
    border-radius: 3px 3px 0 0;
    height: 55px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 40px;
    .bank-quote-title {
      font-size: 13.5px;
      text-transform: uppercase;
      font-weight: bold;
    }
    .large-text {
      font-size: 14px;
    }
    .small-title {
      font-size: 13px;
    }
    .mui-divider {
      margin-top: 0;
    }
  }
}

.bank-quote-reward {
  float: right;
}

.blue {
  color: #0198e1;
}

.green {
  color: #a4c639;
}

.red {
  color: #ff0800;
}

.orange {
  color: #ffa500;
}

.yellow {
  color: #f2bc2b;
}

.yellow-background {
  background-color: #fbbc05;
}

.red-background {
  background-color: #e3484c;
}

.orange-background {
  background-color: #ce7e07;
}

.style-type-none {
  list-style-type: none;
}

.border-bottom {
  border-bottom: 1px solid #dcdcdc;
}

.unconfirmed-btn-container {
  padding-top: 10px;
  padding-right: 25px;
}

.status-tag {
  font-size: 12px;
  font-weight: 500;
  color: rgb(255, 255, 255);
  padding: 5px;
}

.unconfirmed-msg {
  width: 80%;
  text-align: left;
  margin: 0 auto;
  font-size: 14px;
  font-weight: 500;
  color: #616161;
  padding-top: 20px;
}

.tab-container {
  height: 64px;
  width: 100%;
  background-color: #00112b;
  .tab {
    width: 211px;
    color: "#FFFFFF";
    margin-top: 0;
  }
}

.tabs {
  margin-top: 0 !important;
  padding-top: 12px;
  ul {
    text-align: center;
    li {
      border-bottom: 0 !important;
      a {
        color: #ffffff !important;
        height: 100%;
        cursor: pointer;
      }
    }
    .mui--is-active {
      border-bottom: 4px solid #c06a00 !important;
      box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.24),
        0 1px 1.5px 0 rgba(0, 0, 0, 0.12) !important;
    }
  }
}

.redirect-modal-title {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 30px;
}

.redirect-modal-msg {
  font-size: 14px;
  margin-bottom: 10px;
  color: #757575;
}

.redirect-modal-action {
  font-weight: 600;
  text-align: right;
  font-size: 14px;
  cursor: pointer;
}

.uc-panel-info {
  padding-left: 0;
  font-size: 13px;
  margin-bottom: 10px;
}

@media only screen and (max-width: 1600px) {
  .deals-subheader {
    width: 84%;
  }
}

@media only screen and (max-width: 1425px) {
  .deals-subheader {
    width: 80%;
  }
}

@media only screen and (max-width: 1199px) {
  .deals-subheader {
    width: 100%;
  }
}

@media only screen and (max-width: 1200px) {
  .violation-footer {
    margin-left: 0;
    text-align: center;
  }
}

@media only screen and (max-width: 700px) {
  .deal-details-nav {
    li {
      height: 20px;
      &:hover {
        border-bottom: none;
      }
    }
  }
}

@media only screen and (max-width: 490px) {
  .deal-details-nav {
    padding-right: 25px;
    li {
      height: 20px;
      &:hover {
        border-bottom: none;
      }
    }
  }
}

/***** FUND QUOTE STYLES *****/
.fund-quote .panel-body {
  padding-top: 24px;
}
.fund-quote .small-title {
  margin-top: 0;
}
.fund-quote .title.panel-info-title {
  margin-top: 24px;
}

/***** DEAL DETAILS NOTES STYLES *****/
.deal-details-notes {
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 16px;
}
.deal-details-notes .panel {
  width: 100%;
  padding-bottom: 16px;
  margin-top: 8px;
}
.deal-details-notes .panel:hover {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 2px 0 rgba(0, 0, 0, 0.12);
  cursor: default;
}
.deal-details-note {
  padding: 16px 16px 0 16px;
}
.deal-details-note-actions {
  text-align: right;
  opacity: 0;
  visibility: hidden;
}
.deal-details-note-actions i {
  font-size: 20px;
  margin-left: 8px;
}
.deal-details-note-actions i:nth-child(1):hover {
  transition: all 300ms linear;
  color: #ce7e07;
  cursor: pointer;
}
.deal-details-note-actions i:nth-child(2):hover {
  transition: all 300ms linear;
  color: #ce7e07;
  cursor: pointer;
}
.deal-details-note-actions i:nth-child(3):hover {
  transition: all 300ms linear;
  color: #cc0000;
  cursor: pointer;
}
.deal-details-note-actions i:nth-child(4):hover {
  transition: all 300ms linear;
  color: #007a99;
  cursor: pointer;
}
.deal-details-note-actions i:nth-child(5):hover {
  transition: all 300ms linear;
  color: #ce7e07;
  cursor: pointer;
}
.deal-details-note:hover .deal-details-note-actions {
  transition: all 300ms linear;
  opacity: 1;
  visibility: visible;
}
.deal-details-note-text {
  font-size: 13px;
}
.deal-details-notes .mui-divider {
  margin: 16px -16px 0 -16px;
}
.deal-details-note:last-of-type .mui-divider {
  display: none;
}
.new-note {
  position: absolute;
  bottom: 24px;
  right: 24px;
  background: #ce7e07;
  font-size: 80px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  height: 48px;
  width: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  border: none;
}
.new-note i {
  color: #fff;
  font-size: 24px;
}
.new-note:hover {
  transition: all 300ms linear;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  cursor: pointer;
}

/***** DEAL DETAILS ADD/EDIT NOTE STYLES *****/
.note-details-actions {
  color: #fff;
  float: right;
  margin-top: 23px;
  margin-right: 24px;
  display: flex;
  align-items: center;
}
.note-details-actions i {
  margin-left: 16px;
  font-size: 20px;
}
.note-details-actions i:hover {
  opacity: 0.7;
  transition: opacity 300ms linear;
  cursor: pointer;
}
.note-save-btn {
  font-size: 13px;
  height: 24px;
  line-height: 24px;
  width: auto;
  padding: 0 8px;
  margin-top: -2px;
  margin-bottom: 0;
  margin-left: 16px;
}
.deal-details-note-textarea {
  margin-top: -27px;
  background: #fff;
  width: 100%;
  height: 200px;
  padding: 0;
  resize: none;
  height: 200px;
  padding: 16px 24px;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.date-time {
  display: flex;
  align-items: center;
}
.date-time-clear {
  font-size: 20px;
  margin-left: 8px;
  cursor: pointer;
}
.date-time-clear:hover {
  color: #cc0000;
  transition: color 300ms linear;
}
