.reonomy-link {
  float: right;
  margin: 11px 16px 11px 0;
  cursor: pointer;
  img {
    height: 32px;
    border-radius: 2px;
    &:hover {
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.2);
    }
  }
}

.reonomy-panel {
  padding-bottom: 24px !important;
  cursor: default !important;
  .property-title {
    display: inline-block;
  }
  .panel-info-value {
    display: inline-block;
  }
  .panel-city-state {
    display: inline-block;
    margin-bottom: 0;
    font-size: 13px;
    color: #666;
    .bulletpoint {
      margin: 0 8px;
    }
  }
  .panel-confidence {
    @extend .panel-city-state;
  }
  .note {
    padding-left: 40px;
  }
}
