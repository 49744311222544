.contacts-container {
  padding-top: 72px;
  padding-bottom: 24px;
}
.contact-input {
  width: 228px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@media only screen and (max-width: 1168px) {
  .contact-input {
    width: auto;
  }
}

/***** CONTACT SEARCH STYLES *****/
.predictive-search {
  cursor: pointer;
  margin: 16px auto;
  width: 80%;
  transition: color 300ms linear;
}
.predictive-search:hover {
  color: #ce7e07;
}
@media only screen and (max-width: 1200px) {
  .predictive-search {
    width: 90%;
  }
}

@media only screen and (max-width: 992px) {
  .predictive-search {
    width: 96%;
  }
}

/***** CONTACTS - CONTACT PAGE SUBHEADER STYLES *****/
.contacts-calls-container {
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.contacts-calls-container .mui-btn.mui-btn--flat.button {
  height: 32px;
  line-height: 32px;
  padding: 0 24px;
  margin-left: 24px;
}
.total-calls {
  flex: 1;
}
.refresh-button i {
  vertical-align: middle;
  margin-right: 8px;
}
.total-contacts {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0 !important;
  margin-top: 16px;
}
@media only screen and (max-width: 1200px) {
  .total-contacts {
    width: 90%;
  }
}
@media only screen and (max-width: 992px) {
  .total-contacts {
    width: 96%;
  }
}

/***** INDIVIDUAL CONTACT STYLES *****/
.contact-container {
  width: 80%;
  margin: 8px auto 0 auto;
  background-color: #fff;
  border-radius: 3px;
  padding: 16px;
  position: relative;
}
.contacts-name {
  margin: 0;
  float: left;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.8);
}
.rating-contacts-properties {
  display: inline-block;
  font-size: 12px;
  color: #fff;
  background: #00112b;
  padding: 2px 10px;
  border-radius: 3px;
  cursor: pointer;
  margin-left: 8px;
}
.rating-contacts-properties:hover {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.rating-contacts-properties span {
  padding-left: 4px;
}
.contacts-company {
  color: #8b8b8b;
  font-size: 13px;
}
.contacts-giveback {
  margin-right: 16px;
  cursor: pointer;
  color: #00112b;
}
.star-icon-orange {
  color: #ce7e07;
  cursor: pointer;
  font-size: 24px;
}
.star-icon-gray {
  @extend .star-icon-orange;
  color: #8b8b8b;
}
.contacts-calendar-container {
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .react-date-field--theme-default {
    border: none;
    margin-left: 16px;
    margin-top: -14px !important;
  }
  .react-date-field--theme-default .react-date-field__calendar-icon {
    border: 2px solid #00112b;
  }
  .react-date-field__input {
    width: 88px !important;
  }
  .react-date-field--theme-default .react-date-field__calendar-icon:after,
  .react-date-field--theme-default .react-date-field__calendar-icon:before {
    background: #00112b;
  }
  .react-date-field--theme-default .react-date-field__calendar-icon-inner {
    border: 2px solid #00112b;
  }
  .react-date-picker__calendar {
    position: absolute;
    top: 35px !important;
    right: 0;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }
}
.contacts-toggle-arrow {
  float: right;
  color: #8b8b8b;
  cursor: pointer;
}
.contact-detail {
  padding-top: 16px;
  color: #8b8b8b;
}
.contact-detail img {
  height: 24px;
  padding-right: 8px;
}
.contact-detail p {
  display: inline-block;
}
.contact-detail a {
  color: #8b8b8b;
}
.contacts-add-note-container {
  display: inline;
}
.contacts-add-note-container img {
  margin-top: 4px;
  margin-right: 20px;
}
.contacts-add-note-icon {
  vertical-align: bottom;
  margin-left: 4px;
  cursor: pointer;
}
.contact-value {
  font-size: 13px;
}
.open-contact-listings-titles {
  padding-left: 20px;
}
.open-contact-listings-titles p {
  color: #8b8b8b;
}
.open-contact-listings-titles .mui-divider {
  margin-top: 0 !important;
}
/*** Media Queries ***/
@media only screen and (max-width: 1200px) {
  .contact-container {
    margin-left: auto;
    width: 90%;
  }
}

@media only screen and (max-width: 992px) {
  .contact-container {
    width: 96%;
  }
}
@media only screen and (max-width: 544px) {
  .contacts-calendar-container {
    white-space: normal;
    text-align: left;
    margin-top: 56px;
  }
  .contacts-toggle-arrow {
    position: absolute;
    top: 16px;
    right: 8px;
  }
}

/***** CONTACT DETAILS STYLES *****/
/*** Contact Details Subheader Styles ***/
.contact-sub-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: visible;
}
.contact-mobile-header {
  height: auto !important;
  min-height: 60px;
  padding-bottom: 14px;
}
/* Contact Details Subheader Left Styles */
.cd-back-arrow {
  float: left;
  cursor: pointer;
}
.cd-back-arrow img {
  vertical-align: middle;
  height: 24px;
  margin-left: 24px;
  display: inline-block;
  margin-right: 16px;
}
.cd-header-contact {
  float: left;
  color: #fff;
  font-weight: 300;
  font-size: 15px;
}
.cd-rating {
  display: inline-block;
  background-color: #fff;
  border-radius: 3px;
  padding: 0 8px;
  margin-left: 8px;
  cursor: pointer;
}
.cd-rating-contacts-properties {
  @extend .rating-contacts-properties;
  background-color: #fff;
  color: #00112b;
  margin-left: 8px;
}
@media only screen and (max-width: 600px) {
  .contact-sub-header .flex-center {
    display: block;
  }
  .cd-rating-contacts-properties {
    margin-left: 0;
  }
}
/* Contact Details Subheader Right Styles */
.contact-subheader-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
}
.cd-edit,
.cd-delete {
  color: #fff;
  margin-right: 24px;
  font-size: 13px;
  display: flex;
  align-items: center;
}
.cd-edit i,
.cd-delete i {
  vertical-align: middle;
  font-size: 16px;
  margin-right: 4px;
}
.cd-star-wrapper .star-icon-orange {
  margin-top: 4px;
}
.cd-star-wrapper .star-icon-gray {
  margin-top: 4px;
}
.contact-sub-header {
  .react-date-picker {
    float: right;
    margin-left: 20px;
    .react-date-picker__button {
      border: none !important;
    }
    .react-date-picker__button__input {
      background-color: transparent;
      width: 100px;
      color: #fff;
    }
    .react-date-picker__calendar-button {
      color: #fff;
      &:hover {
        color: #ce7e07 !important;
        transition: color 300ms linear;
        &:before {
          background-color: #ce7e07 !important;
          transition: color 300ms linear;
        }
        &:after {
          background-color: #ce7e07 !important;
          transition: color 300ms linear;
        }
        //.react-date-field__calendar-icon-inner {background-color: #ce7e07 !important; transition: background-color 300ms linear;}
      }
      &:before {
        background-color: white !important;
      }
      &:after {
        background: white;
      }
      //.react-date-field__calendar-icon-inner {background: white;}
    }
  }
}
.contact-sub-header
  .react-date-picker__calendar--theme-default
  .react-date-picker__month-view--theme-default {
  position: absolute;
  top: 0;
  right: 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.no-call-date {
  cursor: pointer;
  color: #fff;
}
.cd-client-login {
  cursor: pointer;
  margin-right: 32px;
  margin-left: 0;
  margin-top: 0;
  padding-left: 32px;
  color: #fff;
}
.cd-client-login img {
  height: 22px;
  display: inline-block;
  margin-left: 12px;
}
.nav-arrows {
  margin-right: 24px;
}
.nav-arrows .previous-arrow {
  background-color: rgba(255, 255, 255, 0.1);
  height: 32px;
  cursor: pointer;
  width: 32px;
  padding: 4px 0 0 4px;
  border-radius: 100%;
  margin-right: 8px;
  display: inline-block;
}
.nav-arrows .previous-arrow i {
  color: #fff;
}
.nav-arrows .next-arrow {
  @extend .previous-arrow;
  margin-right: 0;
  margin-left: 8px;
}
.disabled-arrow {
  color: rgba(255, 255, 255, 0.1) !important;
}

/*** Contact Details Page Styles ***/
.cd-padding-top {
  padding-top: 65px;
}
.cd-page .panel {
  width: 70%;
  margin: 20px auto;
  cursor: default !important;
}
.cd-page .mui-divider {
  margin-right: 0px;
  margin-left: 0px;
  margin-top: 0;
}
.cd-page .margin-left {
  margin-left: 50px;
}

/* Contact Details Section Styles */
.cd-section-title-img {
  height: 16px;
  vertical-align: top;
  margin-top: 22px;
  margin-left: 20px;
}
.big-img {
  height: 19px;
  margin-top: 20px;
}
.small-img {
  height: 14px;
}
.cd-section-title-icon {
  font-size: 18px;
  float: left;
  margin-left: 20px;
  margin-top: 22px;
  color: #666;
}
.cd-section-title {
  padding: 20px 0 15px 20px;
  font-weight: 500;
  font-size: 15px;
  color: #666;
  display: inline-block;
}
.cd-info-header {
  padding: 20px;
  font-weight: 500;
  font-size: 13px;
}
.cd-info {
  padding: 20px;
  float: left;
  font-size: 12px;
}

/* Contact Details Primary Information Styles */
.confirmed-contact {
  text-align: right;
  padding-top: 18px;
  padding-right: 24px;
}
.confirmed-contact i {
  font-size: 14px;
  width: 16px;
  height: 16px;
  background: #4caf50;
  color: #fff;
  vertical-align: top;
  margin: 2px 8px 0 0;
  text-align: center;
  cursor: pointer;
  border-radius: 2px;
}
.confirmed-contact p {
  display: inline-block;
  color: #4caf50;
}
.contacts-lc {
  padding: 20px 20px 15px 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  cursor: pointer;
}
.contacts-lc i {
  vertical-align: middle;
  margin-left: 8px;
  font-size: 18px;
  margin-top: -2px;
}
@media only screen and (max-width: 600px) {
  .confirmed-contact {
    padding-right: 8px;
  }
}

/* Contact Details Dropdown Styles */
.cd-page-dropdown {
  position: relative;
  cursor: pointer;
}
.cd-page-dropdown:hover .cd-dropdown-content {
  opacity: 1;
  visibility: visible;
}
.cd-page-dropdown:hover .cd-dropdown-content {
  opacity: 1;
  visibility: visible;
}
.cd-dropdown-icon {
  height: 25px;
  margin-top: 20px;
  float: right;
  margin-right: 20px;
}
.cd-dropdown-content {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 175px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  right: 10px;
  z-index: 1;
  transition: opacity 300ms linear;
}
.cd-dropdown-content div {
  padding: 15px 10px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}
.cd-dropdown-content div:hover {
  background-color: #f5f5f5;
}
.cd-dropdown-content img {
  height: 20px;
  margin-right: 5px;
}
.cd-dropdown-content i {
  margin-right: 10px;
}

/* Contact Details Note Styles */
.note-date {
  color: #666666;
  font-weight: 400;
}
.add-note-row .cd-section-title-icon {
  color: #ce7e07;
}
.add-note-title {
  display: inline-block;
  padding-top: 22px;
  padding-left: 10px;
  padding-bottom: 20px;
  color: #ce7e07;
}
.quick-notes {
  float: right;
  margin-right: 20px;
  margin-top: 20px;
  font-size: 13px;
}
.quick-notes-header {
  display: inline-block;
  color: #bbb;
  margin-right: 4px;
}
.quick-note {
  display: inline-block;
  margin: 0 12px;
  text-transform: uppercase;
  font-weight: 500;
}
.quick-note.orange-text:hover {
  color: #00112b !important;
}
.quick-note-bullet {
  display: inline-block;
}
@media only screen and (max-width: 600px) {
  .quick-notes {
    float: none;
    margin-top: -8px;
    margin-left: 20px;
    margin-right: 8px;
    margin-bottom: 16px;
  }
}

/*** Media Queries ***/
@media only screen and (max-width: 901px) {
  .cd-padding-top {
    padding-top: 116px;
  }
  .contact-sub-header {
    display: flex;
    margin-top: 14px;
  }
  .cd-back-arrow img {
    margin-left: 16px;
  }
  .contact-subheader-right {
    justify-content: flex-end;
    margin-top: 0;
    margin-right: 16px;
  }
  .cd-delete,
  .cd-edit,
  .cd-datepicker,
  .cd-client-login {
    display: none;
  }
  .nav-arrows {
    margin: 0 8px 0 16px;
  }
  .mobile-dropdown-content .react-date-field--theme-default {
    margin-left: -4px;
    float: none;
  }
  .mobile-dropdown-content
    .react-date-field--theme-default
    .react-date-field__input {
    color: #333;
  }
}

@media only screen and (max-width: 720px) {
  .cd-page .panel {
    width: 96%;
  }
}

/***** GENERAL CONTACT STYLES *****/
//TODO make all labels into a common tags class with different background colors
.green-label {
  display: inline-block;
  margin-left: 10px;
  background-color: #4aa04d;
  border-radius: 2px;
  font-size: 11px;
  padding: 0 4px;
  font-style: italic;
  color: #fff;
  letter-spacing: 0.5px;
  font-weight: 300;
  height: 21px;
  line-height: 21px;
}
.gray-label {
  @extend .green-label;
  background-color: #8b8b8b;
}
.contact-properties-container {
  @extend .qts-container;
}

/***** CREATE CONTACT PAGE STYLES *****/
.contact-save-btn {
  @extend .note-save-btn;
  float: right;
  margin-right: 32px;
  margin-top: 20px;
  padding: 0 16px;
}
.create-contact {
  padding: 84px 24px 24px 24px;
  background: #fff;
}
.create-contact-row {
  display: flex;
  align-items: center;
  max-width: 959px;
  position: relative;
}
.create-contact-row > div {
  flex: 1;
}
.create-contact-row > div.flex-double {
  flex: 2;
}
.create-contact-row > div.flex-half {
  flex: 0.5;
}
.create-contact-row i {
  margin-right: 16px;
}
.create-contact-row .input-left {
  margin-right: 32px;
}
.no-icon-row {
  padding-left: 40px;
}
.create-contact-row .toggle-group {
  margin-top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 96px;
  margin-left: 24px;
}
.create-contact-row.more-fields {
  margin-left: 40px;
  margin-top: 24px;
}
.delete-contact-info {
  position: absolute;
  right: -56px;
  transition: color 300ms linear;
  cursor: pointer;
  color: #999;
}
.delete-contact-info:hover {
  color: #ce7e07;
}
.create-contact-row > .input-zip {
  flex: 0.85;
}
.birthday-row {
  margin-bottom: 5px;
}
.birthday-row .react-date-field__input {
  padding: 8px 0;
  font-size: 16px;
}
.contact-fake-select {
  @extend .lc-fake-select;
  i {
    margin-right: -6px;
    height: 21px;
    font-size: 26px;
    top: 16px;
  }
}

@media only screen and (max-width: 640px) {
  .create-contact-row {
    display: block;
  }
  .create-contact-row i {
    display: none;
  }
  .no-icon-row {
    padding-left: 0;
  }
  .create-contact-row .flex-justify-center {
    display: block;
  }
  .create-contact-row .toggle-group {
    margin-left: 0;
  }
  .create-contact-row .input-left {
    margin-right: 0;
  }
  .create-contact-row.more-fields {
    margin-left: 0;
  }
}
