.unsubscribe-panel {
  padding: 40px;
  width: 696px;
}
.unsubscribe-panel h2 {
  font-size: 30px;
  font-weight: 300;
  margin-bottom: 56px;
}
.unsubscribe-panel textarea {
  height: 96px;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.87);
  background: #fafafa;
  resize: none;
  margin-top: 16px;
  margin-bottom: 48px;
}
.unsubscribe-btns .mui-btn.mui-btn--flat.button.btn-accent {
  padding: 0 24px;
  border: 1px solid #ce7e07;
}
.unsubscribe-btns .mui-btn.mui-btn--flat.button.btn-cancel:hover {
  opacity: 0.6;
  background: #fff;
  color: #333;
}

/*** Unsubscribed Success Styles ***/
.unsubscribed-success h2 {
  margin-bottom: 48px;
}
.unsubscribed-success-footer {
  display: flex;
  align-items: flex-end;
}
.unsubscribed-success-footer img {
  margin-bottom: -40px;
  margin-right: 56px;
}

/*** Responsive Styles ***/
@media only screen and (max-width: 704px) {
  .unsubscribe-panel {
    width: 280px;
    padding: 16px;
  }
  .unsubscribe-panel h2 {
    font-size: 22px;
    margin-bottom: 32px;
  }
  .unsubscribe-panel .accent-text {
    font-size: 12px;
  }
  .unsubscribe-panel textarea {
    margin-bottom: 16px;
  }
  .unsubscribe-btns .mui-btn.mui-btn--flat.button {
    font-size: 10px;
    min-width: auto;
  }
  .unsubscribed-success h2 {
    margin-bottom: 64px;
  }
  .unsubscribed-success-footer img {
    display: none;
  }
}
