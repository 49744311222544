.lbl {
  position: relative;
  display: block;
  height: 16px;
  width: 40px;
  background: #898989;
  border-radius: 100px;
  cursor: pointer;
  transition: all 0.3s ease;
  &:after {
    position: absolute;
    left: -2px;
    top: -3px;
    display: block;
    width: 21px;
    height: 21px;
    border-radius: 100px;
    background: #dcdcdc;
    box-shadow: 0px 3px 3px rgba(#000, 0.05);
    content: "";
    transition: all 0.3s ease;
  }
  &:active {
    &:after {
      transform: scale(1.15, 0.85);
    }
  }
}

.cbx:checked ~ label {
  background: lighten(#00112b, 30%);
  &:after {
    left: 20px;
    background: #00112b;
  }
}

.press {
  float: right;
}

.toggle-group {
  margin-top: 40px;
}

.toggle-switch {
  float: right;
}
