.accounting-outer-container {
  padding: 80px 40px;
}

/***** ACCOUNTING CARD STYLES *****/
.accounting-container {
  @extend .reports-container;
}
.accounting-list {
  @extend .reports-list;
}
.accounting-list .flex-space-justify {
  height: auto;
  padding: 14px 16px;
  font-size: 13px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.accounting-list .flex-space-justify:nth-child(odd) {
  background: #fdfdfd;
}
.accounting-list .flex-space-justify:nth-child(even) {
  background: #fff;
}
.accounting-list .flex-space-justify:first-of-type {
  border-radius: 3px 3px 0 0;
}
.accounting-list .flex-space-justify:last-of-type {
  border-radius: 0 0 3px 3px;
  border-bottom: none;
}
.accounting-list > .flex-space-justify div:first-child {
  font-weight: 500;
}
.accounting-card-title {
  border-left: 4px solid $accent-color;
  margin-left: -16px;
  padding-left: 12px;
}

/*** Accounting Summary Styles ***/
.accounting-card-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  width: 90%;
  margin: 16px auto;
}

.accounting-card {
  flex: 1 0 225px;
  height: 130px;
  padding: 24px;
}

.accounting-card:not(:last-child) {
  margin-right: 30px;
}

.accounting-card-amount {
  color: rgba(0, 0, 0, 0.87);
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 14px;
}

.accounting-card-amount-earnings-detail {
  // ## From account-card-amount:
  color: rgba(0, 0, 0, 0.87);
  // font-size: 32px;
  font-weight: bold;
  margin-bottom: 14px;
  // ## From earnings-detail:
  // font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
  line-height: 1.7;
  // ## New properties to replace React style props:
  font-size: 25px;
}

.accounting-card-amount-details {
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
}

.summary-title {
  color: rgba(0, 0, 0, 0.87);
  font-size: 22px;
  font-weight: bold;
  padding: 14px 16px;
}

.summary-table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  th,
  td {
    text-align: left;
    padding: 15px;
    border: none;
  }
  tr:nth-child(odd) {
    background-color: #f5f5f5;
  }
  .align-right {
    text-align: right;
  }
}

.earnings-page-container {
  max-width: 1200px;
  width: 90%;
  margin: 16px auto;
}

.earnings-flex {
  display: flex;
}
.earnings-col {
  display: flex;
  flex-direction: column;
}

.earnings-panel {
  padding: 14px;
  min-width: 315px;
  height: 100%;
}

.earnings-first-col {
  flex: 0 0 40%;
  margin-right: 30px;
}

.earnings-second-col {
  padding: 14px;
  flex: 0 0 57%;
  min-width: 445px;
}

.earnings-divider {
  margin: 15px 0 5px 0;
}

.earnings-detail {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
  line-height: 1.7;
}

.earnings-disclaimer {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.54);
  line-height: 1.7;
}

.earnings-tabs-indicator {
  background-color: #ce7e07;
  height: 3px;
}

.earnings-tab {
  min-width: initial;
  flex-grow: 1;
  font-size: 13px;
}

.earnings-table-container {
  min-width: 980px;
  overflow: auto;
}

.tips-paid-table {
  td,
  th {
    border: none;
  }
}

.payments-title {
  font-size: 22px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.87);
}

.payments-updated {
  color: rgba(0, 0, 0, 0.38);
  font-size: 14px;
  margin-left: 25px;
}

.payments-icon {
  cursor: pointer;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
}

.payments-entitled {
  font-style: italic;
  color: rgba(0, 0, 0, 0.38);
  font-size: 13px;
  font-weight: bold;
  margin-top: 10px;
}

.accounting-list-inner {
  padding: 16px;
  background: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.accounting-list-inner .flex-space-justify {
  background: #fbfbfb !important;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: none;
  height: auto;
  align-items: center;
}
.accounting-list-inner .flex-space-justify > div:first-child p,
.accounting-list-inner .flex-space-justify > p:first-child {
  font-weight: 500;
  display: flex;
  align-items: center;
}
.accounting-list-inner .flex-space-justify > div:first-child p span,
.accounting-list-inner .flex-space-justify > p:first-child span {
  font-size: 24px;
  display: inline-block;
  margin-right: 16px;
}
.accounting-list-inner .flex-space-justify:last-of-type {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.accounting-list-inner .flex-space-justify > div:first-child > div {
  margin-left: 26px;
}

@media only screen and (max-width: 540px) {
  .accounting-outer-container {
    padding: 80px 24px;
  }
}

@media only screen and (max-width: 959px) {
  .accounting-outer-container {
    padding: 80px 8px;
  }
  .accounting-card-container {
    flex-direction: column;
    align-items: stretch;
    width: 100%;
  }
  .accounting-card {
    flex: unset;
    height: unset;
  }
  .accounting-card:not(:last-child) {
    margin-right: 0;
  }

  .accounting-card-hide-mobile {
    display: none;
  }

  .accounting-card-amount-earnings-detail {
    font-size: 16px;
  }

  .earnings-page-container {
    margin: 0 auto;
    width: 100%;
  }

  .earnings-flex {
    display: block;
  }
  .earnings-col {
    display: block;
  }

  .earnings-panel {
    // padding: 14px;
    min-width: unset;
    // height: 100%;
  }

  .earnings-first-col {
    // flex: 0 0 40%;
    margin-right: unset;
  }

  .earnings-second-col {
    // padding: 14px;
    // flex: 0 0 57%;
    min-width: unset;
  }

  .earnings-detail {
    margin-left: 10px;
  }

  .earnings-table-container {
    min-width: unset;
    overflow: scroll;
    width: 100%;
    table {
      min-width: 980px;
    }
  }

  .payments-title {
    font-size: 16px;
  }

  .payments-updated:before {
    content: " ";
    display: block;
  }
}
