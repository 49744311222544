/***** TABLE STYLES *****/
.table-container {
  max-width: 100%;
  overflow: auto;
}
.table {
  width: 100%;
  border-collapse: separate;
}
.table thead tr {
  background: transparent;
}
.table tr {
  height: 64px;
  border-radius: 4px;
  background: #fff;
  border: 4px solid #f3f5f9;
}
.table tr input[type="text"] {
  margin-bottom: 0;
}
.table th,
td {
  vertical-align: middle;
  text-align: left;
  padding: 0 16px;
  border-bottom: 4px solid #f3f5f9;
}
.table th {
  font-size: 13px;
  color: #b9bccd;
  height: 18px;
  font-family: "Open Sans";
  font-weight: 300;
}
.table th p {
  padding-right: 16px;
  display: inline-block;
}
.table th p i {
  position: absolute;
  right: -8px;
  top: -8px;
}
.table td {
  font-size: 14px;
  padding: 16px;
}
.table td:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.table td:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

/*** Responsive Styles ***/
@media screen and (max-width: 840px) {
  .table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  .table tr {
    border: 1px solid #e8e8e8;
    background: #fff;
    border-radius: 3px;
    display: block;
    height: auto;
    margin: 8px;
    padding: 8px;
  }
  .table td {
    border-bottom: 1px solid #ddd;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: right;
  }
  .table td:last-child {
    border-bottom: none;
  }
  .table td:before {
    content: attr(data-label);
    float: left;
    font-weight: 400;
    color: #333;
    text-align: left;
  }
}
