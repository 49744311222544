.qts-background-image {
  background-image: url("/images/qts-background-image.png");
  background-size: cover;
  min-height: 100vh;
  overflow: auto;
  display: flex;
  justify-content: center;
  #page,
  #user-page {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (max-height: 520px) {
  .qts-background-image #page {
    display: block;
  }
  .qts-background-image #user-page {
    display: block;
  }
}

/*** QTS Search Form Styles ***/
.qts-search-form {
  width: 616px;
  background-color: $search-form-background;
  padding: 15px;
  border-radius: 3px;
  max-height: calc(100vh - 112px);
  overflow: auto;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  h3 {
    color: #fff;
    padding-left: 14px;
    float: left;
  }
}

.qts-address-bar {
  width: 95%;
  margin-right: auto;
  margin-left: auto;
  background-color: #fff;
  height: 45px;
  border-radius: 3px;
  padding: 8px 10px 0 10px;
  // margin-top: 16px;
  i {
    color: #8b8b8b;
    vertical-align: middle;
    margin-right: 5px;
  }
}

.qts-search-second-row {
  width: 95%;
  margin-right: auto;
  margin-left: auto;
}
.qts-search-third-row {
  margin-bottom: 10px;
  width: 95%;
  margin-right: auto;
  margin-left: auto;
}
// .qts-search-second-row .mui-select__menu {max-height: 160px;}
// .qts-search-second-row .mui-select__menu > div {height: 32px; line-height: 32px;}
.qts-property-box {
  background-color: #fff;
  margin-top: 10px;
  border-radius: 3px;
  height: 45px;
  padding: 12px 10px 0 10px;
  cursor: pointer;

  position: relative;
  i {
    float: left;
    margin-right: 5px;
    color: #8b8b8b;
  }
  p {
    display: inline-block;
    color: rgba(0, 0, 0, 0.7);
  }
  .drop-arrow {
    float: right;
    color: #8b8b8b;
    margin-right: -3px !important;
  }
  .qts-dropdown-box {
    max-height: 220px;
  }
}

.qts-dropdown-box {
  position: absolute;
  z-index: 1;
  height: 300px;
  width: 205px;
  background-color: #fff;
  margin-right: -10px;
  top: 0;
  right: 35px;
  border-radius: 3px;
  padding: 10px;
  overflow: hidden;
  &:hover {
    overflow: auto;
  }
  .rkmd-checkbox {
    font-weight: normal;
  }
}

.qts-search-area {
  @extend .qts-property-box;
  margin-right: 8px;
  i {
    float: left;
    margin-right: 5px;
  }
  p {
    margin-top: 2px;
  }
}

.qts-lender-area{
  @extend .qts-property-box;
  i {
    float: left;
    margin-right: 5px;
  }
  p {
    margin-top: 2px;
  }
  @extend .qts-search-area;
}

.qts-loan-area{
  margin-left: 8px;
  margin-right: 0;
  @extend .qts-property-box;
  i {
    float: left;
    margin-right: 5px;
  }
  p {
    margin-top: 2px;
  }
  @extend .qts-search-area;
  .qts-dropdown-box {
    max-height: 120px;
  }
}

.search-area-select {
  display: inline-block;
  padding-top: 0;
  margin-top: -4px;
  width: 86%;
  select {
    border-bottom: none;
    color: rgba(0, 0, 0, 0.7);
    font-size: 14px;
  }
  .mui-select__menu {
    top: 0 !important;
  }
}

.qts-time-frame {
  @extend .qts-search-area;
  margin-right: 0;
  margin-left: 8px;
}

.time-frame-select {
  @extend .search-area-select;
}

.toggle-search {
  margin: 16px 0 8px 16px;
  font-size: 13px;
  margin-bottom: 16px;

  cursor: pointer;
}

.advanced-search {
  margin-bottom: 16px;
}

.qts-loan-box {
  @extend .qts-address-bar;
  .input {
    color: #333 !important;
  }
}

.qts-min-max-loan {
  padding-left: 8px;
  padding-right: 8px;
}

.qts-recourse {
  @extend .qts-search-area;
  img {
    margin-right: 5px;
  }
}

.qts-ltv {
  @extend .qts-time-frame;
  padding-top: 8px;
  img {
    margin-right: 4px;
    margin-top: -2px;
  }
  .input {
    color: #333 !important;
  }
}

.qts-bank-note {
  @extend .qts-loan-box;
  img {
    height: 24px;
    width: 24px;
    margin-right: 5px;
  }
}

.qts-show-result-row {
  width: 95%;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 10px;
}

.qts-show-results {
  @extend .qts-search-area;
  margin-top: 0;
  padding-top: 12px;
  width: 100%;
}

.show-result-select {
  @extend .search-area-select;
  width: 93%;
}

.qts-search-btn-row {
  padding-left: 16px;
  padding-right: 16px;
}

.qts-search-btn {
  width: 100%;
  // margin-left: 8px;
  // margin-right: 8px;
  padding: 0;
  display: block !important;
  height: 45px;
  text-align: center;
}

.quote-results {
  .panel {
    width: 87%;
  }
}

/*** QTS Subheader Styles ***/
.qts-page .mui-row.sub-header {
  overflow: visible;
}
.qts-nav {
  margin-right: 60px;
}
.qts-search-dropdown-box {
  @extend .comps-search-dropdown-box;
  height: 352px;
  width: 350px;
  right: 52px;
  top: 52px;
  opacity: 1;
  transition: opacity 300ms linear;
  .mui-textfield > input {
    font-size: 14px;
  }
  .property-container2 {
    padding: 0 24px;
    height: 56px;
    position: relative;
    .property-type-header {
      margin-left: 0;
    }
    .property-arrow {
      margin-right: -6px;
    }
  }
  .qts-dropdown-box p {
    display: inline-block;
  }
  .radius-container2 {
    padding: 0 24px;
    height: 56px;
  }
  .bank-container {
    @extend .radius-container2;
  }
  .address-container {
    padding: 0 16px;
    height: 56px;
    line-height: 56px;
    input {
      line-height: initial; // Fixes hidden input text value bug (iPhone only).
      border-bottom: 1px solid rgba(0, 0, 0, 0.26);
      width: 100%;
    }
  }
  .reset-filters-container {
    margin-top: 8px;
  }
  .search-drpdwn-search-btn {
    width: calc(350px - 48px);
    font-size: 14px;
    padding: 0;
    height: 40px;
  }
  .floating-row {
    float: none;
    padding: 0;
  }
}

@media only screen and (max-width: 546px) {
  .qts-search-dropdown-box {
    right: 16px;
  }

  .qts-search-btn-row {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.hidden-opacity .qts-search-dropdown-box-content {
  display: none;
}

.dropdown-box-header {
  padding: 8px 24px;
  border-bottom: 1px solid #f5f5f5;
  p {
    display: inline-block;
  }
  i {
    vertical-align: middle;
  }
  .search {
    margin-right: 8px;
    font-weight: 500;
  }
  .close {
    float: right;
    font-size: 16px;
    color: #8b8b8b;
    padding-top: 2px;

    cursor: pointer;
  }
}

.qts-quotes-data {
  padding-left: 20px;
  padding-top: 15px;
}

/*** QTS Results Styles ****/
.qts-results {
  .contact-name {
    font-weight: 500;
    padding-left: 20px;
    margin-top: 15px;
    margin-bottom: 0;
    font-size: 13px;
    cursor: pointer;
    &:hover {
      color: #ce7e07;
    }
  }
}

.qts-results-panel {
  width: 80%;
  margin: 16px auto;
  padding-bottom: 0;
  &:hover {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }
  .quote-section {
    text-align: center;
  }
  .qts-heading {
    padding-left: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    padding-bottom: 16px;
  }
  .contact {
    height: 48px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    &:hover {
      box-shadow: none;
    }
  }
  .contact:last-of-type {
    border-bottom: none;
    border-radius: 0 0 3px 3px;
  }
  .contact-name {
    margin: 0;
    padding-top: 0;
  }
}

.qts-container {
  @extend .contacts-container;
  padding-top: 56px;
  padding-bottom: 0;
  .lc-header {
    margin-top: -24px;
  }
}

.qts-heading .small-title {
  font-weight: normal;
  color: #999;
  font-size: 13px;
  padding-left: 0;
}

.qts-bankquote {
  .panel-heading {
    cursor: pointer;
  }
  .small-title {
    padding-left: 25px;
  }
  .circle-icon {
    color: #fff;
    background-color: #8b8b8b;
    border-radius: 100%;
    height: 40px;
    width: 40px;
    vertical-align: middle;
    margin-left: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    float: left;
    i {
      font-size: 17px;
    }
    img {
      height: 24px;
    }
  }
  .deal-info {
    display: inline-block;
    padding-left: 10px;
    max-width: 135px;
    .info-title {
      font-weight: 500;
      font-size: 13px;
    }
    .info-subtitle {
      color: #999999;
      font-size: 13px;
    }
  }
  .banker-name {
    font-size: 13px;
    font-weight: 500;
    float: left;
    padding-top: 2px;
    cursor: pointer;
    transition: color 300ms linear;

    span {
      padding-right: 10px;
      color: #999;
      font-weight: 400;
    }
    &:hover {
      color: #ce7e07;
      span {
        color: #999;
      }
    }
  }
  .banker-name-empty:hover {
    color: rgba(0, 0, 0, 0.87);
    cursor: default;
  }
  .more-bankers, .lending-criteria {
    color: #ce7e07;
    font-size: 13px;
    font-weight: 500;
    float: right;
    margin-top: 16px;
    text-transform: uppercase;
    cursor: pointer;
    margin: 0 5px;

    i {
      vertical-align: middle;
      font-size: 21px;
      padding-left: 5px;
      margin-top: -2px;
    }
  }
  .lending-criteria .lastUpdated{
    font-weight: normal;
    font-size: 9px;
    color: rgba(0, 0, 0, 0.87);
  }
}

.other-banker-name {
  @extend .banker-name;
  float: none !important;
  padding-left: 25px;
}

.other-banker {
  font-size: 13px;
  font-weight: 500;
  line-height: 25px;
  padding-left: 25px;
  display: inline-block;
}

.qts-recommend-footer {
  padding: 20px 25px 12px 25px;
}

.qts-recommend-footer2 {
  padding: 5px 0 12px 0;
}

.panel-heading {
  .bank-title {
    @extend .property-title;
    padding-left: 25px;
  }
}

.bank-name {
  display: block;
  // margin-top: 22px;
  // margin-left: 10px;
  font-size: 16px;
  color: #00112b;
  text-align: center;
  padding-bottom: 22px;
  font-weight: bold;
  // padding-left: 60px;
  // position: absolute;
}

.qts-recommend-divider {
  margin-left: 25px !important;
  margin-right: 25px !important;
  margin-top: 20px !important;
}

.bank-state {
  margin-bottom: 20px;
}

.qts-rates {
  padding-top: 10px;
  .gray-circle-icon {
    height: 110px;
    width: 110px;
    border-radius: 100%;
    background-color: #8b8b8b;
    margin-left: auto;
    margin-right: auto;
    padding-top: 31px;
    padding-left: 29px;
    i {
      font-size: 50px;
      color: #fff;
    }
  }
  h2 {
    text-align: center;
    font-size: 30px;
    font-weight: 500;
    color: #00112b;
    margin-top: 40px;
  }
  p {
    text-align: center;
    margin-top: 10px;
    color: #00112b;
  }
  .title {
    font-weight: bold;
    text-align: center;
    font-size: 16px;
    text-transform: uppercase;

  }
  .header{
    font-weight: bold;
    text-align: center;
  }
  .nonAgency{
    border-bottom: 1px solid #ddd;
  }

}

.qts-map {
  @extend .qts-rates;
  height: calc(100vh - 59px);
  padding-top: 0;
  position: relative;
  .gray-circle-icon {
    padding-left: 33px;
    padding-top: 29px;
  }
  p {
    margin-top: 0;
    text-align: left;
  }
}

.qts-map-key {
  cursor: pointer;
  user-select: none;
  position: absolute;
  top: -22px;
  padding-left: 18px;
  z-index: 1;
  overflow-y: hidden;
}

.qts-map-header {
  i {
    position: relative;
    top: 38px;
    left: 164px;
  }
}

.see-popup {
  position: absolute;
  top: 130px;
  left: 100px;
  z-index: 10;
}

.deal-popup-hidden {
  opacity: 0;
  bottom: 0;
  width: 400px;
  position: absolute;
  overflow: hidden;
  background-color: #fff;
  left: 50%;
  margin-left: -200px;
  border-radius: 6px 6px 0 0;
  box-shadow: 0px 30px 56.4px 3.6px rgba(0, 0, 0, 0.3);
  transition: all 300ms linear;
}

.deal-popup-hidden .deal-popup-content {
  display: none;
}

.deal-popup {
  position: absolute;
  bottom: -2px;
  background-color: #fff;
  width: 400px;
  left: 50%;
  margin-left: -200px;
  border-radius: 6px 6px 0 0;
  box-shadow: 0px 30px 56.4px 3.6px rgba(0, 0, 0, 0.3);
  opacity: 1;
  transition: all 300ms linear;
}

@media only screen and (max-width: 546px) {
  .deal-popup {
    width: unset;
    left: 0px;
    right: 0px;
    bottom: 64px;
    // top: 74px;
    margin-left: unset;
  }
}

.deal-popup-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  p {
    padding: 16px;
    float: left;
  }
  i {
    float: right;
    padding: 17px 16px;
    color: #999;
    font-size: 17px;

    cursor: pointer;
  }
}

.deal-popup-body {
  padding: 24px;
  position: relative;
  .small-title {
    font-size: 13px;
    color: #999;
  }
  .mui-row {
    margin-bottom: 8px;
  }
  .mui-divider {
    margin-bottom: 12px;
    margin-right: 0;
    margin-left: 0;
  }
}

.dp-street-view {
  position: absolute;
  right: 0;
  top: 7px;
  cursor: pointer;
  text-align: center;
}

.dp-reonomy-link {
  position: absolute;
  right: 4px;
  top: 54px;
}

.quotes-title {
  position: relative;
  p {
    position: absolute;
    left: 142px;
    top: -11px;
    background: white;
    padding: 0 4px;
  }
}

.qts-filter-circle {
  position: absolute;
  bottom: 88px;
  right: 72px;
  height: 44px;
  width: 44px;
  background: #00112b;
  color: #fff;
  text-align: center;
  padding-top: 8px;
  font-size: 25px;
  border-radius: 100%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  cursor: pointer;
  z-index: 1;
}

.qts-filter-circle:hover .qts-filter-tooltip {
  display: inline-block;
}

.qts-filter-tooltip {
  position: absolute;
  right: 56px;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  border-radius: 3px;
  font-size: 14px;
  padding: 4px 15px;
  font-weight: 300;
  letter-spacing: 0.5px;
  display: none;
}

.quote-results {
  .panel {
    cursor: default;
    &:hover {
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    }
  }
  .panel-heading {
    background-color: #fff;
    cursor: default !important;
    .property-title {
      padding-left: 20px;
      display: inline-block;
    }
    .street-view {
      float: right;
      padding-top: 20px;
      padding-right: 20px;
    }
    .street-view-square {
      @extend .street-view;
      padding-top: 0;
    }
    .reonomy-link {
      float: right;
      margin: 10px 8px;
      height: 24px;
    }
    .reonomy-link-small {
      height: 32px;
      margin: 2px 8px;
    }
  }
  .panel-body {
    padding: 8px 16px 16px 16px;
  }
  .small-title {
    padding-left: 8px;
  }
  .panel-info-value {
    padding-left: 8px;
  }
}

.brokers {
  color: #000;
  padding-left: 20px;
  .sm-padding {
    padding: 0 7px;
  }
}

.quote-results {
  margin-top: 16px !important;
}

.qts-quote-subheader {
  width: 87%;
  margin: 0 auto;
  color: #999;
  font-size: 14px;
  span {
    margin-right: 8px;
    font-size: 18px;
    vertical-align: middle;
  }
}

.qts-lending-program {
  width: 95%;
  margin-right: auto;
  margin-left: auto;
  margin-top: 8px;
}

@media only screen and (min-width: 1200px) and (max-width: 1380px) {
  .details-info {
    width: 50%;
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1100px) {
  .tablet-margin-left {
    margin-left: -5px !important;
  }
}

@media only screen and (max-width: 991px) {
  .qts-container {
    padding-top: 75px;
  }
  .qts-results {
    width: 100%;
    padding: 0 25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 850px) {
  .details-info {
    width: 50%;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .details-info {
    margin-bottom: 20px;
  }
  .qts-reccomend-divider {
    margin-top: 10px !important;
  }
}

@media only screen and (min-width: 320px) and (max-width: 375px) {
  .qts-search-form {  width: 320px;  }
}

@media only screen and (min-width: 375px) and (max-width: 546px) {
  .qts-search-form { width: 375px; }
}


@media only screen and (max-width: 546px) {

  .qts-property-box {
    width: 100%;
  }
  .qts-loan-box {
    width: 100%;
  }
  .qts-time-frame {
    margin-left: unset !important;
    // margin-top: 16px;
  }
  .qts-min-max-loan {
    .qts-loan-box {
      margin-top: 10px;
    }
  }
  .qts-lending-program {
    width: 100%;
    margin: unset !important;
  }
  .advanced-search .mui-row {
    padding-left: 8px;
    padding-right: 8px;
  }
}

@media only screen and (max-width: 959px) {
  .qts-background-image {
    padding-left: 0;
  }
}
