.status-summary-page {
  padding: 24px;
  .panel {
    padding: 16px 24px;
    width: 100%;
    margin-top: 0;
  }
  .panel:hover {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 2px 0 rgba(0, 0, 0, 0.12);
    cursor: default;
  }
  h2 {
    font-size: 20px;
    font-weight: 500;
    margin: 0 0 8px 0;
  }
  .mui-divider {
    margin: 0 -24px;
  }
}

.bank-running-third-party-banner {
  width: 100%;
  margin-top: 16px;
  min-height: 36px;
  background-color: #dcdcdc;
  border-radius: 3px;
  margin-bottom: 24px;
  padding: 8px 16px;
  p {
    height: 100%;
    display: flex;
    align-items: center;
  }
  i {
    margin-right: 8px;
  }
}

/*** Status Update Styles ***/
.status-update {
  margin-right: 16px;
}
.status-update-header h2 {
  float: left;
}
.status-update-header .timing {
  padding-top: 8px;
}
.timing {
  float: right;
  font-size: 13px;
}
.timing > * {
  float: left;
  margin: 0 4px;
}
.timing img {
  height: 20px;
  margin-right: 4px;
  margin-top: -2px;
}
.status-summary {
  margin-top: 16px;
  font-size: 13px;
}
.status-summary-broker {
  margin-top: 40px;
  font-weight: 500;
}

/*** Status Questions Styles ***/
.status-questions {
  background: #dcdcdc;
  padding: 16px 24px;
  font-size: 13px;
  border-radius: 3px;
  margin-right: 16px;
}

/*** Third Party Styles ***/
.third-party {
  margin-left: 16px;
}
.third-party .panel {
  margin-top: 0;
}
.third-party-section {
  padding: 16px 0;
}
.third-party-title {
  font-weight: 500;
  text-transform: uppercase;
  float: left;
  margin-bottom: 16px;
}
.waiting {
  font-style: italic;
  float: right;
}
.third-party-label {
  background: #dcdcdc;
  border-radius: 3px;
  padding: 0 16px;
  height: 24px;
  line-height: 24px;
  display: inline-block;
}
.phase-inspection-contact label {
  margin-top: 24px;
}

@media only screen and (max-width: 768px) {
  .status-update {
    margin-right: 0;
  }
  .status-questions {
    margin-right: 0;
  }
  .third-party {
    margin-left: 0;
  }
}
