.mui-container-fluid {
  padding: 0;
}

.mui-select > select:focus {
  height: 32px;
}
.mui-textfield > label {
  width: auto;
  font-size: 12px;
}

.mui-textfield > input {
  height: 32px;
  /*width: 350px;*/
}
.mui-textfield > input:disabled {
  color: #8b8b8b;
}
.mui-textfield > input:focus,
.mui-textfield > textarea:focus {
  border-color: #ce7e07;
  border-width: 2px;
}

.mui-textfield > input:focus ~ label,
.mui-textfield > textarea:focus ~ label {
  color: #ce7e07;
}

.mui-btn {
  padding: 0 150px;
  background-color: #ce7e07;
  height: 50px;
  font-size: 20px;
  color: #ffffff;
}

.mui-btn.mui-btn--flat {
  color: #f5f5f5;
  background-color: #ce7e07;
}

.mui-btn:active,
.mui-btn:focus,
.mui-btn:hover {
  color: #ffffff;
  background-color: #ce7e07;
}

.mui-btn.mui-btn--flat:active,
.mui-btn.mui-btn--flat:focus,
.mui-btn.mui-btn--flat:hover {
  color: #ce7e07;
}

.mui-col-lg-1,
.mui-col-lg-10,
.mui-col-lg-11,
.mui-col-lg-12,
.mui-col-lg-2,
.mui-col-lg-3,
.mui-col-lg-4,
.mui-col-lg-5,
.mui-col-lg-6,
.mui-col-lg-7,
.mui-col-lg-8,
.mui-col-lg-9,
.mui-col-md-1,
.mui-col-md-10,
.mui-col-md-11,
.mui-col-md-12,
.mui-col-md-2,
.mui-col-md-3,
.mui-col-md-4,
.mui-col-md-5,
.mui-col-md-6,
.mui-col-md-7,
.mui-col-md-8,
.mui-col-md-9,
.mui-col-sm-1,
.mui-col-sm-10,
.mui-col-sm-11,
.mui-col-sm-12,
.mui-col-sm-2,
.mui-col-sm-3,
.mui-col-sm-4,
.mui-col-sm-5,
.mui-col-sm-6,
.mui-col-sm-7,
.mui-col-sm-8,
.mui-col-sm-9,
.mui-col-xs-1,
.mui-col-xs-10,
.mui-col-xs-11,
.mui-col-xs-12,
.mui-col-xs-2,
.mui-col-xs-3,
.mui-col-xs-4,
.mui-col-xs-5,
.mui-col-xs-6,
.mui-col-xs-7,
.mui-col-xs-8,
.mui-col-xs-9 {
  padding: 0;
}

a {
  color: #000;
}

a:hover {
  text-decoration: none;
  color: #000;
}

.mui-container-fluid {
  height: 100%;
  height: 100vh;
}

.mui-textfield > textarea {
  min-height: 45px;
}

.mui-panel {
  padding: 0;
}

/***** SELECT PLACEHOLDER *****/
.mui-select.placeholder select {
  color: rgba(0, 0, 0, 0.3);
}

.mui-textfield label {
  overflow: visible !important;
}
.mui-select label {
  overflow: visible !important;
}

/***** SELECT PLACEHOLDER *****/
.mui-select.placeholder select {
  color: rgba(0, 0, 0, 0.3);
}

.mui-text-padding-top {
  padding-top: 5px !important;
}

.mui-select__menu {
  top: 0 !important;
  font-size: 14px !important;
}
.mui-row {
  margin: 0;
}
