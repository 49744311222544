/***** REACT DATE-TIME OVERRIDES *****/
.rdt {
  flex: 1;
}
.rdt input {
  width: 100%;
  padding: 4px 8px;
  font-size: 12px;
}
.rdtPicker {
  width: auto;
  font-size: 12px;
}
.rdt tr {
  background: #fff;
}
