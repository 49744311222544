/***** FLAT BUTTON STYLES *****/
.mui-btn.mui-btn--flat.button {
  height: 40px;
  min-width: 104px;
  padding: 0 8px;
  line-height: 40px;
  font-size: 13px;
  font-weight: normal;
  transition: all 300ms linear;
}
.btn {
  border-radius: 3px;
  border: none;
  transition: all 300ms linear;
}

/***** PRIMARY BUTTON STYLES *****/
.mui-btn.mui-btn--flat.button.btn-primary,
.btn.btn-primary {
  background: #00112b;
  color: #fff;
  border: 1px solid #00112b;
}
.mui-btn.mui-btn--flat.button.btn-primary:hover,
.btn.btn-primary:hover {
  color: #00112b;
  background: #fff;
}

/***** ACCENT BUTTON STYLES *****/
.mui-btn.mui-btn--flat.button.btn-accent,
.btn.btn-accent {
  background: #ce7e07;
  border: 1px solid #ce7e07;
  color: #fff;
}
.mui-btn.mui-btn--flat.button.btn-accent:hover,
.btn.btn-accent:hover:not(:disabled) {
  color: #ce7e07;
  background: #fff;
}

/***** ACCENT2 BUTTON STYLES *****/
.mui-btn.mui-btn--flat.button.btn-accent2 {
  background: #999;
  color: #fff;
}
.mui-btn.mui-btn--flat.button.btn-accent2:hover {
  color: #999;
  background: #fff;
}

/***** CANCEL BUTTON STYLES *****/
.mui-btn.mui-btn--flat.button.btn-cancel {
  background: #fff;
  color: #333;
}
.mui-btn.mui-btn--flat.button.btn-cancel:hover {
  color: #fff;
  background: #333;
}

/***** FULL WIDTH BUTTON STYLES *****/
.btn-fullWidth {
  width: 100%;
}

/***** ACTION BUTTON STYLES *****/
.mui-btn.mui-btn--flat.button.btn-action {
  background: rgba(255, 255, 255, 0.24);
  color: #fff;
}
.mui-btn.mui-btn--flat.button.btn-action:hover {
  color: #5f5f5f;
  background: #fff;
}

/***** BUTTON SIZES STYLES *****/
.btn-large {
  height: 40px;
  line-height: 40px;
  padding: 0 32px;
}

/***** DISABLED BUTTON STYLES *****/
.btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
