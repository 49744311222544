/*** Account Activation Card Page Styles ***/
.account-activation-background {
  background: $primary-color;
  display: flex;
  justify-conent: center;
  align-items: center;
}
.account-activation-background .aa-logo {
  width: 100%;
  top: 24px;
  text-align: center;
  left: 0;
  position: absolute;
  padding-top: 0;
}
.account-activation-panel {
  padding: 32px;
  width: 720px;
  max-width: 100%;
}
.account-activation-panel.small {
  width: 312px;
  min-height: 328px;
}
.account-activation-panel.small.short {
  min-height: initial;
}
.account-activation-panel.small .bold-text {
  margin-bottom: 16px;
}
.account-activation-panel p.margin-bottom {
  margin-bottom: 24px;
}
.account-activation-panel button.margin-top {
  margin-top: 24px;
}
.directions {
  display: flex;
  align-items: center;
  margin: 40px 0;
}
.directions .divider-or {
  position: relative;
  margin: 0 40px 0 24px;
  height: 120px;
  width: 1px;
}
.directions .divider-or .or-circle {
  top: 40px;
  left: -14px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.directions-left,
.directions-right {
  width: 296px;
  padding-bottom: 40px;
}
.directions-title {
  margin-bottom: 24px;
}
.account-activation-panel .centered-spinner {
  text-align: center;
  width: 48px;
}
.account-activation-panel .centered-spinner img {
  width: 40px;
}
.success-circle-container {
  text-align: center;
}
.success-circle {
  color: #4aa04d;
  font-size: 80px;
}
.account-activation-panel .contact-fake-select i {
  top: 12px;
}

/*** Register Form Styles ***/
.register-form .mui-textfield {
  margin-bottom: 12px;
}
.user-dropdown-box {
  position: absolute;
  z-index: 1;
  width: 200px;
  background-color: #fff;
  margin-right: -10px;
  top: 0;
  right: 35px;
  border-radius: 3px;
  padding: 10px;
  height: auto;
}
.user-dropdown-box .label {
  display: inline-block;
}

@media only screen and (max-width: 820px) {
  .account-activation-panel {
    width: 400px;
  }
  .directions {
    display: block;
    margin-bottom: 16px;
  }
  .directions .divider-or {
    width: 100%;
    height: 1px;
    margin: 0 0 24px 0;
  }
  .directions .divider-or .or-circle {
    left: 50%;
    transform: translateX(-50%);
    top: -16px;
  }
}

@media only screen and (max-width: 480px) {
  .account-activation-panel {
    width: 312px;
    text-align: center;
  }
  .directions-left,
  .directions-right {
    width: auto;
    padding-bottom: 40px;
  }
  .directions #android2 {
    display: block;
    margin: 0 auto 16px auto;
  }
  .directions #ios2 {
    margin: 0;
  }
}

@media only screen and (max-height: 650px) {
  .account-activation-background {
    display: block;
    padding: 120px 0;
    height: auto !important;
  }
}

#app > div:first-of-type.account-activation-page {
  background: #00112b;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 8px;
  height: auto;
}
.account-activation-panel {
  width: 408px;
  padding: 32px 40px 24px 40px;
}
.account-activation-header {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 32px;
}
#message {
  font-size: 11px;
  color: #cc0000;
  margin-top: -12px;
}
.account-activation-panel-footer {
  margin-top: 16px;
}
.account-activation-panel-footer-margin-top {
  margin-top: 56px;
}
.account-activation-panel-footer-margin-top-extra {
  margin-top: 88px;
}

/*** MuiCSS Overrides ***/
.account-activation-panel .mui-select select {
  font-size: 13px;
}
.account-activation-panel .mui-textfield > input {
  font-size: 13px;
  height: 24px;
  line-height: 24px;
}
.account-activation-panel .mui-textfield > label {
  line-height: 24px;
}
.account-activation-panel .mui-btn {
  height: 40px;
  line-height: 40px;
  width: 100%;
  padding: 0;
  font-size: 14px;
  margin-bottom: 16px;
}
