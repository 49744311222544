.radio-input {
  display: none;
}
.radio-label {
  position: relative;
  cursor: pointer;
  margin: 10px;
  padding-left: 28px;
}

.label-one {
  color: #eec900;
}

.label-two {
  color: #e5a3ad;
}

.label-three {
  color: #ffaa00;
}
.radio-label:before,
.radio-label:after {
  position: absolute;
  content: "";
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.radio-label:before {
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  background-color: #1565c0;
  -moz-box-shadow: inset 0 0 0 13px #fff;
  -webkit-box-shadow: inset 0 0 0 13px #fff;
  box-shadow: inset 0 0 0 13px #fff;
}

.label-one:before {
  background-color: #eec900;
  border: 2px solid #eec900;
}

.label-two:before {
  background-color: #e5a3ad;
  border: 2px solid #e5a3ad;
}

.label-three:before {
  background-color: #ffaa00;
  border: 2px solid #ffaa00;
}

.type-label {
  margin: 8px 0;
  display: block;
}

.type-label:before {
  background-color: #ce7e07;
  border: 1px solid #8b8b8b;
}

.radio-input:checked + .radio-label:before {
  border: 1px solid #ce7e07;
}

.radio-label:after {
  top: 49%;
  left: 9px;
  width: 54px;
  height: 54px;
  background-color: transparent;
  -moz-transform: translate(-50%, -50%) scale(0);
  -ms-transform: translate(-50%, -50%) scale(0);
  -webkit-transform: translate(-50%, -50%) scale(0);
  transform: translate(-50%, -50%) scale(0);
}
.radio-input:checked + .radio-label:before {
  -moz-box-shadow: inset 0 0 0 4px #fff;
  -webkit-box-shadow: inset 0 0 0 4px #fff;
  box-shadow: inset 0 0 0 4px #fff;
}
.radio-input:checked + .radio-label:after {
  -moz-transform: translate(-50%, -50%) scale(1);
  -ms-transform: translate(-50%, -50%) scale(1);
  -webkit-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
  -moz-animation: ripple 1s forwards;
  -webkit-animation: ripple 1s forwards;
  animation: ripple 1s forwards;
}
