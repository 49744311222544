.multiselect {
  position: relative;
}
.multiselect-dropdown-box {
  position: absolute;
  z-index: 1;
  background-color: #fff;
  margin-right: -10px;
  top: 0;
  width: 100%;
  border-radius: 3px;
  padding: 10px;
  overflow: hidden;
}
.multiselect-dropdown-box .label {
  display: inline-block;
}
