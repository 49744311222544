.modal-form {
  p {
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 16px;
  }
  .mui-textfield {
    margin-bottom: 0;
  }
  .buttons {
    float: right;
    .mui-btn.mui-btn--flat {
      display: inline-block;
      color: #ce7e07;
      background-color: transparent;
      padding: 0 15px;
      font-size: 14px;
      height: 36px;
      margin-top: 24px;
    }
  }
}

.modal-subtitle {
  color: rgba(0, 0, 0, 0.6);
  font-size: 13px !important;
  font-weight: 400 !important;
}

/***** MODAL BTNS STYLES *****/
.modal-btns {
  float: right;
  text-align: right;
  .mui-btn {
    font-size: 14px;
    color: #ce7e07;
    background-color: transparent;
    padding: 0;
    margin: 24px 0 0 16px;
    height: 24px;
    &:hover {
      background-color: transparent;
    }
  }
  .cancel-btn {
    color: #8b8b8b !important;
  }
}

/****** MODAL STYLES ******/

/*** Create Client Login Modal ***/
.create-client-login-modal {
  overflow: hidden;
  width: 400px;
  border-radius: 3px;
  margin: auto;
  position: absolute;
  top: 250px;
  left: 40px;
  right: 40px;
  border: none;
  outline: none;
  background: rgb(255, 255, 255);
  height: auto;
  min-height: 100px;
  padding: 20px 20px 8px 20px;
}

@media only screen and (max-width: 420px) {
  .create-client-login-modal {
    width: 312px;
  }
}

/*** Note Modal ***/
.note-modal {
  overflow: hidden;
  width: 500px;
  margin: auto;
  position: absolute;
  top: 250px;
  left: 40px;
  right: 40px;
  border: none;
  outline: none;
  background: rgb(255, 255, 255);
  height: auto;
  padding: 20px 20px 8px 20px;
}

.add-note-modal {
  font-size: 15px;
}

.add-note-textarea textarea {
  resize: none;
  min-height: 30px !important;
}

.add-note-buttons {
  float: right;
  .mui-btn {
    font-size: 14px;
    color: #ce7e07;
    background-color: transparent;
    padding: 0;
    margin: 24px 0 0 20px;
    height: 36px;
    &:hover {
      background-color: transparent;
    }
  }
  .cancel-btn {
    color: #8b8b8b !important;
  }
}

/*** Loading Modal ***/
.cm-loading-spinner {
  height: 50px;
  width: 50px;
  margin: 16px auto;
  display: block;
}

.loading-dialog {
  padding: 3%;
  .grey-circle {
    margin-top: 10px;
    height: 88px;
    width: 88px;
    i {
      font-size: 55px;
    }
  }
}

.loading-modal {
  @extend .comps-error-modal;
  background-color: transparent !important;
}

/*** Rating Modal ***/
.rating-modal {
  @extend .note-modal;
  overflow: visible !important;
  .mui-select {
    margin-top: 24px;
    margin-bottom: 0;
    .mui-select__menu {
      top: 0 !important;
      font-size: 14px !important;
      height: auto !important;
      max-height: 200px;
      div {
        height: 32px !important;
        line-height: 32px !important;
      }
    }
  }
}

/*** Status Modal ***/
.status-modal {
  @extend .note-modal;
  overflow: visible !important;
  .mui-select {
    margin-top: 24px;
    margin-bottom: 0;
    .mui-select__menu {
      top: 0 !important;
      font-size: 14px !important;
      height: auto !important;
      max-height: 200px !important;
      div {
        height: 32px !important;
        line-height: 32px !important;
      }
    }
  }
}

/*** Deal Rating Modal ***/
.deal-rating-modal {
  padding: 0;
  min-height: 175px;
  width: 275px;
}

/*** Add Contact Modal ***/
.add-contact-modal {
  width: 30%;
  margin: auto;
  border: none;
  overflow: auto;
  outline: none;
  padding: 0;
  background: rgb(255, 255, 255);
  height: auto;
  max-height: 800px;
  z-index: 10;
}

@media only screen and (max-width: 700px) {
  .add-contact-modal {
    left: 20px;
    right: 20px;
    width: auto;
  }
}

/*** Share Calculator Modal ***/
.share-calc-modal {
  height: auto;
  max-height: 800px;
  width: 375px;
  padding: 20px;
  .share-calc-title {
    font-size: 16px;
  }
  .share-calc-subtitle {
    font-size: 13px;
    font-weight: normal;
  }
  .modal-exit {
    color: #ccc;
    margin-top: -2px;
    margin-right: 0;
  }
  .margin-top {
    margin-top: 10px;
  }
  .share-calc-button .mui-btn {
    padding: 0;
    background-color: transparent;
    color: #ce7e07;
    font-size: 14px;
    height: 25px;
    margin-top: 20px;
    &:hover {
      background-color: transparent;
    }
  }
  .mui-textfield input {
    font-size: 13px;
  }
  .help-block {
    margin-top: 5px !important;
  }
}

.modal-exit {
  float: right;
  color: #fff;
  margin-top: 10px;
  margin-right: 5px;
  cursor: pointer;
}

/*** Snooze Modal ***/
.snooze-modal {
  overflow: visible !important;
  .react-date-picker__calendar {
    position: absolute;
    top: 35px;
  }
}

/*** Filter Modal ***/
.filter-modal {
  @extend .note-modal;
  width: 300px;
  .filter-icon {
    font-size: 20px;
    vertical-align: middle;
    margin-right: 8px;
    margin-top: -4px;
  }
  .close-icon {
    font-size: 16px;
    color: #8b8b8b;
    float: right;

    cursor: pointer;
  }
  .rkmd-checkbox {
    float: right;
    margin-top: -5px;
    margin-right: -16px;
  }
  p {
    display: inline-block;
    font-size: 15px;
  }
  .slider {
    display: block;
    text-align: center;
    border: 1px solid #ce7e07;
  }
}

.filter-modal > div {
  min-height: 40px;
}

.filter-modal .rc-slider {
  margin-bottom: 32px;
}

.filter-modal .rc-slider-dot:first-child {
  margin-left: 0;
}

.filter-modal .rc-slider-dot:last-child {
  margin-left: -8px;
}

.filter-modal .slider-orange {
  .rc-slider-track {
    background-color: #ce7e07 !important;
  }
  .rc-slider-handle {
    border: 2px solid #ce7e07 !important;
  }
  .rc-slider-dot-active {
    border-color: #ce7e07 !important;
  }
  .rc-slider-mark-text {
    color: #ce7e07 !important;
  }
}

.filter-modal .slider-gray {
  .rc-slider-track {
    background-color: #8b8b8b !important;
  }
  .rc-slider-handle {
    border: 2px solid #8b8b8b !important;
  }
  .rc-slider-dot-active {
    border-color: #8b8b8b !important;
  }
  .rc-slider-mark-text {
    color: #8b8b8b !important;
  }
}

.filter-btn .mui-btn.mui-btn--flat {
  width: 100%;
  font-size: 14px;
  padding: 0;
  height: 40px;
}

.modal-header {
  border-bottom: 1px solid #f5f5f5;
  margin: -8px -20px 16px -20px;
  padding: 0 20px 8px 20px;
  min-height: unset !important;
  p {
    margin-bottom: 0;
  }
}

/*** Lending Criteria - First Look Modal ***/
.first-look-modal {
  width: 480px;
  height: auto;
  margin: 0 auto;
}
.first-look-modal .modal-btns {
  margin-top: 16px;
}
.first-look-modal .modal-btns .button {
  font-size: 12px;
  height: 32px;
  line-height: 32px;
  min-width: auto;
}
.first-look-modal .modal-btns .btn-cancel {
  background: #8b8b8b !important;
  color: #fff !important;
}
.first-look-modal .modal-btns .btn-cancel:hover {
  background: #f5f5f5 !important;
  color: #8b8b8b !important;
}

/*** Deal Details Notes - Delete Modal ***/
.delete-modal {
  width: 320px;
  height: auto;
}
/*** Deal Review Modal ***/
.deal-review-modal {
  width: 320px;
  padding: 16px;
  height: auto;
}
.deal-review-modal .label {
  display: inline-block;
  font-weight: 400;
}
.review-modal-detail {
  margin-left: 8px;
  display: inline-block;
  color: #333;
}
.review-modal-note {
  display: block;
  font-size: 13px;
  color: #333;
  font-weight: 400;
  margin-left: 40px;
}

/*** Deal Details Notes - Assign Modal ***/
.assign-modal {
  width: 320px;
  height: auto;
  overflow: visible !important;
}
.assign-modal
  .rkmd-checkbox
  .input-checkbox
  input[type="checkbox"]
  + .checkbox:before {
  left: 0;
}
.assign-modal .label {
  display: inline-block;
  font-weight: normal;
  font-size: 13px;
}
.assign-modal .mui-select select {
  font-size: 13px;
  top: 0 !important;
  height: auto !important;
  max-height: 200px !important;
}
.assign-modal .rkmd-checkbox {
  margin-top: 16px;
}
.assign-modal .rkmd-checkbox .input-checkbox {
  margin-top: -2px;
  width: 24px;
  vertical-align: middle;
}

/*** Deal Review Modal ***/
.deal-review-modal {
  width: 320px;
  padding: 16px;
  height: auto;
}
.deal-review-modal .label {
  display: inline-block;
  font-weight: 400;
}
.review-modal-detail {
  margin-left: 8px;
  display: inline-block;
  color: #333;
}
.review-modal-note {
  display: block;
  font-size: 13px;
  color: #333;
  font-weight: 400;
  margin-left: 40px;
}

/*** Deal Details Notes - Date/Time Modal ***/
.date-time-modal {
  width: 320px;
  overflow: visible !important;
  height: auto;
}
.date-time-modal .modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: none;
  margin: 0 0 24px 0;
  padding: 0;
}
.date-time-modal .modal-header i {
  font-size: 16px;
}
.date-time-delete {
  position: relative;
}
.date-time-delete-content {
  position: absolute;
  min-width: 120px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  right: 0;
  z-index: 1;
  padding: 4px;
  border-radius: 1px;
  font-size: 13px;
  background: #fff;
  top: -4px;
  opacity: 0;
  visibility: hidden;
}
.date-time-delete:hover .date-time-delete-content {
  opacity: 1;
  visibility: visible;
  transition: all 300ms linear;
}
.date-time-delete-content:hover {
  background: #f9f9f9;
  transition: all 300ms linear;
  cursor: pointer;
}
.date-time-delete-content i {
  vertical-align: middle;
  margin-top: -2px;
  margin-right: 8px;
  color: #cc0000;
}
.date-time-modal .react-date-field--theme-default {
  width: 100%;
  font-size: 12px;
}
.time-input {
  margin-top: 8px;
}
.time-input .mui-textfield {
  margin-bottom: 0;
}

/*** Form Modal ***/
.form-modal {
  width: 640px;
  padding: 24px;
  height: auto;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  border-radius: 3px;
}
.form-modal-header {
  margin-bottom: 16px;
}
.form-modal-header i:hover {
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 300ms linear;
}
.form-modal .mui-textfield > input {
  height: 24px;
}
.form-modal .mui-textfield--float-label > label {
  line-height: 24px;
}
.form-modal .mui-textfield > label {
  font-size: 14px;
}
.form-modal .mui-select > select {
  font-size: 14px;
  height: 24px;
}
.form-modal .mui-select__menu {
  max-height: 280px;
}
.form-modal-textarea {
  margin-top: 16px;
}
.form-modal-textarea textarea {
  border: 1px solid #e0e0e0;
  border-radius: 2px;
  width: 100%;
  resize: none;
  height: 72px;
  margin-top: 2px;
}
.form-modal-btns .mui-btn.mui-btn--flat.button {
  height: 32px;
  width: 144px;
  line-height: 32px;
  font-size: 12px;
  margin: 16px 0 0 8px;
}
.form-modal-btns .mui-btn.mui-btn--flat.button.btn-accent2 {
  border: 1px solid #999;
}
.form-modal-btns .mui-btn.mui-btn--flat.button.btn-accent {
  border: 1px solid #ce7e07;
}

/*** Confirmation Modal ***/
.confirmation-modal {
  height: auto;
  width: 312px;
  border-radius: 3px;
}
.confirmation-modal-header {
  margin-bottom: 8px;
}
.confirmation-modal-btns {
  @extend .add-note-buttons;
}
.confirmation-modal-btns .mui-btn {
  margin-top: 8px;
  height: 30px;
}

/*** Create Account Modal ***/
.create-account-modal {
  width: 376px;
  height: 216px;
  padding: 24px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
}
.create-account-modal .xl-text {
  margin-bottom: 16px;
}
.create-account-modal .modal-btns {
  margin-top: 38px;
  display: flex;
  justify-content: space-between;
  float: none;
}
.create-account-modal .modal-btns .mui-btn {
  margin: 0;
}
.create-account-modal .modal-btns .mui-btn:hover {
  opacity: 0.6;
}
.create-account-modal .modal-btns > div .mui-btn {
  margin-left: 16px;
}

/*** Contact Modal ***/
.contact-modal {
  height: auto;
  max-height: 88vh;
  overflow: auto !important;
  width: 416px;
  padding: 0;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  border-radius: 3px;
}
.contact-modal-header {
  padding: 20px 24px;
}
.contact-modal-header i {
  margin-top: 2px;
  float: right;
  cursor: pointer;
  transition: opacity 300ms linear;
}
.contact-modal-header i:hover {
  opacity: 0.6;
}
.contact-modal-form {
  font-size: 12px;
  border-top: 2px solid #e0e0e0;
  padding: 16px 24px 0 24px;
}
.contact-modal-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}
.contact-modal-info-left {
  flex: 1;
  display: flex;
  align-items: center;
}
.contact-modal-info-left i {
  color: #9e9e9e;
  font-size: 18px;
  margin-right: 16px;
}
.contact-modal-info-right label {
  color: #9e9e9e;
  font-size: 10px;
}
.contact-modal-info-bio {
  padding: 16px 24px 24px 24px;
  border-top: 2px solid #e0e0e0;
  margin: 0 -24px;
}
.contact-modal-info-bio label {
  margin-bottom: 8px;
}

@media only screen and (max-width: 448px) {
  .contact-modal {
    width: 312px;
  }
}

/*** Listings Onboarding Modal ***/
.listings-modal {
  height: auto;
  text-align: center;
  max-width: 600px;
  padding: 0 64px 8px 64px;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.5);
}
.listings-modal-header {
  padding: 24px;
  background: #00112b;
  color: #fff;
  font-size: 24px;
  margin: 0 -64px 40px -64px;
  text-align: center;
}
.listings-modal .status {
  display: flex;
  justify-content: center;
  margin-bottom: 56px;
}
.listings-modal .status div {
  margin: 0 20px;
}
.listings-modal .status i {
  font-size: 36px;
  color: #dcdbdc;
}
.listings-modal .status div:first-child i {
  margin-top: 10px;
  margin-bottom: 16px;
}
.listings-modal .status div:first-child p {
  color: #e3484c;
}
.listings-modal .status div:last-child i {
  margin-bottom: 26px;
}
.listings-modal .status div:last-child p {
  color: #4caf50;
}
.listings-modal .status p {
  text-transform: uppercase;
  font-weight: 500;
}
.listings-modal .dots {
  margin-top: 40px;
  margin-bottom: 16px;
}
.listings-modal .dots i {
  font-size: 8px;
  color: #e0e0e0;
  margin: 0 4px;
}
.listings-modal .dots i.active {
  color: #ce7e07;
}
.listings-modal .mui-btn.mui-btn--flat.button {
  height: 32px;
  line-height: 32px;
  width: 180px;
  margin-bottom: 16px;
  border: 1px solid #ce7e07;
}
.listings-modal-img {
  margin-bottom: 24px;
}

/*** Date Picker Modal ***/
.datepicker-modal {
  height: auto;
  width: auto;
  overflow: visible !important;
  border-radius: 3px;
}

/*** Mark Sold Modal ***/
.mark-sold-modal {
  width: 540px;
  max-width: 100%;
  height: auto;
  border-radius: 3px;
  padding: 24px;
  overflow: visible !important;
}
.sold-details {
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
}
.sold-details > div:first-child {
  width: 48%;
  padding-right: 2%;
}
.sold-details > div:last-child {
  width: 48%;
  padding-left: 2%;
}
.sale-price-container .error-block {
  font-weight: normal;
  position: absolute;
  margin-top: -16px;
}
.sale-date-container .react-date-field--theme-default {
  height: 47px;
  width: 100%;
  border-bottom: none;
}

/*** Mark Active Modal ***/
.mark-active-modal {
  @extend .mark-sold-modal;
}
.mark-active-modal .radio-label {
  display: block;
  margin: 24px 0 0 0;
}
.mark-active-modal .radio-label:before {
  border: 1px solid #333;
  background: #ce7e07;
}

/*** Remove Listings Modal ***/
.removed-listings-modal {
  width: 448px;
  height: auto;
  border-radius: 3px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  padding: 24px 24px 16px 24px;
}
.removed-properties {
  margin-top: 24px;
  margin-bottom: 16px;
  max-height: 50vh;
  overflow: auto;
}
.removed-properties p {
  margin-bottom: 8px;
}
.removed-listings-modal .mui-divider {
  margin: 0 -24px;
  background: #e0e0e0;
  height: 2px;
  margin-bottom: 16px;
}
.removed-listings-modal .mui-btn.mui-btn--flat.button {
  height: 32px;
  line-height: 32px;
  border: 1px solid #ce7e07;
  margin-bottom: 0;
  float: right;
}

/*** Salesbroker Deals - Add Property Modal ***/
.add-property-modal {
  top: 50%;
  left: 50%;
  height: auto;
  width: 600px;
  border-radius: 3px;
  box-shadow: -4px 0 7px 0 rgba(0, 0, 0, 0.2);
  padding: 24px;
}
.add-property-modal .modal-header {
  border-bottom: 2px solid #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px 24px;
  margin: -8px -24px 16px -24px;
}
.add-property-modal .close-icon {
  font-size: 16px;
  color: #8b8b8b;
  cursor: pointer;
}
.add-property-modal .searchbox {
  border: 2px solid #e0e0e0;
  border-radius: 3px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  height: 40px;
  width: 100%;
  margin-bottom: 15px;
}
.add-property-modal .searchbox input {
  border: none;
  background: transparent;
  margin-left: 16px;
}
.add-property-modal .properties {
  max-height: 56vh;
  overflow: auto;
  margin: 0 -24px;
  padding: 0 24px;
}
.add-property-modal .properties.border-top {
  border-top: 2px solid #e0e0e0;
}
.add-property-modal .property {
  margin: 24px 0;
}
.add-property-modal .add-manual {
  border-top: 2px solid #e0e0e0;
  margin: 0 -24px;
  padding: 24px 24px 0;
}

/*** Salesbroker Deals - Add New Property Modal ***/
.add-new-property-modal {
  @extend .add-property-modal;
  padding-bottom: 0;
}
.add-property-form {
  max-height: 60vh;
  overflow: auto;
  margin-right: -24px;
  padding: 24px 24px 24px 0;
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 12px));
  grid-gap: 24px;
}
.add-property-form .mui-textfield > input {
  font-size: 14px;
}
.add-property-form .mui-select > select {
  font-size: 14px;
}
.add-property-form .btn-accent {
  font-size: 14px;
  height: 40px;
  width: 100%;
  line-height: 32px;
}
.add-property-form-footer {
  border-top: 2px solid #e0e0e0;
  text-align: right;
  margin: 0 -24px;
  padding: 16px 38px;
}
.add-property-form-footer .mui-btn.mui-btn--flat {
  height: 40px;
  width: 140px;
  font-size: 13px;
  padding: 0;
}
