p {
  margin: 0;
}

.calculator-sub-header {
  @extend .sub-header;
  overflow-x: auto;
  white-space: nowrap;
  padding: 0 10px;
  min-height: 80px;
  text-align: center;
  -webkit-overflow-scrolling: touch;
  a {
    display: inline-block;
    color: #8b8b8b;
    text-align: center;
    padding: 25px 14px;
    text-decoration: none;
    height: 65px;
    font-size: 13px;
    &:hover {
      color: #ffffff;
      border-bottom: 3px solid #ce7e07;
    }
  }
  .header-active-link {
    color: #fff;
    border-bottom: 3px solid #ce7e07;
  }
}

#sub-header-nav {
  padding-top: 30px;
  padding-left: 20px;
  a {
    text-decoration: none;
    li {
      float: left;
      padding-right: 15px;
      padding-left: 15px;
      color: #8b8b8b;
      list-style-type: none;
      width: 9%;
      text-align: center;
      height: 50px;
      &:hover {
        color: #ffffff;
        border-bottom: 3px solid #ce7e07;
      }
    }
  }
}

.calculators {
  padding-top: 75px;
  padding-bottom: 20px;
}

.calculator-card {
  margin: 25px auto;
  width: 85%;
  border-radius: 5px;
}

.calculator-title {
  font-weight: 500;
  color: #8b8b8b;
  font-size: 20px;
}

.formatted-input-container {
  width: 49%;
  padding-right: 50px;
  display: inline-block;
}

.narrow-input {
  width: unset !important;
  max-width: 330px;
  flex: 1 0 200px;
}

.narrow-or-input {
  width: unset !important;
  max-width: 330px;
  // min-width: 200px;
  flex: 1 0 200px;
  padding-right: 18px !important;
}

.ls-or-wraper {
  display: flex;
  align-items: center;
  flex: 1 0 200px;
}

.calculator-form {
  width: 70%;
  padding-left: 20px;
  padding-bottom: 15px;
  float: left;
  h4 {
    color: #8b8b8b;
    font-size: 14px;
  }
  .mui-textfield {
    //width: 49%;
    //padding-right: 50px;
    label {
      font-size: 14px;
      overflow: hidden;
    }
    input {
      font-size: 14px;
    }
  }
}

.calculator-form-full-width {
  width: 100% !important;
  padding-right: 20px;
  max-width: 1000px;
}

.mui-textfield > input:focus,
.mui-textfield > textarea:focus {
  border-color: #ce7e07;
  border-width: 2px;
}

.mui-textfield > input:focus ~ label,
.mui-textfield > textarea:focus ~ label {
  color: #ce7e07;
}

.mui-select:focus > select {
  border-color: #ce7e07;
}

.mui-select:focus > label,
.mui-select > select:focus ~ label {
  color: #ce7e07;
}

.percentage-of-select {
  width: 44%;
  margin-right: 37px;
  display: inline-block;
}

.amortization-select {
  @extend .percentage-of-select;
  .mui-select .mui-select__menu {
    top: 0 !important;
    max-height: 175px !important;
  }
}

.calculator-error {
  position: absolute;
  margin-top: -15px;
  color: #cc0000;
  font-size: 12px;
}

.error-container {
  .calculator-error {
    position: static;
  }
}

.margin-top {
  margin-top: 30px;
}

#boxes {
  float: right;
  margin-right: 20px;
  width: 25%;
  margin-top: 10px;
}

.box {
  background-color: #00112b;
  width: 100%;
  border-radius: 5px;
  margin-top: 10px;
  margin-right: 25%;
  margin-bottom: 10px;
  color: #ffffff;
  padding: 10px 20px;
}
.box-top {
  margin-right: 4%;
}
.box-top > div {
  margin: 7px 0px;
}
.box.orange {
  background-color: #ce7e07;
}
.amount {
  font-size: 32px;
  word-wrap: break-word;
}

.or {
  z-index: 5;
  display: inline-block;
  margin-left: -45px;
  width: 45px;
  text-align: center;
}

.loan-syndication-form .ls-or {
  padding-right: 18px;
  font-weight: 700;
}

.loan-syndication-form {
  width: 100% !important;
  max-width: 1000px;
  padding-right: 20px;
}

.ls-totals {
  display: flex;
  flex-direction: column;
  width: 215px;
}

.info {
  background-color: #dcdcdc;
  color: #8b8b8b;
  width: 85%;
  margin: 15px auto;
  border-radius: 5px;
  font-size: 1.05em;
  overflow: hidden;
  p {
    float: left;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 12px;
    i {
      float: left;
      margin-top: -3px;
      padding-left: 20px;
      padding-right: 8px;
      font-size: 20px;
      height: 25px;
    }
    a {
      color: #ce7e07;
    }
  }
}

.box-title {
  font-size: 13px;
}

.double {
  display: block;
  padding-top: 10px;
  font-size: 12px;
}

.result {
  float: right;
  margin-right: 10px;
  color: rgba(255, 255, 255, 0.5);
}

.mui-textfield .display-value {
  font-size: 16px;
}

.date-inputs {
  margin-bottom: 10px;
}

.calendar-label {
  margin-right: 20px;
  display: inline-block;
  width: 100px;
}

.irr-boxes {
  .box-title {
    font-size: 15px;
  }
  .amount {
    font-size: 32px;
  }
}

@media only screen and (max-width: 1200px) {
  #sub-header-nav {
    a {
      li {
        width: 33%;
        &:hover {
          border-bottom: none;
        }
      }
    }
  }

  #sub-header-nav {
    padding-left: 0;
    .active-nav {
      li {
        border-bottom: none;
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .calculator-card {
    width: 91%;
    display: block;
  }

  .calculator-form {
    width: 100%;
  }

  #boxes {
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
    margin-right: 0;
  }

  .info {
    width: 91%;
    p {
      padding-right: 5px;
    }
  }
}

.error-message {
  span {
    margin-left: 49%;
  }
}

.calculator-display {
  width: 49%;
}

.share-calc {
  color: #ce7e07;
  margin-top: 25px;
  margin-bottom: 20px;
  font-size: 13px;

  cursor: pointer;
  i {
    float: left;
    margin-top: -3px;
    margin-right: 8px;
  }
}

//media queries

@media only screen and (min-width: 1840px) {
  .calculator-sub-header {
    min-height: 65px;
  }
}

@media only screen and (max-width: 768px) {
  .info {
    display: block;
    margin-right: auto;
    margin-left: auto;
    padding: 5px;
    text-align: center;
    i {
      display: none;
    }
  }
}

@media only screen and (max-width: 560px) {
  .calculator-card {
    width: 95%;
  }

  .calculator-form {
    padding-left: 10px;
    padding-right: 10px;
    .mui-textfield {
      label {
        font-size: 12px;
      }
    }
  }

  .formatted-input-container {
    padding-right: 25px;
  }

  .or {
    margin-left: -30px;
    width: 30px;
    text-align: right;
    padding-right: 6px;
  }

  .p-and-i {
    .formatted-input-container:last-of-type {
      width: 55%;
    }
  }

  .amount {
    font-size: 32px;
  }

  .mobile-ls-output-col {
    margin-right: 12px;
  }

  .mobile-ls-output-col p {
    margin-bottom: 7px;
  }

  .mobile-blended-term {
    width: unset !important;
    max-width: 330px;
    flex: 1 0 200px;
    display: block !important;
  }
}

//for nav
@media only screen and (max-width: 900px) {
  .calculator-sub-header {
    width: 100%;
    position: unset !important;
  }

  .calculators {
    padding-top: 0;
  }
}
