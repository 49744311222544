/***** PW DEAL STYLES *****/
.mobile-header-searchbar + .contacts-container {
  padding-top: 120px !important;
}

/*** Panel Styles ***/
.fpw-deal .panel , .pw-deal .panel {
  padding-bottom: 0;
}

/* Panel Heading Styles */
.fpw-deal .panel-heading , .pw-deal .panel-heading {
  padding: 16px;
  margin-bottom: 0;
}
.fpw-deal .panel-info-value.property-title , .pw-deal .panel-info-value.property-title {
  padding: 0;
}
.fpw-deal .ip-quote-star , .pw-deal .ip-quote-star {
  margin: 0;
  height: 24px;
  width: 24px;
  text-align: center;
}
.fpw-deal .ip-quote-star .star-icon-orange , .pw-deal .ip-quote-star .star-icon-orange {
  font-size: 20px;
  padding-top: 1px;
  padding-left: 0;
}
.fpw-deal .reonomy-link , .pw-deal .reonomy-link {
  margin: 0 8px 0 0;
  height: 24px;
  width: 24px;
}
.fpw-deal .reonomy-link img , .pw-deal .reonomy-link img {
  height: 24px;
}
.r-icon {
  float: right;
  margin-right: 8px;
}
.r-icon img {
  height: 24px;
}
.deal-id {
  font-weight: 400;
  margin-left: 16px;
}

@media only screen and (max-width: 546px) {
  .fpw-deal .panel-heading , .pw-deal .panel-heading {
    padding: 12px;
  }
}

/* Panel Body Styles */
.fpw-deal .panel-body , .pw-deal .panel-body {
  padding: 16px;
}
.fpw-deal .panel-body .mui-row , .pw-deal .panel-body .mui-row {
  padding-bottom: 16px;
}
.fpw-deal .panel-body .mui-divider , .pw-deal .panel-body .mui-divider {
  margin: 0;
}
.fpw-deal .small-title , .pw-deal .small-title {
  padding-left: 0;
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.fpw-deal .panel-info-value , .pw-deal .panel-info-value {
  font-size: 13px;
  padding-left: 0;
}
.fpw-deal .notes-title , .pw-deal .notes-title {
  margin-top: 0 !important;
}
.fpw-deal .note , .pw-deal .note {
  padding-left: 0;
}
.fpw-deal .note-options , .pw-deal .note-options {
  padding-top: 16px;
}
.ip-deal .panel-body {
  padding: 0 16px;
}
.ip-deal .panel-body .mui-row {
  padding-bottom: 16px;
}
.ip-deal .small-title {
  padding-left: 0;
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.ip-deal .panel-info-value {
  padding-left: 0;
  font-size: 13px;
}
.ip-deal .panel-body .mui-divider {
  margin: 16px 0;
}
.ip-deal .comps-row {
  margin-top: -16px;
}
.ip-deal .notes-title {
  margin-top: 0 !important;
}

@media only screen and (max-width: 546px) {
  .ip-deal .panel-heading {
    padding: 12px;
  }
  .ip-deal .property-title {
    padding: 0 !important;
  }
  .ip-quote-star {
    margin: 0;
  }
}

/* Comps Styles */
.fpw-deal .violation-value , .pw-deal .violation-value {
  margin: 0 0 16px 16px;
}
.fpw-deal .comps , .pw-deal .comps {
  margin-left: 16px;
}

/*** Remind Me Styles ***/
.remind-me {
  font-size: 13px;
  margin-top: 4px;
}
.remind-me p {
  display: inline-block;
  margin-right: 16px;
}
.remind-me i {
  font-size: 18px;
  vertical-align: middle;
  margin-right: 8px;
}

/*** View Notes Styles ***/
.view-all {
  float: right;
}
.view-all p:first-child {
  display: inline-block;
  margin-right: 16px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  color: #ce7e07;
}
.view-all p:last-child {
  display: inline-block;
  font-size: 12px;
  color: #999;
}

/*** Circle Background Styles ***/
.circle-background {
  margin-left: 16px;
  height: 24px;
  width: 24px;
  border-radius: 100%;
  display: inline-block;
  text-align: center;
  color: #fff;
  cursor: pointer;
  padding-top: 2px;
}

.yellow-circle {
  background: #eec900;
}
.orange-circle {
  background: #ffaa00;
}
.green-circle {
  background: #42a746;
}
.pink-circle {
  background: #e5a3ad;
}

/*** Expected IP Date Styles ***/
.expected-ip .react-date-field--theme-default {
  border-bottom: none;
  width: 120px;
}
.expected-ip .react-date-field__input {
  padding-left: 0;
}
.expected-ip .react-date-picker {
  top: -7px;
}

@media only screen and (max-width: 546px) {
  .expected-ip .react-date-picker__calendar {
    left: -16px;
    .react-calendar {
      // width: unset;
      max-width: 95vw;
    }
  }
}

/*** Brokers Styles ***/
.brokers-list p {
  color: #b3b3b3;
  width: calc(100% - 80px);
  display: inline-block;
}
.brokers-list span {
  width: 78px;
  display: inline-block;
  text-transform: uppercase;
  font-weight: 500;
  margin-left: 16px;
  margin-right: 32px;
}

.deal-status-tag {
  @extend .deal-tag;
  background-color: #4caf50;
}

/*** Search Info Tooltip Styles ***/
.info-icon-container {
  position: relative;
  display: inline-block;
}

.info-icon-container:hover {
  .info-tooltip {
    cursor: pointer;
    visibility: visible;
  }
}

.info-tooltip {
  visibility: hidden;
  position: absolute;
  // bottom: 100%;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  left: 50%;
  font-size: 10px;
  width: 142px;
  text-align: center;
  border-radius: 3px;
  margin-left: -60px;
  padding: 4px;
  transition: all 300ms linear;
}
