.not-found-first-row {
  padding-top: 15%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  h1 {
    font-size: 200px;
    font-weight: bold;
    line-height: 150px;
  }
  p {
    margin-top: 50px;
    font-size: 16px;
    color: #8b8b8b;
  }
}

.not-found-btn-row {
  margin-top: 50px;
  text-align: center;
  .home-button {
    padding: 2px 15px;
    height: auto;
    font-size: 14px;
    &:hover {
      background-color: transparent;
    }
  }
}

@media (max-width: 450px) {
  .not-found-first-row {
    margin-top: 15%;
    h1 {
      font-size: 150px;
      line-height: 100px;
    }
    p {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}
