.remaining-portfolio {
  .note-container {
    cursor: pointer;
  }
}

#pencil {
  height: 20px;
  padding-left: 3px;
  padding-bottom: 2px;
}

.add-note {
  color: #ce7e07;
  padding-left: 40px;
  padding-top: 5px;
  i {
    margin-top: -4px;
    vertical-align: middle;
    font-size: 20px;
  }
}

.last-update {
  color: #ce7e07;
  padding-left: 40px;
  font-size: 13px;
  margin-top: 10px;
}
.remaining-portfolio .note {
  padding-left: 40px;
}

@media only screen and (max-width: 900px) {
  .add-note {
    padding-left: 20px;
  }
}
