.react-date-field--theme-default {
  border: none;
  border-bottom: 1px solid #8b8b8b;
}
.react-date-field--theme-default.react-date-field--focused {
  border: none;
  border-bottom: 1px solid #8b8b8b;
}
.react-date-field--theme-default > .react-date-field__picker {
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.34375);
  border: none;
}

.react-date-field--theme-default .react-date-field__calendar-icon {
  border: 2px solid #8b8b8b;
}

.react-date-field--theme-default.react-date-field--focused
  .react-date-field__calendar-icon,
.react-date-field--theme-default.react-date-field--focused
  .react-date-field__calendar-icon:hover,
.react-date-field--theme-default.react-date-field--focused
  .react-date-field__calendar-icon:active,
.react-date-field--theme-default:not(.react-date-field--disabled)
  .react-date-field__calendar-icon:active {
  border-color: #8b8b8b;
}
.react-date-field--theme-default.react-date-field--focused
  .react-date-field__calendar-icon:after,
.react-date-field--theme-default.react-date-field--focused
  .react-date-field__calendar-icon:before,
.react-date-field--theme-default.react-date-field--focused
  .react-date-field__calendar-icon
  .react-date-field__calendar-icon-inner,
.react-date-field--theme-default.react-date-field--focused
  .react-date-field__calendar-icon:hover:after,
.react-date-field--theme-default.react-date-field--focused
  .react-date-field__calendar-icon:hover:before,
.react-date-field--theme-default.react-date-field--focused
  .react-date-field__calendar-icon:hover
  .react-date-field__calendar-icon-inner,
.react-date-field--theme-default.react-date-field--focused
  .react-date-field__calendar-icon:active:after,
.react-date-field--theme-default.react-date-field--focused
  .react-date-field__calendar-icon:active:before,
.react-date-field--theme-default.react-date-field--focused
  .react-date-field__calendar-icon:active
  .react-date-field__calendar-icon-inner,
.react-date-field--theme-default:not(.react-date-field--disabled)
  .react-date-field__calendar-icon:active:after,
.react-date-field--theme-default:not(.react-date-field--disabled)
  .react-date-field__calendar-icon:active:before,
.react-date-field--theme-default:not(.react-date-field--disabled)
  .react-date-field__calendar-icon:active
  .react-date-field__calendar-icon-inner {
  background: #8b8b8b;
}

.react-date-picker__month-view--theme-default
  .react-date-picker__month-view-day
  .react-date-picker__month-view-day-text:hover {
  background: #e6be83 padding-box;
}

.react-date-picker__month-view--theme-default
  .react-date-picker__month-view-day--in-hover-range.react-date-picker__month-view-day--today-highlight
  .react-date-picker__month-view-day-text,
.react-date-picker__month-view--theme-default
  .react-date-picker__month-view-day--in-range.react-date-picker__month-view-day--today-highlight
  .react-date-picker__month-view-day-text,
.react-date-picker__month-view--theme-default
  .react-date-picker__month-view-day--value.react-date-picker__month-view-day--today-highlight
  .react-date-picker__month-view-day-text {
  color: #fff;
}

.react-date-picker__year-view--theme-default
  .react-date-picker__year-view-month--value {
  color: white;
  background: #ce7e07 padding-box;
  border: none;
}

.react-date-picker__month-view--theme-default
  .react-date-picker__month-view-day--in-hover-range
  .react-date-picker__month-view-day-text:hover,
.react-date-picker__month-view--theme-default
  .react-date-picker__month-view-day--in-range
  .react-date-picker__month-view-day-text:hover,
.react-date-picker__month-view--theme-default
  .react-date-picker__month-view-day--value
  .react-date-picker__month-view-day-text:hover {
  background: #ce7e07 padding-box;
  border: none;
}

.react-date-picker__month-view--theme-default
  .react-date-picker__month-view-day--active
  .react-date-picker__month-view-day-text {
  border: none;
}

.react-date-picker__month-view--theme-default
  .react-date-picker__month-view-day--in-hover-range
  .react-date-picker__month-view-day-text,
.react-date-picker__month-view--theme-default
  .react-date-picker__month-view-day--in-range
  .react-date-picker__month-view-day-text,
.react-date-picker__month-view--theme-default
  .react-date-picker__month-view-day--value
  .react-date-picker__month-view-day-text {
  border: none;
  background: #ce7e07 padding-box;
  color: #fff;
}

.react-date-picker__month-view--theme-default
  .react-date-picker__month-view-day--in-hover-range.react-date-picker__month-view-day--today-highlight
  .react-date-picker__month-view-day-text,
.react-date-picker__month-view--theme-default
  .react-date-picker__month-view-day--in-range.react-date-picker__month-view-day--today-highlight
  .react-date-picker__month-view-day-text,
.react-date-picker__month-view--theme-default
  .react-date-picker__month-view-day--value.react-date-picker__month-view-day--today-highlight
  .react-date-picker__month-view-day-text {
  color: #fff;
}

.react-date-picker__month-view--theme-default
  .react-date-picker__month-view-day--today-highlight {
  color: #ce7e07;
}

.react-date-picker__footer--theme-default {
  padding: 0;
  margin-top: -20px;
}
.react-date-picker__footer--theme-default .react-date-picker__footer-button {
  display: none;
}

.react-date-field--theme-default.react-date-field--focused
  .react-date-field__clear-icon {
  color: gray;
  fill: gray;
}
