/***** TAG STYLES *****/
.tag {
  border-radius: 2px;
  padding: 3px 6px;
  font-size: 11px;
  font-weight: 400;
  text-transform: none;
  display: inline-block;
}
.primary-tag {
  background: #262c40;
  color: #fff;
}
.accent-tag {
  background: #dcdbdc;
  color: #fff;
}
.first-look-tag {
  margin-left: 8px;
}
.first-look-tag.inline {
  display: inline;
}
.status-tag {
  margin-bottom: 16px;
}

.badge-container {
  position: relative;
}

.badge {
  top: 5px;
  right: -50px;
  height: 20px;
  display: flex;
  padding: 0 4px;
  z-index: 1;
  position: absolute;
  flex-wrap: wrap;
  font-size: 12px;
  min-width: 20px;
  transform: scale(1) translate(50%, -50%);
  box-sizing: border-box;
  transition: transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  align-items: center;
  font-family: Lato, Lucida Grande, sans-serif;
  font-weight: 500;
  align-content: center;
  border-radius: 10px;
  flex-direction: row;
  justify-content: center;
  transform-origin: 100% 0%;
  color: #fff;
  background-color: #ff0800;
}

.badge-sidebar {
  height: 15px;
  min-width: 15px;
  top: 0;
  right: 0;
  font-size: 11px;
}
