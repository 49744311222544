header {
  background: #00112b;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 11;
  padding: 0 16px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
header ul {
  display: flex;
  justify-content: center;
  height: 40px;
  align-items: center;
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
}
header li a {
  text-transform: uppercase;
  padding: 0 20px;
  color: #fff;
}
header li:hover a {
  color: #fff;
  opacity: 0.7;
  transition: all 300ms linear;
  cursor: pointer;
}

/***** WIDTH MEDIA QUERIES *****/
@media only screen and (max-width: 959px) {
  header {
    height: 50px;
    display: flex;
    justify-content: space-between;
  }

  .mobile-header-content {
    display: flex;
    // justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .mobile-header-title-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 20px;
  }

  .mobile-header-title {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #ffffff;
    margin-left: 20px;
  }

  .mobile-header-title-small {
    font-size: 13.1097px;
    margin-left: 0px;
  }

  .mobile-header-subtitle {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 8.7398px;
    color: #ffffff;
  }
}
