/***** SALESBROKER - MY DEALS - SUBHEADER STYLES *****/
.salesbroker-deals-page .sub-header {
  display: flex;
}
.salesbroker-deals-subheader {
  display: flex;
  width: 96%;
  max-width: 984px;
  margin: 0 auto;
}
.salesbroker-deals-subheader > * {
  flex: 1;
}
.salesbroker-deals-subheader ul {
  text-align: left;
}
.salesbroker-deals-subheader > .flex {
  align-items: center;
  justify-content: flex-end;
}
.salesbroker-deals-page .search-container {
  margin: 0;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 8px;
  transform: translateX(0);
  width: 60%;
}
.salesbroker-deals-page .search-form {
  display: flex;
  align-items: center;
  color: #fff;
}
.salesbroker-deals-page .search-form input {
  background: transparent;
  border: none;
}
.salesbroker-deals-page .sub-header > .flex i:hover {
  opacity: 0.5;
  cursor: pointer;
  transition: opacity 300ms linear;
}

@media only screen and (max-width: 496px) {
  .salesbroker-deals-page .sub-header {
    padding-bottom: 16px;
  }
  .salesbroker-deals-subheader {
    display: block;
  }
  .salesbroker-deals-subheader ul {
    text-align: center;
  }
}

/***** SALESBROKER - MY DEALS - CONTAINER STYLES *****/
.salesbroker-content-container {
  padding-top: 80px;
}

/***** SALESBROKER - MY DEALS - PANEL STYLES *****/
.salesbroker-content-container .panel {
  max-width: 984px;
  width: 96%;
  cursor: default;
}
.salesbroker-content-container .panel-heading {
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: auto;
}
.salesbroker-content-container .panel-heading > div:first-child {
  flex: 1;
}
.salesbroker-content-container .panel-heading .property-title {
  padding: 0;
  flex: 1;
}
.salesbroker-content-container .panel-body {
  padding: 40px 32px 0 32px;
}
.salesbroker-content-container
  .panel-body
  > .mui-row:first-child
  div[class^="mui-col"] {
  margin-bottom: 24px;
}
.salesbroker-content-container .panel-body > div:last-child > div {
  margin-bottom: 0;
}
.salesbroker-content-container .non-qts {
  margin-top: -40px;
}
.salesbroker-content-container .small-title {
  margin-top: 0;
}
.salesbroker-content-container .panel-info-value {
  padding-left: 0;
}
.salesbroker-content-container .panel .mui-divider {
  margin-left: -16px;
  margin-right: -16px;
  width: calc(100% + 32px);
}
.salesbroker-content-container .comps {
  margin-left: 0;
}
.salesbroker-content-container .btn-primary {
  background: #00112b;
  font-size: 10px;
  color: #fff;
  border-radius: 3px;
  border: 1px solid #00112b;
  height: 24px;
  margin-top: 4px;
}
.salesbroker-content-container .note-container i {
  font-size: 16px;
  margin-left: 12px;
}

/***** SALESBROKER - MY DEALS - ADD STYLES *****/
.salesbroker-deals-add {
  position: absolute;
  bottom: 8px;
  right: 64px;
}
.salesbroker-content-container .add-circle-icon {
  bottom: 22px;
}

/***** SALESBROKER - MY DEALS - ADD/EDIT STYLES *****/
/*** Subheader Styles ***/
.salesbroker-deal-edit-page .sub-header-content {
  max-width: 1200px;
  margin: 0 auto;
  width: 96%;
  height: 100%;
}
.salesbroker-deal-edit-page .sub-header-content > div:first-child {
  flex: 1;
}
.salesbroker-deal-edit-page .back-arrow img {
  position: relative;
  margin: 0 16px 0 0;
}
.save-btn {
  height: 32px;
  width: 104px;
  font-size: 14px;
  padding: 0;
}
/*** Container Styles ***/
.salesbroker-deal-edit-content-container {
  padding-top: 104px;
}
/*** Panel Styles ***/
.salesbroker-deal-edit-panel {
  width: 96%;
  max-width: 1200px;
  cursor: default;
}
.salesbroker-deal-edit-panel:hover {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 2px 0 rgba(0, 0, 0, 0.12);
}
.salesbroker-deal-edit-panel .panel-heading {
  padding: 0 24px;
  display: flex;
  align-items: center;
}
.salesbroker-deal-edit-panel .panel-body {
  padding: 24px;
}
/* First Row Styles */
.edit-deal-first-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}
.edit-deal-first-row > div {
  flex: 0.5;
  margin-left: 16px;
}
.edit-deal-first-row > div:first-child {
  flex: 1.5;
  margin-left: 0;
}
.edit-deal-first-row > .flex {
  flex: 1.25;
}
.edit-deal-first-row > .flex > div:last-child {
  padding-left: 16px;
}
.edit-deal-first-row .default-text {
  margin-top: -16px;
}
.edit-deal-first-row .btn-primary {
  background: #00112b;
  font-size: 10px;
  color: #fff;
  border-radius: 3px;
  border: 1px solid #00112b;
  height: 24px;
  margin-top: 4px;
}
.edit-deal-first-row .react-date-field--theme-default {
  border-bottom: none;
}
.salesbroker-deal-edit-panel .mui-textfield {
  padding-top: 0;
}
.salesbroker-deal-edit-panel .mui-select {
  padding-top: 0;
}
.salesbroker-deal-edit-panel .mui-textfield > input {
  font-size: 14px;
}
.salesbroker-deal-edit-panel .mui-select > select {
  font-size: 14px;
}
/* Note Styles */
.salesbroker-deal-edit-panel .note-container .xs-text:first-child {
  margin-bottom: 8px;
}
.salesbroker-deal-edit-panel .note-container .mui-textfield > input {
  font-size: 12px;
}
/* Divider Styles */
.salesbroker-deal-edit-panel .mui-divider {
  margin: 24px -24px;
}
/* Property Information Styles */
.property-information-title span {
  margin-left: 16px;
  display: inline-block;
}
.property-information-title span i {
  font-size: 16px;
  vertical-align: middle;
  margin-top: -4px;
}
.salesbroker-deal-panel-property-info {
  margin-bottom: 16px;
}
.salesbroker-deal-panel-property-info .property {
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f5f5f5;
  padding: 0 16px;
  margin-bottom: 16px;
}
.salesbroker-deal-panel-property-info .property:first-of-type {
  margin-top: 24px;
}
.salesbroker-deal-panel-property-info .property:last-child {
  margin-bottom: 0;
}
.salesbroker-deal-panel-property-info .property > .flex-center .bold-text {
  margin-right: 16px;
}
.property-actions i:hover {
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 300ms linear;
}
/* Save Button Styles */
.salesbroker-deal-save-btn {
  margin-bottom: -16px;
}

@media only screen and (max-width: 968px) {
  .edit-deal-first-row {
    flex-wrap: wrap;
  }
  .edit-deal-first-row > div:first-child {
    min-width: 100%;
  }
  .edit-deal-first-row > div:nth-child(2) {
    margin-left: 0;
  }
}

@media only screen and (max-width: 584px) {
  .edit-deal-first-row > div {
    min-width: 100%;
    margin-left: 0;
  }
  .salesbroker-deal-panel-property-info .property {
    display: block;
    padding: 16px;
    height: auto;
  }
  .salesbroker-deal-panel-property-info .property > .flex-center {
    display: block;
  }
  .property-actions {
    text-align: right;
  }
}

/***** SALESBROKER - MY DEALS - ADD PROPERTY SIDEBAR STYLES *****/
.add-property-sidebar {
  position: fixed;
  height: calc(100vh - 56px);
  width: 400px;
  right: 0;
  top: 56px;
  box-shadow: -4px 0 7px 0 rgba(0, 0, 0, 0.2);
  background: #fff;
}
/*** Header Styles ***/
.add-property-sidebar-header {
  height: 96px;
  display: flex;
  align-items: center;
  padding: 32px 24px 0 24px;
  background: #f5f5f5;
}
.add-property-sidebar-header .xxs-text.accent-text-light {
  position: absolute;
  top: 16px;
  right: 24px;
}
.add-property-sidebar-header .searchbox {
  border: 2px solid #e0e0e0;
  border-radius: 3px;
  background: #fafafa;
  padding: 0 16px;
  display: flex;
  align-items: center;
  height: 48px;
  width: 100%;
}
.add-property-sidebar-header .searchbox input {
  border: none;
  background: transparent;
  margin-left: 16px;
}
/*** Body Styles ***/
.add-property-sidebar-body {
  height: calc(100% - 96px);
  overflow: auto;
}
.add-property-sidebar-body .properties > div {
  padding: 16px 40px;
  border-top: 2px solid #e0e0e0;
}
.add-property-form {
  padding: 32px;
  height: calc(100% - 96px);
  overflow: auto;
}
.add-property-form .mui-textfield > input {
  font-size: 14px;
}
.add-property-form .mui-select > select {
  font-size: 14px;
}
.add-property-form .btn-accent {
  font-size: 14px;
  height: 40px;
  width: 100%;
  line-height: 32px;
}
.add-property-sidebar-body .manual-property {
  padding-bottom: 32px !important;
}

@media only screen and (max-width: 520px) {
  .add-property-sidebar {
    width: 240px;
  }
  .add-property-sidebar-body .properties > div {
    padding: 16px 24px;
  }
}

/***** FUND PORTFOLIO - QUOTE FORM ****/
.fp-quote-panel {
  padding: 24px !important;
  display: block;
  border-radius: 3px;
}
.fp-quote-panel > div {
  display: flex;
}
.fp-quote-panel > div > div:first-child {
  flex: 1;
}
.fp-quote-panel .quote-notes-container {
  margin: -16px 0;
}
.fp-quote-panel .quote-col-left {
  margin-right: 0;
  padding-right: 32px;
}
.fp-quote-panel-footer {
  margin: 16px -24px 0;
  padding: 16px 24px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: flex-end;
}

#delete {
  vertical-align: middle;
  font-size: 20px;
  padding-bottom: 2px;
  cursor: pointer;
}
