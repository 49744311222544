.home-page {
  padding: 32px;
}

/***** HOME PAGE GRID STYLES *****/
.home-page-grid {
  grid-template-columns: calc(25% - 12px) calc(25% - 12px) calc(25% - 12px) calc(
      25% - 12px
    );
  grid-gap: 16px;
  max-width: 1200px;
  margin: 0 auto;
}

/*** Home Page Card Styles ***/
.home-page-card {
  background: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  padding: 16px;
  display: flex;
  flex-direction: column;
  height: 200px;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 3px;
  cursor: pointer;
}
.home-page-card:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}
.home-page-title {
  position: absolute;
  left: 16px;
  top: 16px;
  color: #666;
  font-size: 13px;
  font-weight: 500;
  transition: color 100ms linear;
}
.home-page-card img {
  height: 56px;
  align-self: center;
  position: absolute;
  transition: all 300ms linear;
}
.home-page-card .active-img {
  opacity: 0;
  display: none;
}
.home-page-card:hover .active-img {
  opacity: 1;
  display: block;
}
.home-page-card:hover .inactive-img {
  opacity: 0;
  display: none;
}
.home-page-card:hover .home-page-title {
  color: #ce7e07;
}
.home-page-card svg {
  color: #8B8B8B;
  align-self: center;
  position: absolute;
  transition: all 300ms linear;
  height: 56px;
  width: 56px;
}
.home-page-card:hover svg {
  color: #ce7e07;
}

/***** MEDIA QUERIES *****/
@media only screen and (max-width: 1000px) {
  .home-page-grid {
    width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(168px, 1fr));
  }
}
@media only screen and (max-width: 546px) {
  .home-page-grid {
    height: calc(50% - 5px);
    grid-gap: 16px;
    grid-template-columns: repeat(auto-fit, 50% 50%);
    width: 100%;
    .home-page-card-container {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
    }
    .home-page-card {
      height: calc(50vw - 32px);
      word-break: break-all;
      .home-page-title {
        left: 12px;
        top: 12px;
      }
    }
    .home-page-card img {
      height: 35px;
      position: initial;
      margin-bottom: 15px;
    }
    // .home-page-title {
    //   position: initial;
    // }
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */
  .home-page-card {
    min-width: 240px;
    margin: 16px;
  }
  .home-page-card img {
    transform: translate(-50%, -50%);
  }
}
